import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../../Foundation/Helpers/Components/Feature/Common/SectionTitle/SectionTitle';
import { useSelector } from 'react-redux';
import styles from './Dashboard.module.css';

const Dashboard = () => {

  const user = useSelector(state => state.user);

  return <div className='dashboard'>
    <SectionTitle title="Dashboard"></SectionTitle>

    {user &&
      <div className={styles.administratorProfile}>Welcome {user.FirstName}</div>}
  </div>;

};

export default Dashboard;

Dashboard.propTypes = {

}