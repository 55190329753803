/**
 * @class CookieStorageHelper 
 * @description basic CRUD operations for browser cookie storage
 */
export class CookieStorageHelper {

    /*
     * returns array of domain cookies
     *
     * @method read
     * @public
     * @return {Array} domain cookies
     */
    read() {
        let cookies = [],
            ckeArr;

        if (!document.cookie) {
            return false;
        }

        ckeArr = document.cookie.split(/; */);

        for (let i = 0; i < ckeArr.length; i += 1) {
            let splitCookie = ckeArr[i].split('='),
                ck = new Cookie(splitCookie[0], splitCookie[1]);

            cookies.push(ck);
        }

        return cookies;
    };

    /*
     * writes a domain cookie
     *
     * @method write
     * @public
     * @param {String} name of the cookie
     * @param {String} value for the cookie
     * @param {Object} optional configuration options - legacy as a string for expiry date in days from today
     * @return {void} 
     */
    write(name, value, options) {

        let cookieString = name + '=' + value,
            now = new Date;

        if (options !== undefined) {
            if (typeof options === 'object') {

                if (typeof options.expires === 'number') {
                    now.setDate(now.getDate() + options.expires);
                    cookieString += ';expires=' + now.toUTCString();
                }

                if (typeof options.secure === 'boolean' && options.secure === true) {
                    cookieString += ';secure';
                }

                if (typeof options.maxAge === 'number') {
                    cookieString += ';max-age=' + options.maxAge;
                }

                if (typeof options.domain === 'string') {
                    cookieString += ';domain=' + options.domain;
                }

                if (typeof options.path === 'string') {
                    cookieString += ';path=' + options.path;
                }

                if (typeof options.sameSite === 'string') {
                    cookieString += ';SameSite=' + options.sameSite;
                }

            } else { 
                now.setDate(now.getDate() + options);
                cookieString += ';path=/;expires=' + now.toUTCString();
            }
        }

        document.cookie = cookieString;
    };

    /* gets value of cookie
     *
     * @method check
     * @public
     * @param name {String} cookie name
     * @return {String|Null} value of associate
     */
    check(name) {
        let ckes = this.read();

        for (let i in ckes) {
            if (ckes[i].name === name) {
                return ckes[i].value;
            }
        }

        return undefined;
    };

    /* erases cookie by name, otherwise all
     *
     * @method erase
     * @public
     * @param name {String} specific cookie to erase by name
     */
    erase(name) {

        if (name !== undefined) {
            this.write(name, '', -1);
        } else {
            let ckes = this.read();

            for (let i in ckes) {
                this.write(ckes[i].name, '', -1);
            }
        }
    };

}

/* 
*
* @class Cookie
* @private
*
*/
class Cookie {
    constructor(name, value) {
        this.name = name;
        this.value = value;
    }
};