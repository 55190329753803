import { GET_USER_ACCOUNT_DATA_SUCCESS, GET_USER_ACCOUNT_DATA_ERROR, UPDATE_USER_ACCOUNT_DATA_ERROR, UPDATE_USER_ACCOUNT_DATA_SUCCESS } from '../../Actions/User/actionTypes';
import initialState from '../../initialState';

const userReducer = (state = initialState.user, action) => {

    switch (action.type) {

        case GET_USER_ACCOUNT_DATA_SUCCESS:
            let getUserAccountStateData = action.user?.AccountSummary;
            return { ...state, ...getUserAccountStateData };
        case UPDATE_USER_ACCOUNT_DATA_SUCCESS:
            let updatedUserAccountStateData = action.user?.AccountSummary;
            return { ...state, ...updatedUserAccountStateData };
        case GET_USER_ACCOUNT_DATA_ERROR:
        case UPDATE_USER_ACCOUNT_DATA_ERROR:
        default:
            return state;
    }
};

export default userReducer;