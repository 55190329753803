import MembershipModel from "./MembershipModel";
import MembershipPaymentsSummaryModel from "./MembershipPaymentsSummaryModel";

class MembershipWithDetailModel extends MembershipModel {

    constructor(params) {

        super(params);

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.AccountNumber = params.accountNumber;
        this.Credits = params.credits;
        this.CreditsRedeemed = params.creditsRedeemed;
        this.CreditsRemaining = params.creditsRemaining;
        this.RedemptionCount = params.redemptionCount;
        this.TotalSavings = params.totalSavings;
        this.TotalCost = params.totalCost;
        this.PaymentsSummary = new MembershipPaymentsSummaryModel(params.paymentsSummary);

    }

}

export default MembershipWithDetailModel;
