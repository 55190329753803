import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldBase from '../../Base/FieldBase';
import { CLASS_MODIFIERS, FIELD_TYPES } from '../../../Utility/_formsConfig';
import parse from 'html-react-parser';

const Display = ({ name, register, variant, type, label, readOnly, placeholder, defaultValue, displayValue, maxLength, errors, events, alive, incrementor, options, option, validation = {} }) => {

  const isImage = (type === FIELD_TYPES.ImageUpload && displayValue);
  const isCollectionOfImages = isImage && Array.isArray(displayValue);

  const [fieldValue, setFieldValue] = useState(displayValue);

  useEffect(() => {

    if (type === FIELD_TYPES.RadioButtonList) {
      let val = options?.filter(o => o.value == displayValue)[0]?.label;
      setFieldValue(val);
    }

    if (type === FIELD_TYPES.CheckBoxList) {
      if (displayValue !== false && displayValue !== null && typeof displayValue !== 'undefined') {
        let values = `${displayValue}`?.split(',');
        let result = options?.filter(o1 => values?.some(o2 => o1.value === o2))?.map(a => a.label);
        setFieldValue(result.join(', '));
      }
    }

    if (type === FIELD_TYPES.CheckBox) {
       setFieldValue(fieldValue ? option.label : option.labelOff);
    }

  }, []);

  const hasValue = typeof fieldValue !== 'undefined' && fieldValue !== '' && fieldValue !== null;

  return <FieldBase {...{ errors, alive, ['classModifier']: CLASS_MODIFIERS.Display, label, incrementor, type }}>
    <div className={`display-field ${isImage ? 'display-image' : ''} ${name}`}>
      {type === FIELD_TYPES.ImageUpload ? fieldValue &&
        isCollectionOfImages ? fieldValue.map((f, i) => <img src={f} key={`${f}_${i}`} />) : <img src={fieldValue} />
        : hasValue ? parse(`${fieldValue}`) : ''}
    </div>
  </FieldBase>
};

export default Display;

Display.propTypes = {

}