import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Payments.module.css';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import { Outlet, useLocation } from 'react-router-dom';
import ActionsPanel from '../../Details/ActionsPanel/ActionsPanel';
import MembershipPaymentsHeadline from '../../Memberships/Shared/MembershipPaymentsHeadline/MembershipPaymentsHeadline';
import PaymentList from './PaymentList/PaymentList';
import LinkButton from '../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import ConfirmButton from '../../../Foundation/Helpers/Components/Feature/Common/Buttons/ConfirmButton/ConfirmButton';
import data from './paymentData.json';
import { toast } from 'react-toastify';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import _paymentServicesApiService from '../../../Application/ApiServices/Payment/PaymentServices/Services/PaymentServicesApiService';
import { DATA_API_PROPERTIES_KEY } from './constants';
import PaymentEntityViewModel from './PaymentEntityViewModel';
import CustomLoader from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { useDispatch, useSelector } from 'react-redux';
import { getMembershipPaymentData } from '../../../Foundation/Store/Actions/Membership/membershipActions';

const SUCCESS_MESSAGE = "Payment has been created",
    ERROR_MESSAGE = "Unable to create payment";

const Payments = () => {

    const location = useLocation();
    const membership = useSelector((state) => state.membership);
    const url = location.pathname;
    const backLinkUrl = url.substring(0, url.lastIndexOf('/'));
    const [isPendingData, setIsPendingData] = useState(false);
    const dispatch = useDispatch();

    const refreshPaymentStateData = () => {
        dispatch(getMembershipPaymentData(membership.MembershipNumber)).then((response) => {

        });
    }

    // Create
    const onCreateReject = () => {
        toast(<Notification label={"New Payment not created"} mode="information" />)
    }

    const onCreateConfirm = (data) => {

        if (membership === null) {
            toast(<Notification label={ERROR_MESSAGE} mode="error" />)
            return;
        }

        setIsPendingData(true);

        data.AccountNumber = membership.AccountNumber;
        data.MembershipNumber = membership.MembershipNumber;

        _paymentServicesApiService.create(data).then((response) => {

            if (response.apiStatus === HTTP_STATUS_CODES.Created) {
                response[DATA_API_PROPERTIES_KEY] = new PaymentEntityViewModel(response[DATA_API_PROPERTIES_KEY]);
                toast(<Notification label={SUCCESS_MESSAGE} mode="success" />)
                refreshPaymentStateData();
            } else {
                toast(<Notification label={ERROR_MESSAGE} mode="error" />)
            }

            setIsPendingData(false);

        });
    }

    return <div className={`layout__sub-section`}>
        <div className={`layout__side-panel`}>
            <ActionsPanel title="Payments" actions={
                <>
                    <div className=''>
                        <ConfirmButton disabled={isPendingData} noArrow primary onReject={onCreateReject} onConfirm={onCreateConfirm} dialogData={data.createDialog} dialogClassName={styles.createDialog}>Create new Payment</ConfirmButton>
                    </div>
                </>
            } links={<LinkButton url={`${backLinkUrl}`} label={"Back to membership"} arrowDirection="left" />} />
        </div>
        <div className={`layout__editor-panel`}>
            {isPendingData ? <CustomLoader label={"Creating a new Payment"} pending={isPendingData} className={styles.loader} /> :
                <>
                    <MembershipPaymentsHeadline />
                    <PaymentList />
                </>}
        </div>
    </div>
};

export default Payments;

Payments.propTypes = {

}
