import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../../Foundation/Helpers/Components/Feature/Common/SectionTitle/SectionTitle';
import SectionNavigator from '../Global/SectionNavigator/SectionNavigator';
import { Outlet } from 'react-router-dom';

const navigationData = {

  Directory: {
    id: "directory",
    uri: '',
    label: "Directory"
  },
  Create: {
    id: "create",
    uri: 'create',
    label: "Create a Course",
  }
}

const Partners = () => {

  return <div className='partners'>
    <SectionTitle title="Course Manager"></SectionTitle>
    <SectionNavigator data={navigationData} />
    <Outlet />
  </div>;

};

export default Partners;

Partners.propTypes = {

}