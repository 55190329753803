class GetMembershipRedemptionRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return;
        }

        this.referenceId = params.referenceId;
        this.membershipNumber = params.membershipNumber;

    }

}

export default GetMembershipRedemptionRequestModel;