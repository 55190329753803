import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import styles from './PartnerManager.module.css';
import FilterListing from '../../FilterListing/FilterListing';
import _partnerSearchServicesApiService from '../../../Application/ApiServices/Partner/Search/Services/PartnerSearchServicesApiService';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import filterData from './PartnerManagerListingFilterData.json';
import { getCounties } from '../../../Foundation/Store/Actions/Utility/utilityActions';
import { mapNameIdPairToSelectOptions } from '../../../Foundation/Helpers/Components/Forms/Utility/formHelpers';
import { toast } from 'react-toastify';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';

const COUNTY_ID_KEY = 'CountyIds';

const PartnerManager = ({ counties }) => {

  const [results, setResults] = useState(null);
  const [data, setData] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [isPendingFilterData, setIsPendingFilterData] = useState(false);
  const dispatch = useDispatch();

  const filterSubmit = (data) => {

    setIsPendingData(true);

    let dto = { ...data };

    _partnerSearchServicesApiService.search(dto).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        setResults(response.Results);
      } else {
        toast(<Notification label="Unable to complete search" mode="error" />)
      }

      setIsPendingData(false);

    });
  }

  const buildFormViewModel = () => {

    let result = { formViewModel: filterData }

    // counties
    for (let section in result.formViewModel.sections) {

      for (let field of result.formViewModel.sections[section].fields) {

        if (field.name === COUNTY_ID_KEY) {
          let countyList = mapNameIdPairToSelectOptions(counties);
          field.options = countyList;
          // field.displayValue = counties.find(x => x.value === entity)?.label; // todo, from localStorage once implemented
        }

      }
    }

    return result;
  }

  useEffect(() => {

    if (counties === null) {
      setIsPendingFilterData(true);
      dispatch(getCounties()).then(() => {
        setIsPendingFilterData(false);
      })
    }

    if (counties !== null) {
      setData(buildFormViewModel());
    }

  }, [counties]);

  useEffect(() => {

    // first load, get partners
    filterSubmit(null); // todo, from pre-stored parameters or default

  }, []);

  filterData.onSubmit = filterSubmit;

  return <div className={`${styles.partnerManager} layout__sub-section`}>
    <FilterListing type="partner" data={results} filters={filterData} isPendingData={isPendingData} isPendingFilterData={isPendingFilterData} listingClassName={styles.listing} listingEntityClassName={styles.listItem} />
  </div>

};

function mapStateToProps(state) {
  return {
    counties: state.utility.counties
  }
}

export default connect(mapStateToProps)(PartnerManager);

PartnerManager.propTypes = {

}
