class CreateMembershipRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return;
        }

        this.TypeId = params.typeId;
        this.AccountNumber = params.accountNumber;
        this.StartDate = params.startDate;
        this.PaymentPlanType = params.paymentPlanType;

    }

}

export default CreateMembershipRequestModel;