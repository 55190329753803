import React from 'react';
import parse from 'html-react-parser';
import styles from './MembershipFooter.module.css';

const MembershipFooter = ({ data }) => {

  if (typeof data === 'undefined') {
    return null;
  };

  return <div className={styles.validityDates}>
    {data.StartDate &&
      <div className={`${styles.dateField} ${styles.field}`}>
        <div className={styles.label}>Valid from</div>
        <div className={styles.value}>{parse(data.StartDate)}</div>
      </div>}
    {data.ExpiryDate &&
      <div className={`${styles.dateField} ${styles.field}`}>
        <div className={styles.label}>Expires</div>
        <div className={styles.value}>{parse(data.ExpiryDate)} {data.Expired && <span className={`${styles.expired}`}>Expired</span>}</div>
      </div>}
  </div>

};

export default MembershipFooter;

MembershipFooter.propTypes = {

}