import React, { useState, useEffect, useRef } from 'react';
import SubSectionNavigator from '../SubSectionNavigator/SubSectionNavigator';
import styles from './ManageNavigation.module.css';

const ManageNavigation = ({ data }) => {

  return <div className={`${styles.manageNavigation}`}>
    <SubSectionNavigator data={data} />
  </div>

};

export default ManageNavigation;

ManageNavigation.propTypes = {

}
