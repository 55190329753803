import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FilterListingResult.module.css';

const FilterListingResult = ({ children, className='' }) => {

  return <div className={`${styles.filterListingResult} ${className} panel-strong`}>
    {children}
  </div>

};

export default FilterListingResult;

FilterListingResult.propTypes = {

}
