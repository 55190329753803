import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../../Foundation/Helpers/Components/Feature/Common/SectionTitle/SectionTitle';
import SectionNavigator from '../Global/SectionNavigator/SectionNavigator';
import { Outlet } from 'react-router-dom';

const navigationData = {
  
  Directory: {
    id: "directory",
    uri: '',
    label: "Directory"
  },
  Create: {
    id: "create",
    uri: 'create',
    label: "Create a user"
  }
}

// todo, this conditional nav item...
// Manage: {
//   id: "manage",
//   uri: ':id',
//   label: "Manage user",
//   end: false
// }

const Users = () => {

  return <div className='users'>
    <SectionTitle title="User Manager"></SectionTitle>
    <SectionNavigator data={navigationData} />
    <Outlet />
  </div>;

};

export default Users;

Users.propTypes = {

}