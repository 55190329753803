import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './FilterListing.module.css';
import FilterListingResult from './FilterListingResult/FilterListingResult';
import FilterListingTypeResolver from './_FilterListingTypeResolver';
import Form from '../../Foundation/Helpers/Components/Forms/Form';
import CustomLoader from '../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';

const ListingEntity = ({ component: Component, data }) => {

  return <Component model={data} />
}

const FilterListing = ({ type, data, filters, isPendingData, isPendingFilterData, listingEntityClassName, listingClassName = '' }) => {

  return <div className={`${styles.filterListing}`}>
    <div className={`${styles.filter} ${isPendingFilterData ? styles.filterLoading : ''}`}>
      <div className={styles.formContainer}>
        <Form model={filters} isDisabled={isPendingFilterData} />
      </div>
      {isPendingFilterData && <span className={styles.disabledMask}></span>}
    </div>
    <div className={`${styles.results} ${listingClassName} ${isPendingData || data === null ? 'panel-strong' : ''}`}>
    {data && <div>Results Count {data.Count}</div>}
      {isPendingData ? <CustomLoader label={"Loading"} pending={isPendingData} className={styles.loader} /> 
      : data && data.Items?.map((r, i) => <FilterListingResult data={r} className={listingEntityClassName} key={`${r.AccountNumber}_${i}`}>
        <ListingEntity component={FilterListingTypeResolver[type]} data={r} />
      </FilterListingResult>)}
    </div>
  </div>
};

export default FilterListing;

FilterListing.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  filters: PropTypes.object,
}