import { CLEAR_MEMBERSHIP_DATA_ERROR, CLEAR_MEMBERSHIP_DATA_SUCCESS, GET_MEMBERSHIP_DATA_ERROR, GET_MEMBERSHIP_DATA_SUCCESS, UPDATE_MEMBERSHIP_STATE_ERROR, UPDATE_MEMBERSHIP_STATE_SUCCESS, UPDATE_MEMBERSHIP_PAYMENT_STATE_SUCCESS, ACTIVATE_MEMBERSHIP_ERROR, ACTIVATE_MEMBERSHIP_SUCCESS, LOCK_UNLOCK_MEMBERSHIP_SUCCESS, LOCK_UNLOCK_MEMBERSHIP_ERROR } from './actionTypes';
import _membershipsMemberServicesApiService from '../../../../Application/ApiServices/Membership/MembershipMemberServices/Services/MembershipMemberServicesApiService';
import _membershipAccountServicesApiService from '../../../../Application/ApiServices/Membership/MembershipAccountServices/Services/MembershipAccountServicesApiService';

const API_RESPONSE_CODES = {
    GetMembershipDataSuccessful: 200,
    GetMembershipPaymentDataSuccessful: 200,
    UpdateMembershipDataSuccessful: 200,
    ActivateMembershipSuccessful: 200,
    LockUnlockMembershipSuccessful: 200
};

/*
 * get membership data
 */
export const getMembershipDataSuccess = (membership) => ({ type: GET_MEMBERSHIP_DATA_SUCCESS, membership })
export const getMembershipDataError = (membership) => ({ type: GET_MEMBERSHIP_DATA_ERROR, membership })

export function getMembershipData(membershipNumber) {

    return (dispatch) => {

        return _membershipsMemberServicesApiService.getWithDetailByMembershipNumber(membershipNumber).then((response) => {

            if (response?.apiStatus === API_RESPONSE_CODES.GetMembershipDataSuccessful) {
                dispatch(getMembershipDataSuccess(response.Membership));
                return { apiStatus: response.apiStatus, apiMessage: response.apiMessage };
            }

            return dispatch(getMembershipDataError(response))?.membership;

        });

    };
}

export const getMembershipPaymentDataSuccess = (paymentsSummary) => ({ type: UPDATE_MEMBERSHIP_PAYMENT_STATE_SUCCESS, paymentsSummary })

export function getMembershipPaymentData(membershipNumber) {

    return (dispatch) => {

        return _membershipsMemberServicesApiService.getPaymentDetailByMembershipNumber(membershipNumber).then((response) => {

            if (response?.apiStatus === API_RESPONSE_CODES.GetMembershipPaymentDataSuccessful) {
                dispatch(getMembershipPaymentDataSuccess(response.PaymentsSummary));
                return { apiStatus: response.apiStatus, apiMessage: response.apiMessage };
            }

            return dispatch(getMembershipDataError(response))?.membership;

        });

    };
}

/*
 * clear membership data
 */
export const clearMembershipDataSuccess = (membership) => ({ type: CLEAR_MEMBERSHIP_DATA_SUCCESS, membership })

export function clearMembershipData() {
    return (dispatch) => {
        return dispatch(clearMembershipDataSuccess());
    };
}

/*
 * update membership state (no save)
 */
export const updateMembershipStateSuccess = (membership) => ({ type: UPDATE_MEMBERSHIP_STATE_SUCCESS, membership })
export const updateMembershipStateError = (membership) => ({ type: UPDATE_MEMBERSHIP_STATE_ERROR, membership })

export function updateMembershipState(data = {}) {
    return (dispatch) => {
        return dispatch(updateMembershipStateSuccess(data));
    };
}

/*
* activate
*/
export const activateMembershipSuccess = (membership) => ({ type: ACTIVATE_MEMBERSHIP_SUCCESS, membership })
export const activateMembershipError = (membership) => ({ type: ACTIVATE_MEMBERSHIP_ERROR, membership })

export function activateMembership(membershipNumber) {

    return (dispatch) => {

        return _membershipAccountServicesApiService.activate(membershipNumber).then((response) => {

            if (response?.apiStatus === API_RESPONSE_CODES.ActivateMembershipSuccessful) {
                dispatch(activateMembershipSuccess(response.Membership));
                return { apiStatus: response.apiStatus, apiMessage: response.apiMessage };
            }

            return dispatch(activateMembershipError(response))?.membership;

        })

    };
}

/*
* lock
*/
export const lockUnlockMembershipSuccess = (membership) => ({ type: LOCK_UNLOCK_MEMBERSHIP_SUCCESS, membership })
export const lockUnlockMembershipError = (membership) => ({ type: LOCK_UNLOCK_MEMBERSHIP_ERROR, membership })

export function lockMembership(membershipNumber) {

    return (dispatch) => {

        return _membershipAccountServicesApiService.lock(membershipNumber).then((response) => {

            if (response?.apiStatus === API_RESPONSE_CODES.LockUnlockMembershipSuccessful) {
                dispatch(lockUnlockMembershipSuccess(response.Membership));
                return { apiStatus: response.apiStatus, apiMessage: response.apiMessage };
            }

            return dispatch(lockUnlockMembershipError(response))?.membership;
        });
    };
}

/*
* unlock
*/
export function unlockMembership(membershipNumber) {

    return (dispatch) => {

        return _membershipAccountServicesApiService.unlock(membershipNumber).then((response) => {

            if (response?.apiStatus === API_RESPONSE_CODES.LockUnlockMembershipSuccessful) {
                dispatch(lockUnlockMembershipSuccess(response.Membership));
                return { apiStatus: response.apiStatus, apiMessage: response.apiMessage };
            }

            return dispatch(lockUnlockMembershipError(response))?.membership;
        });
    };
}