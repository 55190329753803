import RedemptionRequestSearchUserModel from "../RedemptionRequestSearchUserModel";

class RedemptionRequestListItemModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.BookingRequestDateTime = params.bookingRequestDateTime;
        this.SlotDateTime = params.slotDateTime;
        this.PartnerId = params.partnerId;
        this.PartnerName = params.partnerName;
        this.ReferenceId = params.referenceId;
        this.MembershipNumber = params.membershipNumber;
        this.NumberOfGuests = params.numberOfGuests;
        this.Confirmed = params.confirmed;
        this.Cancelled = params.cancelled;
        this.User = new RedemptionRequestSearchUserModel(params.user);

    }

}

export default RedemptionRequestListItemModel;