class CreatePaymentRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return;
        }

        this.MembershipNumber = params.MembershipNumber;
        this.AccountNumber = params.AccountNumber;
        this.Amount = params.Amount;
        this.DueDate = params.DueDate;
        this.Reason = params.Reason;
        this.Credits = params.Credits;

    }

}

export default CreatePaymentRequestModel;