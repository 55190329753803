import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import BaseRestService from '../../../../Application/ApiServices/Base/Services/BaseRestService';
import { tryLoginUserFromSession, refreshUserAuthentication } from '../../../Store/Actions/Authentication/authenticationActions';

const AuthenticationProvider = ({ children, authentication }) => {

  BaseRestService.on401 = () => {
  
    dispatch(refreshUserAuthentication({ refreshToken: authentication?.refreshToken })).then((result) => {
      
    });    

  }

  const dispatch = useDispatch();

  useEffect(() => {
    
    dispatch(tryLoginUserFromSession()).then((result) => {
      
    });
    
  }, []);

  return children;
}


function mapStateToProps(state) {
  return {
    authentication: state.authentication
  }
}

export default connect(mapStateToProps)(AuthenticationProvider);