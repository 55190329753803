import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProfileRoundel.module.css';

const ProfileRoundel = ({ data = null, imageUri, className = '' }) => {

  const label = data && (typeof data[0] !== 'undefined' && typeof data[1] !== 'undefined') ? `${data[0]?.charAt(0)}.${data[1]?.charAt(0)}` : null;

  return <div className={`${styles.profileRoundel} ${className}`}>
    {label && !imageUri && <div className={styles.label}>{label}</div>}
    {imageUri && <img src={imageUri} alt="Profile"></img>}
  </div>;

};

export default ProfileRoundel;

ProfileRoundel.propTypes = {
  label: PropTypes.array
}