import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Create.module.css';
import _adminPartnerAccountServicesApiService from '../../../Application/ApiServices/Partner/AdminPartnerServices/Services/PartnerAccountServicesApiService';
import _redemptionServices from '../../../Application/ApiServices/Redemption/RedemptionServices/Services/RedemptionServicesApiService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import StepScreen from '../../Global/StepScreen/StepScreen';
import StepScreenSection from '../../Global/StepScreen/StepScreenSection';
import Confirm from './Components/Confirm/Confirm';
import MembershipSelector from './Components/MembershipSelector/MembershipSelector';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import CustomLoader from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import PartnerSelector from './Components/PartnerSelector/PartnerSelector';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SectionTitle from '../../../Foundation/Helpers/Components/Feature/Common/SectionTitle/SectionTitle';
import { EDIT_REDEMPTION_REQUEST_REFFERAL_QUERY_KEY, EDIT_REDEMPTION_REQUEST_REFFERAL_LOCAL_STORAGE_KEY } from '../constants';
import { LocalStorageHelper } from '../../../Utility/LocalStorageHelper';

const _localStorageHelper = new LocalStorageHelper();

let stepsData = {
  numbered: true,
  initialActiveStepId: 'membership',
  submit: (data) => {

  },
  steps: {
    membership: {
      order: 0,
      label: "Membership",
      mandatory: true,
      next: {
        top: true,
        label: "Select Course"
      },
      onNavigateAway: (ar) => {
        // todo, is a sample method
        return true;
      }
    },
    partner: {
      order: 1,
      label: "Course",
      mandatory: true,
      next: {
        top: true,
        label: "Confirm & Complete"
      },
    },
    confirm:
    {
      mandatory: true,
      order: 2,
      label: "Confirm & Complete"
    }
  }
}

const Create = () => {

  const [isPendingData, setIsPendingData] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let referralData = null;

  const setFromReferral = () => {

    const referralReferenceId = searchParams.get(EDIT_REDEMPTION_REQUEST_REFFERAL_QUERY_KEY);
    referralData = _localStorageHelper.get(`${EDIT_REDEMPTION_REQUEST_REFFERAL_LOCAL_STORAGE_KEY}_${referralReferenceId}`)

    stepsData.steps.membership.defaultData = { primary: referralData.PrimaryMembership, guest: referralData.GuestMemberships }
    stepsData.steps.partner.defaultData = { PartnerId: referralData.PartnerId, Name: referralData.PartnerName }
    stepsData.steps.confirm.defaultData = { slotDateTime: referralData.SlotDateTime };

  }

  // override submit
  stepsData.submit = (data) => {  

    // todo, to helper method
    let slotDateString = new Date(data.confirm.saveData._single.value.slotDate).toDateString(),
      combinedString = `${slotDateString} ${data.confirm.saveData._single.value.slotTime}`,
      parsedSlotDateTime = new Date(combinedString);

    let dto = {
      partnerId: data.partner.saveData._single.value,
      membershipNumber: data.membership.saveData._single.value.primary.value.MembershipNumber,
      slotDateTime: parsedSlotDateTime,
      cost: data.confirm.saveData._single.value.cost,
      credits: data.confirm.saveData._single.value.credits,
      guestMemberMembershipNumbers: data.membership.saveData._single.value.guests.length > 0 ? data.membership.saveData._single.value.guests.map(a => a.value.MembershipNumber) : [],
      guestEmailAddresses: [],
      bookingRequestReferenceId: referralData?.ReferenceId || null
    }

    setIsPendingData(true);

    _redemptionServices.create(dto).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Created) {

        const redirectUrl = `/memberships/${dto.membershipNumber}/${response.Redemption?.ReferenceId}`;

        navigate(redirectUrl);

        toast(<Notification label="Redemption created" mode="success" />)
      } else {
        toast(<Notification title={response.apiMessage} label={response.reason} mode="error" />)
      }

      setIsPendingData(false);

    });

  }

  useEffect(() => {

    // redemption request referral query
    if (searchParams.get(EDIT_REDEMPTION_REQUEST_REFFERAL_QUERY_KEY)) {
      setFromReferral();
    }

  }, [searchParams])

  return <div className={styles.create}>
    <SectionTitle title="Create a new booking"></SectionTitle>
    <StepScreen data={stepsData} name="Create booking" className={styles.creator}>
      <StepScreenSection key="membership">
        <MembershipSelector data={stepsData.steps.membership} />
      </StepScreenSection>
      <StepScreenSection key="partner">
        <PartnerSelector data={stepsData.steps.partner} />
      </StepScreenSection>
      <StepScreenSection key="confirm">
        <Confirm data={stepsData.steps.confirm} summary={stepsData.steps} submit={stepsData.submit} />
      </StepScreenSection>
    </StepScreen>
    {isPendingData &&
      <CustomLoader label={"Creating booking"} pending={isPendingData} className={styles.loader} />}
  </div>
};

export default Create;

Create.propTypes = {

}
