import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './RedemptionList.module.css';
import _redemptionServicesApiService from '../../../../Application/ApiServices/Redemption/RedemptionServices/Services/RedemptionServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../Foundation/Rest/Constants';
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import Arrow from '../../../../Foundation/Helpers/Components/Feature/Arrow/Arrow';
import { Link, Outlet, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Notification from '../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import LinkButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import { ReactComponent as RedemptionIcon } from '../../../../Foundation/Theming/Icons/Navigation/redemption.svg';

const RedemptionList = ({ membershipNumber, className, locked = false }) => {

  const routeParams = useParams();
  const referenceId = routeParams.referenceId;
  const [redemptions, setRedemptions] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [failed, setFailed] = useState(false);

  const loadMessage = failed ? 'Error retrieving redemption history' : 'Success';

  useEffect(() => {

    if (typeof membershipNumber === 'undefined') {
      return null;
    }

    setIsPendingData(true);

    _redemptionServicesApiService.getByMembershipNumber(membershipNumber).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        setRedemptions(response.Redemptions);
      } else {
        toast(<Notification label="Unable to retrieve redemption history" mode="error" />)
        setFailed(true);
      }

      setIsPendingData(false);

    });

  }, [membershipNumber]);

  const renderRedemptions = (redemption, i) => {

    return <li className={`${styles.redemption}`} key={`${redemption.ReferenceId}_${i}`}>
      <Link to={`${redemption.ReferenceId}`}>
        <div className={`${styles.redemptionEntity}`}>
          <div className={styles.taxonomyIcon}>
            <RedemptionIcon />
          </div>
          <div className={styles.slotDateTime}>{new Date(redemption.SlotDateTime).toLocaleDateString()}</div>
          <div className={styles.partnerName}>{redemption.PartnerName}</div>
          <div className={styles.referenceId}>{redemption.ReferenceId}</div>
          <div className={styles.credits}>{redemption.Credits}</div>
          <div className={styles.arrow}>
            <Arrow direction='right' />
          </div>
        </div>
      </Link>
    </li>

  }

  return <div className={`${styles.panel} ${className}`}>
    <div className={styles.heading}>
      <h3>Redemptions</h3>
      {!locked && <div className={styles.new}>
        <LinkButton url={`/memberships/redemptions/create?membershipNumber=${membershipNumber}`} label={`New redemption`} solid primary />
      </div>}
    </div>
    {typeof referenceId === 'undefined' ?
      (typeof membershipNumber !== 'undefined' &&
        (redemptions !== null ? (redemptions.length > 0 ?
          <ul className={styles.redemptions}>
            {redemptions.map((m, i) => renderRedemptions(m, i))}
          </ul> :
          <div className={styles.noRedemptions}>
            No Redemptions
          </div>) :
          <CustomLoader label='Fetching redemption history' pending={isPendingData} failed={failed} message={loadMessage} className={styles.loader} />
        ))
      :
      <Outlet />
    }


  </div>

};

export default RedemptionList;

RedemptionList.propTypes = {
  membershipNumber: PropTypes.string.isRequired
}
