import React from 'react';
import styles from './Notification.module.css';
import { ReactComponent as Success } from '../../../../../Theming/Icons/Status/success.svg';
import { ReactComponent as Error } from '../../../../../Theming/Icons/Status/error.svg';
import { ReactComponent as Warning } from '../../../../../Theming/Icons/Status/warning.svg';
import { ReactComponent as Information } from '../../../../../Theming/Icons/Status/information.svg';

const Notification = ({ label, title = '', className = '', mode = 'success' }) => {

    const notificationMode = {
        'success': { class: styles.success, icon: <Success /> },
        'warning': { class: styles.warning, icon: <Warning /> },
        'information': { class: styles.information, icon: <Information /> },
        'error': { class: styles.error, icon: <Error /> }
    }

    return <div className={`${styles.appNotification} ${notificationMode[mode].class} ${className}`}>
        <span className={`${styles.decoration} notification-decoration`}></span>
        <div className={styles.icon}>
            <div className={styles.iconContainer}>
                {notificationMode[mode].icon}
            </div>
        </div>
        <div className={styles.body}>
            {title && <div className={`${styles.title} notification-title`}>{title}</div>}
            <div className={`${styles.label} notification-label`}>{label}</div>
        </div>
    </div>

}

export default Notification;

