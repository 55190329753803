class PaymentDetailsModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.ReferenceId = params.referenceId;
        this.Reason = params.reason;
        this.Amount = params.amount;
        this.Settled = params.settled;
        this.Pending = params.Pending;
        this.DueDate = params.dueDate;
        this.SettledDate = params.settledDate;
        this.CancelledDate = params.cancelledDate;
        this.RefundedDate = params.refundedDate;
        this.Status = params.status;
        this.StatusName = params.statusName;
        this.Credits = params.credits;

    }

}

export default PaymentDetailsModel;