import { toDateHtmlString } from '../../../../../Utility/DateHelpers';
import MembershipTypeModel from './MembershipTypeModel';

class MembershipModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.MembershipNumber = params.membershipNumber;
        this.Expired = params.expired;

        if(typeof params.startDate !== 'undefined' && params.startDate !== null){
            this.StartDate = toDateHtmlString(params.startDate);
        }

        if(typeof params.expiryDate !== 'undefined' && params.expiryDate !== null){
            this.ExpiryDate = toDateHtmlString(params.expiryDate);
        }
        
        this.MembershipType = new MembershipTypeModel(params.membershipType);

        this.Activated = params.activated;
        this.Locked = params.locked;
        
    }

}

export default MembershipModel;
