import SearchApiResponseBase from '../../../../../Base/Domain/SearchApiResponseBase';
import PartnerListItemModel from '../PartnerListItemModel';

class PartnerSearchResponseModel extends SearchApiResponseBase {

  constructor(params) {

    super(params, PartnerListItemModel);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

  }

}

export default PartnerSearchResponseModel;