import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CreateDetailsPanel.module.css';

const CreateDetailsPanel = ({ className, label }) => {

  return <div className={`${styles.panel} ${className}`}>
    {label && <h3>{label}</h3>}
  </div>

};

export default CreateDetailsPanel;

CreateDetailsPanel.propTypes = {
  label: PropTypes.string
}
