class PartnerSearchRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        if (params.SearchTerm !== '') {
            this.SearchTerm = params.SearchTerm;
        }

        if (typeof params.CountyIds !== 'undefined' && params.CountyIds.length !== 0) {
            this.CountyIds = params.CountyIds?.map((o) => o.value) || null;
        }

        if (params.PublishedOnly !== '') {
            this.PublishedOnly = params.PublishedOnly;
        }

    }

}

export default PartnerSearchRequestModel;