import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styles from './Login.module.css';
import LoginForm from '../../Authentication/Login/LoginForm';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import CustomLoader from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { loginUser } from '../../../Foundation/Store/Actions/Authentication/authenticationActions';
import { toast } from 'react-toastify';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';


const Login = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [requestPending, setRequestPending] = useState(false);
  const [requestResponse, setRequestResponse] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoginRequest, setIsLoginRequest] = useState(true);

  const requestPendingLabel = isLoginRequest ? "Logging in" : "Requesting password reset";
  const redirectResponseTimingModerator = isLoginRequest ? 1100 : 1500;

  const setRequestPendingStartState = () => {
    setHasSubmitted(false);
    setRequestPending(true);
  }

  const handleUiResponse = (response) => {
    setRequestPending(false);
    setHasSubmitted(true);
    setRequestResponse(response);

    if (response.apiStatus === HTTP_STATUS_CODES.Created) {
      toast(<Notification label="Logged in" mode="success" />)
    }

    setTimeout(() => {

      // redirect 
      if (location.state?.from) {
        navigate(location.state.from);
      }

    }, redirectResponseTimingModerator);
  }

  const resetPassword = (data) => {

    setIsLoginRequest(false);
    setRequestPendingStartState();

    // _resetPasswordApiService.request(data).then((response) => {
    //   handleUiResponse(response);
    // })

  }

  const login = (data) => {

    setIsLoginRequest(true);;
    setRequestPendingStartState();

    dispatch(loginUser(data)).then((response) => {
      handleUiResponse(response.login);
    });
  }

  return <>

    <div className={`${styles.login}`}>
      {!requestPending && !hasSubmitted &&
        <LoginForm onLoginSubmit={login} onResetSubmit={resetPassword} className={`${styles.mainLoginForm} panel`} />
      }
      {(requestPending || requestResponse !== null) && <CustomLoader label={requestPendingLabel}
        pending={requestPending}
        message={requestResponse?.apiMessage}
        succeeded={hasSubmitted && (requestResponse?.apiStatus === HTTP_STATUS_CODES.Created || requestResponse?.apiStatus === HTTP_STATUS_CODES.Success)}
        failed={hasSubmitted && (requestResponse?.apiStatus !== HTTP_STATUS_CODES.Created && requestResponse?.apiStatus !== HTTP_STATUS_CODES.Success)} />}
    </div>
  </>

};

function mapStateToProps(state) {
  return {
    // userAuthenticated: state.global.userAuthenticated
  }
}

export default connect(mapStateToProps)(Login);


