import CheckBoxList from "../Fields/CheckBoxList/CheckBoxList";
import ConfirmMatch from "../Fields/ConfirmMatch/ConfirmMatch";
import Display from "../Fields/Display/TextInput/Display";
import Dropdown from "../Fields/Dropdown/Dropdown";
import RadioButtonList from "../Fields/RadioButtonList/RadioButtonList";
import TextArea from "../Fields/TextArea/TextArea";
import TextInput from "../Fields/TextInput/TextInput";
import ImageUpload from "../Fields/ImageUpload/ImageUpload";
import CheckBox from "../Fields/Checkbox/CheckBox";
import Calendar from "../Fields/Calendar/Calendar";
import TimePicker from "../Fields/TimePicker/TimePicker";
import RichText from "../Fields/RichText/RichText";
import ToggleButton from "../Fields/ToggleButton/ToggleButton";

/**
 * @member FormFieldResolver
 * @description resolves a property name (type alias) to a Form Field component
 */
export const FormFieldResolver = {
    TextInput: TextInput,
    ConfirmMatch: ConfirmMatch,
    TextArea: TextArea,
    Dropdown: Dropdown,
    RadioButtonList: RadioButtonList,
    CheckBoxList: CheckBoxList,
    Display: Display,
    ImageUpload: ImageUpload,
    CheckBox: CheckBox,
    Calendar: Calendar,
    TimePicker: TimePicker,
    RichText: RichText,
    ToggleButton: ToggleButton
}

export const CLASS_MODIFIERS = {
    TextInput: "text",
    ConfirmMatch: "confirm-match",
    TextArea: "teaxtarea",
    Dropdown: "dropdown",
    RadioButtonList: "radio-button-list",
    CheckBoxList: "checkbox-list",
    Display: "display",
    ImageUpload: "image-upload",
    CheckBox: "checkbox",
    Calendar: "calendar",
    TimePicker: 'time-picker',
    RichText: 'rich-text',
    ToggleButton: 'toggle-button'
}

export const FIELD_TYPES = {
    TextInput: "TextInput",
    ConfirmMatch: "ConfirmMatch",
    TextArea: "TextArea",
    Dropdown: "Dropdown",
    RadioButtonList: "RadioButtonList",
    CheckBoxList: "CheckBoxList",
    Display: "Display",
    ImageUpload: "ImageUpload",
    CheckBox: "CheckBox",
    Calendar: "Calendar",
    TimePicker: "TimePicker",
    RichText: "RichText",
    ToggleButton: "ToggleButton"
}