import React from 'react';
import { Helmet } from 'react-helmet';

const Head = () => (
    <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Web site created using create-react-app" />
        <link href="https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300&display=swap" rel="stylesheet"></link>
        <title>Tee Up Portal</title>
    </Helmet>);
export default Head;