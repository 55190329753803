/**
* @fileoverview Api base layer 
*/

import ApiResponse from '../Domain/ApiResponse';
import { HTTP_METHODS, HTTP_STATUS_CODES, CONTENT_TYPES, RESPONSE_HEADERS } from '../../../_Domain/Api/Constants';

export class BaseRestService {

  // todo check safari compatibility with static fields...
  static authenticationToken;
  static on401 = null;

  headers = {}; // request headers, configurable as a field

  constructor(endpoint) {

    if (typeof endpoint === 'undefined') {
      console.error("Initialisation of BaseRestService requires a named API Endpoint")
    }

    this.apiEndpoint = endpoint;
  }

  /**
  * @public @methods get, post, put, patch, delete
  * @description performs common http methods to with a rest api
  * 
  * @param {string} resource the api endpoint resource
  * @param {object/number} data or id parameters for the api call
  * @return {Promise}
  */

  /**
   * 
   * @param {object} data object with request headers to set as kvp
   */
  setRequestHeaders(data) {
    for (let key in data) {
      this.headers[key] = data[key];
    }
  }

  /* -- */
  get(resource, id) {
    return this._request(resource, HTTP_METHODS.Get, id)
  }

  post(resource, data) {
    return this._request(resource, HTTP_METHODS.Post, data)
  }

  put(resource, data, id) {
    return this._request(resource, HTTP_METHODS.Put, data, id)
  }

  patch(resource, data, id) {
    return this._request(resource, HTTP_METHODS.Patch, data, id)
  }

  delete(resource, id) {
    return this._request(resource, HTTP_METHODS.Delete, id)
  }
  /* -- */

  _request = async (resource, method = "GET", data, id) => {

    let url = this._constructUrl(resource, data, id, method),
      isJson = false;

    if (this.headers[RESPONSE_HEADERS.ContentType] === CONTENT_TYPES.ApplicationJson) {
      isJson = true;
    }

    // has authentication token
    if (typeof this.constructor.authenticationToken !== 'undefined') {
      this.headers.Authorization = `Bearer ${this.constructor.authenticationToken}`;
    }

    let _mapResponse = this.#_mapResponse;

    try {
      return await fetch(url, {
        method: method,
        headers: this.headers,
        body: /GET|HEAD/.test(method) ? null : (isJson ? JSON.stringify(data) : data)
      }).then(response => response.json().then(function (data) {
        return _mapResponse(response, data);
      }).catch(() => {
        return _mapResponse(response, data);
      }));
    } catch (e) {
      console.error(e);
    }

  }

  #_mapResponse = (response, data) => {

    let status = response.status;

    switch (status) {
      case HTTP_STATUS_CODES.MethodNotAllowed:
      case HTTP_STATUS_CODES.BadRequest:
      case HTTP_STATUS_CODES.NotFound:
      case HTTP_STATUS_CODES.UnprocessableEntity:
      case HTTP_STATUS_CODES.InternalServerError:
        return new ApiResponse(status, data);
      case HTTP_STATUS_CODES.Forbidden:
        return new ApiResponse(status, { apiStatus: status});
      case HTTP_STATUS_CODES.Unauthorised:
        // todo here
        // if (typeof this.constructor.on401 === 'function') {
        //   this.constructor.on401();
        // }
        return new ApiResponse(status, data);
      case HTTP_STATUS_CODES.NoContent:
        return new ApiResponse(status, data);
      case HTTP_STATUS_CODES.Success:
      case HTTP_STATUS_CODES.Created:
      default:
        return new ApiResponse(status, data);
    }
  }

  _dataToQueryString = (data) => {

    if (data === null) {
      return '';
    }

    return Object.keys(data)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
      .join('&');
  }

  /**
   * @private @method _constructUrl
   * 
   * @param {string} resource API endpoint resource
   * @param {object} param json request body
   * @param {string} id (optional) where a resource is specified
   * @param {string} method http method
   * @returns {string} url
   */
  _constructUrl = function (resource, param, id, method) {

    // if post, put, patch (use body, as parameters come from request body on API)
    if (method === HTTP_METHODS.Post || method === HTTP_METHODS.Put || method === HTTP_METHODS.Patch) {
      if (typeof id === "undefined" || id === null) {
        return `${this.apiEndpoint}/${resource}`;
      } else {
        return `${this.apiEndpoint}/${resource}/${id}`;
      }
    }
    // get or delete
    return `${this.apiEndpoint}/${resource}${this._formatQueryParameters(param)}`;
  }

  /**
   * @private @method #_bodyOrAppend
   * @description query string params, URL append, or batch request format ({id},{id},{id})
   * 
   * @returns query string formed appropriately for a flat GET/DELETE request
   */
  _formatQueryParameters = (param) => {

    if (typeof param === 'undefined') {
      return '';
    } else if (Array.isArray(param)) {
      return `/(${param.toString(',')})`
    } else if (typeof param === 'object') {
      const query = this._dataToQueryString(param);
      return `?${query}`;
    } else {
      return `/${param}`;
    }

  }

}

export default BaseRestService;
