import { toDateHtmlString } from "../../../../Utility/DateHelpers";

class MembershipListingResultViewModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        this.MembershipNumber = params.MembershipNumber;
        this.StartDate = toDateHtmlString(params.StartDate);
        this.ExpiryDate = toDateHtmlString(params.ExpiryDate);
        this.Expired = params.Expired;
        this.Activated = params.Activated; 
        this.Locked = params.Locked;
        this.User = params.User;
        this.TypeName = params.TypeName;
    }

}

export default MembershipListingResultViewModel;