import { GET_COUNTIES_SUCCESS, GET_COUNTIES_ERROR, GET_PARTNERS_SUCCESS, GET_PARTNERS_ERROR } from '../../Actions/Utility/actionTypes';
import initialState from '../../initialState';

const utilityReducer = (state = initialState.utility, action) => {

    switch (action.type) {

        case GET_COUNTIES_SUCCESS:
            let updateCountiesState = {
                counties: action.counties?.Counties || null
            };
            return { ...state, ...updateCountiesState };
        case GET_COUNTIES_ERROR:
            let updateCountiesStateError = {
                counties: null
            };
            return { ...state, ...updateCountiesStateError };

        case GET_PARTNERS_SUCCESS:
            let updatePartnersState = {
                partners: action.partners?.PartnerList || null
            };
            return { ...state, ...updatePartnersState };
        case GET_PARTNERS_ERROR:
            let updatePartnersStateError = {
                partners: null
            };
            return { ...state, ...updatePartnersStateError };
        default:
            return state;
    }
};

export default utilityReducer;