import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import styles from './Logout.module.css';
import { logoutUser } from '../../../Foundation/Store/Actions/Authentication/authenticationActions';
import InlineLoader from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/InlineLoader/InlineLoader';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import { toast } from 'react-toastify';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';

const Logout = ({ loggingOut, className = '' }) => {

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutUser()).then((response) => {
      if (response?.logout?.apiStatus === HTTP_STATUS_CODES.NoContent) {
        toast(<Notification label="Logged out" mode="success" />)
      }
    });
  }

  return loggingOut ? <div className={`${styles.logoutLoader} ${className}`}><InlineLoader /></div> : <div className={styles.logoutButton} onClick={logout}>Logout</div>;

};

function mapStateToProps(state) {
  return {
    loggingOut: state.requests.logout
  }
}

export default connect(mapStateToProps)(Logout);

Logout.propTypes = {

}