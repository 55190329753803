import { toDateHtmlString, toDateTimeHtmlString } from "../../../../Utility/DateHelpers";

class RedemptionEntityViewModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        this.PartnerName = params.PartnerName;
        this.ReferenceId = params.ReferenceId;
        this.SlotDateTime = toDateTimeHtmlString(params.SlotDateTime);
        this.BookingDateTime = toDateHtmlString(params.BookingDateTime);
        this.Credits = params.Credits;
        this.Value = `£${params.Value}`;
        this.Cost = `£${params.Cost}`;
        this.MembershipRedemptions = params.MembershipRedemptions.map(a => { return { ...a, Cost: `£${a.Cost}` }}).sort((x, y) => Number(y.PrimaryRedemption) - Number(x.PrimaryRedemption));
    }

}

export default RedemptionEntityViewModel;