/**
 * @fileoverview represents the structure of the application store
 * 
 */

export default {
    authentication: {},
    user: null,
    membership: null,
    utility: {
        counties: null,
        partners: null
    },
    global: {
        offline: false
    },
    requests: {
        logout: false
    }
}
