import { GET_MEMBERSHIP_DATA_SUCCESS, GET_MEMBERSHIP_DATA_ERROR, UPDATE_MEMBERSHIP_STATE_SUCCESS, CLEAR_MEMBERSHIP_DATA_SUCCESS, UPDATE_MEMBERSHIP_PAYMENT_STATE_SUCCESS, ACTIVATE_MEMBERSHIP_SUCCESS, LOCK_UNLOCK_MEMBERSHIP_SUCCESS } from '../../Actions/Membership/actionTypes';

import initialState from '../../initialState';

const membershipReducer = (state = initialState.membership, action) => {

    switch (action.type) {
        case GET_MEMBERSHIP_DATA_SUCCESS:
        case UPDATE_MEMBERSHIP_STATE_SUCCESS:
            return { ...state, ...action.membership };
        case UPDATE_MEMBERSHIP_PAYMENT_STATE_SUCCESS:
            let res = { ...state };
            res.PaymentsSummary = { ...action.paymentsSummary }
            return { ...state, ...res };
        case ACTIVATE_MEMBERSHIP_SUCCESS:
            let activateUpdate = { ...state };
            activateUpdate.Activated = action.membership?.Activated;
            return { ...state, ...activateUpdate };
        case LOCK_UNLOCK_MEMBERSHIP_SUCCESS:
            let lockUnlockUpdate = { ...state };
            lockUnlockUpdate.Locked = action.membership?.Locked;
            return { ...state, ...lockUnlockUpdate };
        case CLEAR_MEMBERSHIP_DATA_SUCCESS:
            return null;
        default:
            return state;
    }
};

export default membershipReducer;