import React, { useEffect, useState, useRef } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import styles from './SubSectionNavigator.module.css';

const NavigationItem = ({ item }) => {

  let resolved = useResolvedPath(item.uri),
    match = useMatch({ path: resolved.pathname, end: typeof item.end === 'undefined' ? true : item.end });

  return <li className={`${styles.navigationItem} ${match ? styles.navigationItemActive : ''}`}>
    <Link to={item.uri} className={styles.navigationItemLink}>
      <div className={styles.label}>
        {item.label}
      </div>
    </Link>
  </li>;

}

const SubSectionNavigator = ({ data }) => {
  return <nav className={`${styles.sectionNav}`}>
    <ul className={styles.navigationItemList}>
      {data && Object.keys(data).map((key, i) => <NavigationItem key={`${data[key]?.id}_${i}_section`} item={data[key]} />)}
    </ul>
  </nav>
};

export default SubSectionNavigator;