import ApiResponseBase from '../../../../../Base/Domain/ApiResponseBase';
import CountyModel from '../CountyModel';

class CountyListResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.Counties = params.counties?.map(a => new CountyModel(a)) || null;

  }

}

export default CountyListResponseModel;