import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Confirm.module.css';
import Button from '../../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import formData from './ConfirmData.json';
import Form from '../../../../../Foundation/Helpers/Components/Forms/Form';

const MEMBERSHIP_SELECTOR_KEY = "MembershipSelection";

const Confirm = ({ data, summary, shared, submit, className }) => {

    const [renderFormReady, setRenderFormReady] = useState(false);

    const steps = summary;

    let orderedIndeces = [];

    for (let itemId in steps) {
        orderedIndeces.splice(steps[itemId].order, 0, itemId);
    }

    const confirmSubmit = () => {
        if (typeof submit === 'function') {
            submit(shared);
        }
    }

    const renderSummaryItem = (item, i) => {
        return <div className={styles.summaryItem} key={`summary-item_${i}`}>
            <div className={styles.summaryItemLabel}>{item._label}</div>
            {typeof i !== 'undefined' &&
                <div className={styles.summaryItemValue}>{item.value}</div>}
        </div>;
    }

    const renderMembershipSelectionSummaryItem = (item) => {

        const primary = item.value.primary,
            guests = item.value.guests;

        return <div className={styles.membershipSelection}>
            {typeof primary !== 'undefined' &&
                <div className={styles.primary}>
                    <div className={styles.membershipSelectionTitle}>Primary</div>
                    <div className={styles.membershipSelectionValue}>
                        {primary.label}
                    </div>
                </div>
            }
            {guests.length !== 0 && <div className={styles.guests}>
                <div className={styles.membershipSelectionTitle}>Guests</div>
                <div className={styles.membershipSelectionValue}>
                    {guests.map(a => <div key={`id_${a.id}`}>{a.label}</div>)}
                </div>
            </div>
            }
        </div>

    }

    const renderSummaryItems = (sectionSaveData) => {

        if (sectionSaveData._single?._key === MEMBERSHIP_SELECTOR_KEY) {
            return <div className={styles.summaryItems}>
                {renderMembershipSelectionSummaryItem(sectionSaveData._single)}
            </div>
        }

        return <div className={styles.summaryItems}>
            {sectionSaveData._single ?
                renderSummaryItem(sectionSaveData._single) : Object.keys(sectionSaveData).map((a, i) => renderSummaryItem(sectionSaveData[a], i))
            }
        </div>
    }

    const renderSummarySection = (sectionData, id, i) => {

        if (typeof sectionData === 'undefined' || Object.keys(sectionData).length === 0 || id === 'confirm') {
            return null;
        }

        return <div className={styles.summarySection} key={`${id}_${i}`}>
            <div className={styles.sectionLabel}>{steps[id]?.label}</div>
            {renderSummaryItems(sectionData.saveData)}
        </div>
    }

    const renderSummary = () => {

        if (typeof shared === 'undefined') {
            return null;
        }

        return <div className={`${styles.summary} panel-strong`}>
            {orderedIndeces.map((id, i) => renderSummarySection(shared[id], id, i))}
        </div>

    }

    const setSubmitValuesInState = (valOjb) => {
        let persistData = Object.assign({}, { _single: { _label: valOjb.label, value: valOjb.value } });
        data.updateState(true, persistData);
    }

    const onWatchEvent = (watchData) => {

        if (typeof watchData?.type === 'undefined') {
            return;
        }

        let label = `${new Date(watchData.value.SlotDate).toDateString()} ${watchData.value.SlotTime}`;
        setSubmitValuesInState({
            label, value: {
                slotDate: watchData.value.SlotDate,
                slotTime: watchData.value.SlotTime,
                cost: watchData.value.Cost,
                credits: watchData.value.Credits
            }
        });
    }

    const setDefaults = () => {

        // preloaded referral default data
        if (typeof data?.defaultData !== 'undefined') {
            let preloadedReferralDateTime = new Date(data?.defaultData.slotDateTime);
            const timeVal = preloadedReferralDateTime.getMinutes() === 0 ? "00" : preloadedReferralDateTime.getMinutes();
            const preloadedTime = `${preloadedReferralDateTime.getHours()}:${timeVal}`;

            formData.sections.MembershipFields.fields[0].defaultValue = preloadedReferralDateTime;
            formData.sections.MembershipFields.fields[1].defaultValue = preloadedTime;
            setSubmitValuesInState({ label: preloadedReferralDateTime, value: { slotDate: preloadedReferralDateTime, slotTime: { value: preloadedTime } } });

        } else {

            let date = new Date();
            let tomorrow = date.setDate(date.getDate() + 1); // set default date to tomorrow's date
            tomorrow = date.setHours(9);
            tomorrow = date.setMinutes(0);
            formData.sections.MembershipFields.fields[0].defaultValue = formData.sections.MembershipFields.fields[0].value = date;
            formData.sections.MembershipFields.fields[1].defaultValue = '9:00';

            setSubmitValuesInState({ label: date, value: { slotDate: date, slotTime: { value: '9:00' } } });
        }

        setRenderFormReady(true);

    }

    useEffect(() => {
        setDefaults();
    }, []);

    return <div className={styles.confirm}>
        {renderFormReady &&
            <>
                <div className={styles.title}>
                    <h3>Confirm new Booking</h3>
                </div>
                {renderSummary()}
                <Form model={formData} watchFields={["SlotDate", "SlotTime", "Cost", "Credits"]} onWatchEvent={onWatchEvent} />
                <Button className={styles.submitButton} onClick={confirmSubmit}>Create Booking</Button>
            </>
        }
    </div>;

};

export default Confirm;

Confirm.propTypes = {

}