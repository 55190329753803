import { toDateTimeHtmlString } from "../../../../Utility/DateHelpers";
import PartnerSummaryViewModel from "./PartnerDetailsSummary/PartnerSummaryViewModel";

class BookingRequestEntityViewModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        this.BookingRequestDateTime = toDateTimeHtmlString(params.BookingRequestDateTime);
        this.SlotDateTime = toDateTimeHtmlString(params.SlotDateTime);
        this.PartnerId = params.PartnerId;
        this.CreditsPerMember = params.CreditsPerMember;
        this.ValuePerMember = params.ValuePerMember;
        this.ReferenceId = params.ReferenceId;
        this.Confirmed = params.Confirmed;
        this.Cancelled = params.Cancelled;
        this.Partner = new PartnerSummaryViewModel(params.PartnerSummary);       
        this.PrimaryMembership = params.PrimaryMembership;
        this.GuestMemberships = params.GuestMemberships;
    }

}

export default BookingRequestEntityViewModel;