import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../../../../Theming/Icons/Brand/home.svg';
import styles from './AppHeaderHomeLink.module.css';


const AppHeaderHomeLink = ({ className }) => {

  return <header className={`${styles.appNavHeader} ${className}`}>
    <div className={`${styles.homeIcon} panel`}>
      <Link to={'/'} className={styles.navigationItemLink}>
        <HomeIcon />
      </Link>
    </div>
  </header>;

};

export default AppHeaderHomeLink;

AppHeaderHomeLink.propTypes = {

}