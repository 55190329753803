import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../../Foundation/Helpers/Components/Feature/Common/SectionTitle/SectionTitle';
import SectionNavigator from '../Global/SectionNavigator/SectionNavigator';
import { Outlet } from 'react-router-dom';

const navigationData = {

  Dashboard: {
    id: "dashboard",
    uri: '',
    label: "Dashboard"
  },
  Credits: {
    id: "credits",
    uri: 'credits',
    label: "Credits",
  }
}

const System = () => {

  return <div className="system">
    [To be implemented]
    <SectionTitle title="System Configuration"></SectionTitle>
    <SectionNavigator data={navigationData} />
    <Outlet />
  </div>;

};

export default System;

System.propTypes = {

}

