import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import GetRedemptionRequestResponseModel from '../Models/Get/GetRedemptionRequestResponseModel';

const API_ENDPOINT = process.env.REACT_APP_REDEMPTION_MEMBERSERVICES_API_HOST;

class RedemptionRequestServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method getByReferenceId
   * @type {GET}
   * @description as an admin user, get a redemption request by reference id
   * 
   * @param {string} id resource id (reference id)
   * @returns {Task} RedemptionRequest
   */
  getByReferenceId = async (id) => this._restService.get(null, `${RESOURCE}${ACTIONS.GetByReferenceId}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetRedemptionRequestResponseModel(result));

  /**
   * @method cancelByReferenceId
   * @type {DELETE}
   * @description as an admin user, cancel a redemption request by reference id
   * 
   * @param {string} id resource id (reference id)
   * @returns {Task} RedemptionRequest
   */
   cancelByReferenceId = async (id) => this._restService.delete(null, `${RESOURCE}${ACTIONS.CancelByReferenceId}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetRedemptionRequestResponseModel(result));

}

export default new RedemptionRequestServicesApiService();