class PartnerSummaryViewModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        this.Name = params.name;
        this.CountyName = params.countyName;
        this.Territory = params.territoryName;
        this.Telephone = params.telephone;
    }

}

export default PartnerSummaryViewModel;