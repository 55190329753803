class CreateRedemptionRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return;
        }

        this.PartnerId = params.partnerId;
        this.MembershipNumber = params.membershipNumber;
        this.SlotDateTime = params.slotDateTime;
        this.Cost = params.cost;
        this.CreditsPerMember = params.credits;
        this.GuestMemberMembershipNumbers = params.guestMemberMembershipNumbers;
        this.GuestEmailAddresses = params.guestEmailAddresses;
        this.BookingRequestReferenceId = params.bookingRequestReferenceId;

    }

}

export default CreateRedemptionRequestModel;