import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserSelectorResultItem.module.css';
import ProfileRoundel from '../../../../../../Foundation/Helpers/Components/Feature/Common/ProfileRoundel/ProfileRoundel';
import Button from '../../../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';

const UserSelectorResultItem = ({ data, index, selectedId, onSelect }) => {

  return <div className={`panel-strong ${styles.item} ${selectedId === data.AccountNumber ? styles.selected : ''}`}>
    <div className={styles.inner}>
      <div className={styles.profile}><div className={styles.roundel}><ProfileRoundel data={[data.FirstName, data.LastName]} imageUri={data.ProfileImageUri} /></div>
        <div className={styles.summary}>
          <div className={styles.name}>{data.FirstName} {data.LastName}</div>
          <div className={styles.accountNumber}>{data.AccountNumber}</div>
          <div className={styles.email}>{data.Email}</div>
        </div>
      </div>
      <div className={styles.select}>
        <Button noArrow={true} skinny onClick={() => onSelect(data)}>Select</Button>
      </div>
    </div>
  </div>;
};

export default UserSelectorResultItem;

UserSelectorResultItem.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
  selectedId: PropTypes.string,
  onSelect: PropTypes.func.isRequired
}