/**
 * @class ApiResponse standard response from a rest API
 */

export default class ApiResponse {

    constructor(status, data) {
        this.status = status;
        this.data = data;
    }

}