export default class SearchResult {

    /**
     * @constructor
     * @param {object} params response data
     * @param {*} ListItemType Class for list item data to be cast to
     * @returns 
     */
    constructor(params, ListItemType) {

        if (typeof params === 'undefined' || params === null) {
            return null;
        }

        this.Count = params.results.count;
        this.Items = params.results?.items?.map(i => typeof ListItemType === 'function' ? new ListItemType(i) : i)

    }

}