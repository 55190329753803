import React from 'react';
import styles from './MembershipPaymentsHeadline.module.css';
import _membershipsMemberServicesApiService from '../../../../Application/ApiServices/Membership/MembershipMemberServices/Services/MembershipMemberServicesApiService';
import { ReactComponent as Warning } from '../../../../Foundation/Theming/Icons/Status/warning.svg';
import { connect } from 'react-redux';
import MembershipHeader from '../Membership/MembershipHeader/MembershipHeader';

const MembershipPaymentsHeadline = ({ membership }) => {

  const paymentsSummary = membership?.PaymentsSummary;

  return <div className={`${styles.membershipHeadline}`}>
    <div className={`${styles.membershipSummary} ${membership?.Expired && styles.membershipExpired}`}>
      {membership !== null ?
        <>
          <MembershipHeader data={membership} />
          <div className={`${styles.stats}`}>
            <div className={`${styles.totalSaving} ${styles.field}`}>
              <div className={`${styles.value} ${styles.total}`}>£{paymentsSummary.Total}</div>
              <div className={styles.label}>Total</div>
            </div>
            <div className={`${styles.totalSaving} ${styles.field}`}>
              <div className={styles.value}>£{paymentsSummary.Settled}</div>
              <div className={styles.label}>Settled</div>
            </div>
            <div className={`${styles.totalCost} ${styles.field}`}>
              <div className={`${styles.value} ${styles.balance} ${paymentsSummary.Outstanding > 0 ? styles.outstanding : ''}`}>£{paymentsSummary.Outstanding}</div>
              <div className={styles.label}>Outstanding</div>
            </div>
          </div>
        </> :
        <div className={styles.notAvailable}>
          <Warning /> Unable to retrieve membership summary information
        </div>}
    </div>

  </div>

};

function mapStateToProps(state) {
  return {
    membership: state.membership
  }
}

export default connect(mapStateToProps)(MembershipPaymentsHeadline);

MembershipPaymentsHeadline.propTypes = {

}