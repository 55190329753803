/**
  * @implements a refresh request model
  *
  */
class RefreshRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return;
        }

        this.RefreshToken = params.refreshToken || null;

    }

}

export default RefreshRequestModel;