class MembershipSearchSummaryRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        if (params.SearchTerm !== '') {
            this.SearchTerm = params.SearchTerm;
        }

    }

}

export default MembershipSearchSummaryRequestModel;