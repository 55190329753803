class UpdateBasicDetailsRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return;
        }

        this.FirstName = params.FirstName;
        this.LastName = params.LastName;
        this.CountyId = params.CountyId?.value;
        this.AccountNumber = params.AccountNumber;
        this.HouseNameNumber = params.HouseNameNumber;
        this.FirstLineAddress = params.FirstLineAddress;
        this.SecondLineAddress = params.SecondLineAddress;
        this.PostCode = params.PostCode;
        this.Telephone = params.Telephone;

    }

}

export default UpdateBasicDetailsRequestModel;