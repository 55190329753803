import { toDateHtmlString } from "../../../Utility/DateHelpers";

class PaymentEntityViewModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        this.ReferenceId = params.ReferenceId;
        this.Reason = params.Reason;
        this.Amount = params.Amount;
        this.AmountDisplay = `£${params.Amount}`;
        this.Settled = params.Settled;
        this.Pending = params.Pending;
        this.DueDate = params.DueDate;
        this.DueDateDisplay = toDateHtmlString(params.DueDate);
        this.SettledDate = toDateHtmlString(params.SettledDate);
        this.CancelledDate = toDateHtmlString(params.CancelledDate);
        this.RefundedDate = toDateHtmlString(params.RefundedDate);
        this.Status = params.Status;
        this.StatusName = params.StatusName;
        this.Credits = params.Credits;
    }

}

export default PaymentEntityViewModel;