import { LOGIN_SUCCESS, LOGOUT_SUCCESS, LOGIN_STATE_COMPLETE, LOGIN_STATE_FAILED_COMPLETE, LOGIN_ERROR, LOGOUT_ERROR } from '../../Actions/Authentication/actionTypes';
import initialState from '../../initialState';

const authenticationReducer = (state = initialState.authentication, action) => {

    switch (action.type) {

        case LOGIN_SUCCESS:
        case LOGIN_STATE_COMPLETE:
            let updateState = {
                isAuthenticated: true,
                accessToken: action.login?.AccessToken,
                refreshToken: action.login?.RefreshToken
            };
            return { ...state, ...updateState };
        case LOGIN_STATE_FAILED_COMPLETE:
        case LOGIN_ERROR:
        case LOGOUT_SUCCESS:
            let updateFailedState = {
                isAuthenticated: false
            };
            return { ...state, ...updateFailedState };

        case LOGOUT_ERROR:
            return { ...state.authentication };
        default:
            return state;
    }
};

export default authenticationReducer;