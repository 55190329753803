import SearchApiResponseBase from '../../../../../Base/Domain/SearchApiResponseBase';
import RedemptionRequestListItemModel from '../RedemptionRequestListItemModel';

class RedemptionRequestSearchResponseModel extends SearchApiResponseBase {

  constructor(params) {

    super(params, RedemptionRequestListItemModel);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

  }

}

export default RedemptionRequestSearchResponseModel;