import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldBase from '../Base/FieldBase';
import { CLASS_MODIFIERS } from '../../Utility/_formsConfig';

const CheckBoxList = ({ name, register, label, defaultValue, alive, options, errors, incrementor, className ='', validation = {} }) => {

    const [value, setValue] = useState(Array.isArray(defaultValue) ? defaultValue : `${defaultValue}`?.split(',') || []);

    const onValueChange = (val) => {

        let stateVal = [...value];

        if (value.includes(val.target.value)) {
            if (!val.target.checked) {
                stateVal = value?.filter(item => item !== val.target.value);
            }
        } else {
            stateVal.push(val.target.value);
        };

        setValue([...stateVal]);
        return val;
    }

    const renderCheckBox = (checkbox, i) => {

        const checked = value?.includes(checkbox.value);

        return <div className="checkbox" key={`${checkbox.label}_${i}`}>
            <label>
                <div className="checkbox__control-container">
                    <div className={`checkbox__control ${checked ? 'checked' : ''}`} tabIndex="1" role="checkbox" aria-checked={checked}>
                        <input {...register(name, validation)} type="checkbox" onChange={onValueChange} checked={checked} value={checkbox.value} />
                        <span className="checkbox__control-mask">{checked && <span className="checkmark draw"></span>}</span>
                    </div>
                </div>
                <span className="checkbox__label">
                    {checkbox.label}
                </span>
            </label>
        </div>
    }

    return <FieldBase {...{ errors, alive, ['classModifier']: CLASS_MODIFIERS.CheckBoxList, label, incrementor, className }}>
        <div className="form-field--checkbox-list__list" role="checkboxgroup">
            {options?.map((checkbox, i) => renderCheckBox(checkbox, i))}
        </div>
    </FieldBase>
};

export default CheckBoxList;

CheckBoxList.propTypes = {
    incrementor: PropTypes.number.isRequired
}