import { GET_COUNTIES_SUCCESS, GET_COUNTIES_ERROR, GET_PARTNERS_SUCCESS, GET_PARTNERS_ERROR } from './actionTypes';
import _countyServicesApiService from '../../../../Application/ApiServices/Utility/UserInterfaceServices/County/Services/CountyServicesApiService';
import _partnerServicesApiService from '../../../../Application/ApiServices/Utility/UserInterfaceServices/Partner/Services/PartnerServicesApiService';

const UTILITY_API_RESPONSE_CODES = {
    GetCountiesSuccessful: 200,
    GetPartnersSuccessful: 200
};

/* get counties */
export const getCountiesSuccess = (counties) => ({ type: GET_COUNTIES_SUCCESS, counties })
export const getCountiesError = (counties) => ({ type: GET_COUNTIES_ERROR, counties })

export function getCounties() {

    return (dispatch, getState) => {
        return _countyServicesApiService.get().then(response => {
            if (response?.apiStatus === UTILITY_API_RESPONSE_CODES.GetCountiesSuccessful) {
                let result = dispatch(getCountiesSuccess(response));
                if (result?.counties?.apiStatus === UTILITY_API_RESPONSE_CODES.GetCountiesSuccessful) {
                    return result;
                }
            }
            return dispatch(getCountiesError(response));
        });
    };
}


export const getPartnersSuccess = (partners) => ({ type: GET_PARTNERS_SUCCESS, partners })
export const getPartnersError = (partners) => ({ type: GET_PARTNERS_ERROR, partners })

export function getPartners() {

    return (dispatch, getState) => {
        return _partnerServicesApiService.get().then(response => {
            if (response?.apiStatus === UTILITY_API_RESPONSE_CODES.GetPartnersSuccessful) {
                let result = dispatch(getPartnersSuccess(response));
                if (result?.partners?.apiStatus === UTILITY_API_RESPONSE_CODES.GetPartnersSuccessful) {
                    return result;
                }
            }
            return dispatch(getPartnersError(response));
        });
    };
}