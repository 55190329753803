import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import UserSearchRequestModel from '../Models/UserList/Get/UserSearchRequestModel';
import UserSearchResponseModel from '../Models/UserList/Get/UserSearchResponseModel';
import UserSearchSummaryRequestModel from '../Models/UserList/Get/UserSearchSummaryRequestModel';
import UserSearchSummaryResponseModel from '../Models/UserList/Get/UserSearchSummaryResponseModel';

const API_ENDPOINT = process.env.REACT_APP_USER_ACCOUNTSERVICES_API_HOST;

class UserSearchServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method search
   * @type {POST}
   * @description as an admin user, search users by parameters
   * 
   * @param {string} id resource id (user account number)
   * @returns {Task} [UserDetails]
   */
   search = async (params) => this._restService.post(new UserSearchRequestModel(params), `${RESOURCE}${ACTIONS.Search}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new UserSearchResponseModel(result));

   /**
   * @method searchSummary
   * @type {POST}
   * @description as an admin user, search users by parameters for user summary list
   * 
   * @param {string} id resource id (user account number)
   * @returns {Task} [UserSummary]
   */
    searchSummary = async (params) => this._restService.post(new UserSearchSummaryRequestModel(params), `${RESOURCE}${ACTIONS.SearchSummary}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new UserSearchSummaryResponseModel(result));

}

export default new UserSearchServicesApiService();