import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './BookingRequest.module.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { HTTP_STATUS_CODES } from '../../../../Foundation/Rest/Constants';
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { toast } from 'react-toastify';
import Notification from '../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import { ReactComponent as RedemptionRequestIcon } from '../../../../Foundation/Theming/Icons/Navigation/redemptionrequest.svg';
import LinkButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import ProfileRoundel from '../../../../Foundation/Helpers/Components/Feature/Common/ProfileRoundel/ProfileRoundel';
import BookingRequestEntityViewModel from './BookingRequestEntityViewModel';
import ActionsPanel from '../../../Details/ActionsPanel/ActionsPanel';
import Button from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import ConfirmButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/ConfirmButton/ConfirmButton';
import { LocalStorageHelper } from '../../../../Utility/LocalStorageHelper';
import { EDIT_REDEMPTION_REQUEST_REFFERAL_LOCAL_STORAGE_KEY, EDIT_REDEMPTION_REQUEST_REFFERAL_QUERY_KEY } from '../../constants';
import _redemptionRequestServicesApiService from '../../../../Application/ApiServices/Redemption/RedemptionRequestServices/Services/RedemptionRequestServicesApiService';
import _redemptionServices from '../../../../Application/ApiServices/Redemption/RedemptionServices/Services/RedemptionServicesApiService';
import Modal from 'react-modal';
import QuickConfirmBooking from './QuickConfirmBooking/QuickConfirmBooking';
import PartnerDetailsSummary from './PartnerDetailsSummary/PartnerDetailsSummary';

const FAILED_MESSAGE = "Unable to retrieve booking request details",
  CANCEL_FAILED_MESSAGE = "Unable to cancel booking request",
  DATA_API_PROPERTIES_KEY = "RedemptionRequest";

const _localStorageHelper = new LocalStorageHelper();

const deleteConfirmScreenData = {
  title: "Confirm cancel",
  message: "Are you sure you want to cancel the Booking Request?",
  notificationText: "Confirming this will cancel the booking request and the user will be notified",
  rejectLabel: "Cancel",
  confirmLabel: "Confirm"
}

const quickConfirmScreenData = {
  title: "Quick Confirm",
  message: "Are you sure you want to confirm this booking?",
  notificationText: "Confirming this will create the booking, notify the user and create a redemption",
  rejectLabel: "Cancel",
  confirmLabel: "Confirm"
}

const BookingRequest = () => {

  const [redemptionRequestDetailsApiResponse, setRedemptionRequestDetailsApiResponse] = useState(null);
  const [rawResponseData, setRawResponseData] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [failed, setFailed] = useState(false);
  const [quickConfirmDialogOpen, setQuickConfirmDialogOpen] = useState(false);
  const loadMessage = failed ? FAILED_MESSAGE : 'Success';

  const routeParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const referenceId = routeParams.referenceId;
  const redemptionRequestData = redemptionRequestDetailsApiResponse && redemptionRequestDetailsApiResponse[DATA_API_PROPERTIES_KEY];

  useEffect(() => {

    setIsPendingData(true);

    _redemptionRequestServicesApiService.getByReferenceId(referenceId).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        setRawResponseData(response[DATA_API_PROPERTIES_KEY]);
        response[DATA_API_PROPERTIES_KEY] = new BookingRequestEntityViewModel(response[DATA_API_PROPERTIES_KEY]);
        setRedemptionRequestDetailsApiResponse(response);
      } else {
        toast(<Notification label={FAILED_MESSAGE} mode="error" />)
        setFailed(true);
      }

      setIsPendingData(false);

    });

  }, [referenceId]);

  const goToEdit = () => {

    if (typeof redemptionRequestData === 'undefined') {
      return null;
    }

    const picked = (({ ReferenceId, PrimaryMembership, GuestMemberships, SlotDateTime, PartnerName, PartnerId }) =>
      ({ ReferenceId, PrimaryMembership, GuestMemberships, SlotDateTime, PartnerName, PartnerId }))(rawResponseData);

    _localStorageHelper.set(`${EDIT_REDEMPTION_REQUEST_REFFERAL_LOCAL_STORAGE_KEY}_${picked.ReferenceId}`, picked);

    navigate(`${location.pathname}/../../create?${EDIT_REDEMPTION_REQUEST_REFFERAL_QUERY_KEY}=${picked.ReferenceId}`);

  }

  const onQuickConfirmReject = () => {
    setQuickConfirmDialogOpen(false);
  }

  const onQuickConfirmConfirm = (slotDateTime, cost, credits) => {

    if (rawResponseData === null) {
      return null;
    }

    const dto = {
      bookingRequestReferenceId: rawResponseData.ReferenceId,
      partnerId: rawResponseData.PartnerId,
      cost: cost,
      credits: credits,
      membershipNumber: rawResponseData.PrimaryMembership?.MembershipNumber,
      slotDateTime: slotDateTime || rawResponseData.SlotDateTime,
      guestMemberMembershipNumbers: rawResponseData.GuestMemberships?.map(a => a.MembershipNumber),
      guestEmailAddresses: []
    };

    setIsPendingData(true);

    _redemptionServices.create(dto).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Created) {

        const redirectUrl = `/memberships/${dto.membershipNumber}/${response.Redemption?.ReferenceId}`;

        navigate(redirectUrl);

        toast(<Notification label="Redemption created" mode="success" />)
      } else {
        toast(<Notification title={response.apiMessage} label={response.reason} mode="error" />)
      }

      setIsPendingData(false);

    });

  }

  const onDeleteReject = () => {

  }

  const onDeleteConfirm = () => {

    setIsPendingData(true);

    _redemptionRequestServicesApiService.cancelByReferenceId(referenceId).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        response[DATA_API_PROPERTIES_KEY] = new BookingRequestEntityViewModel(response[DATA_API_PROPERTIES_KEY]);
        setRedemptionRequestDetailsApiResponse(response);
      } else {
        toast(<Notification label={CANCEL_FAILED_MESSAGE} mode="error" />)
        setFailed(true);
      }

      setIsPendingData(false);

    });

  }

  const renderMember = (member, primary) => {

    const user = member.User;
    const title = primary ? "Primary member" : "Guest member";

    return <div title={title} key={`${user.Email}_memberrequest`} className={`${styles.userLink} ${primary && styles.primaryMember}`}>
      <Link to={`/users/${user.AccountNumber}/${member.MembershipNumber}`}>
        <div className={styles.profileInfo}>
          {user.ProfileImageUri && <ProfileRoundel imageUri={user.ProfileImageUri} className={`${styles.userRoundel}`} />}
          <div className={styles.memberDetails}>
            <div className={styles.memberUserName}>{user.FirstName} {user.LastName}</div>
            <div className={styles.membershipNumber}>{member.MembershipNumber}</div>
          </div>
        </div>
        <div className={`${styles.membershipCreditsRemaining}`}>
          <div className={`${styles.statsValue} ${member.CreditsRemaining < redemptionRequestData.CreditsPerMember && styles.error}`}>{member.CreditsRemaining}</div>
        </div>
      </Link>
    </div>
  }

  return <div className={`${styles.redemptionRequest} layout__sub-section`}>
    <div className={`${styles.panel} layout__side-panel`}>
      <ActionsPanel title="Booking Request" actions={redemptionRequestData !== null &&
        <>
          <div className={"cf"}>
            <LinkButton url="../requests" label={"Search Booking Requests"} arrowDirection="left" className={styles.backLink} mode="small" />
          </div>
          <div className={styles.processButtons}>
            {redemptionRequestData.Cancelled === false && redemptionRequestData.Confirmed === false &&
              <>
                <Button type="submit" secondary onClick={goToEdit}>Amend</Button>
                <Button type="submit" noArrow primary onClick={() => setQuickConfirmDialogOpen(true)}>Quick Confirm</Button>
                <ConfirmButton noArrow danger onConfirmReject={onDeleteReject} onConfirm={onDeleteConfirm} dialogData={deleteConfirmScreenData}>Cancel</ConfirmButton>
                <Modal isOpen={quickConfirmDialogOpen}
                  overlayClassName="sys-modal"
                  className="modal-body"
                  ariaHideApp={false} >
                  <QuickConfirmBooking pending={isPendingData} time={rawResponseData.SlotDateTime} credits={rawResponseData.CreditsPerMember} confirm={onQuickConfirmConfirm} reject={onQuickConfirmReject} />
                </Modal>
              </>}
          </div>
        </>
      } />

    </div>
    <div className={`layout__editor-panel ${styles.redemptionRequestBody} ${redemptionRequestData?.Cancelled === true ? styles.redemptionRequestCancelledBody : ''}`}>
      <div className={`${styles.redemption}`}>
        {redemptionRequestDetailsApiResponse !== null ?
          redemptionRequestData !== null &&
          <div className={`${styles.redemptionSummary}`}>
            <div className={`${styles.headline}`}>
              <div className={styles.leading}>
                <div className={styles.taxonomyIcon}>
                  <RedemptionRequestIcon />
                </div>
                {redemptionRequestData && <div className={styles.label}><span className={styles.referenceIdValue}>{redemptionRequestData.ReferenceId}</span></div>}
              </div>
              {redemptionRequestData.Cancelled === true && <div className={`${styles.cancelled} detail-label`}>Cancelled</div>}
              {redemptionRequestData.Confirmed === true && redemptionRequestData.Cancelled === false && <div className={`${styles.confirmed} detail-label`}>Confirmed</div>}
            </div>
            <div className={styles.venue}>
              <div className={`${styles.field}`}>
                <div className={styles.label}>Course</div>
                  <PartnerDetailsSummary model={redemptionRequestData.Partner} className={`${styles.partnerDetails} ${styles.value}`} />
              </div>
              <div className={`${styles.dates} ${styles.field}`}>
                <div className={styles.label}>At</div>
                <div className={`${styles.dateField} ${styles.slotDate} ${styles.field}`}>
                  <div className={`${styles.value} detail-label`}>{parse(redemptionRequestData.SlotDateTime)}</div>
                  <div className={styles.subLabel}>Booked on</div>
                  <div className={`${styles.value} detail-label ${styles.secondaryValue}`}>{parse(redemptionRequestData.BookingRequestDateTime)}</div>
                </div>
              </div>
              <div className={`${styles.creditsAndFees} ${styles.field}`}>
                <div className={styles.label}>Value</div>
                <div className={styles.creditsAndFeesValues}>
                  <div className={`${styles.stats}`}>
                    <div className={`${styles.redemptionValue} ${styles.field}`}>
                      <div className={styles.label}>Value per Member</div>
                      <div className={`${styles.value} ${styles.statsValue}`}>£{redemptionRequestData.ValuePerMember}</div>

                    </div>
                    <div className={`${styles.creditsValue} ${styles.field}`}>
                      <div className={styles.label}>Credits per Member</div>
                      <div className={`${styles.value} ${styles.statsValue}`}>{redemptionRequestData.CreditsPerMember}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.members} ${styles.field}`}>
                <div className={styles.label}>Members</div>
                <div className={styles.value}>
                  <div className={styles.membersListHeading}>
                    <div className={styles.listHeadingName}></div>
                    <div className={styles.listHeadingCredits}>Available Credits</div>
                  </div>
                  {renderMember(redemptionRequestData.PrimaryMembership, true)}
                  {redemptionRequestData.GuestMemberships.map(a => renderMember(a))}
                </div>
              </div>
            </div>
          </div>
          :
          <CustomLoader label='Fetching booking request details' pending={isPendingData} failed={failed} message={loadMessage} className={styles.loader} />}
      </div>
    </div>
  </div>
};

export default BookingRequest;

BookingRequest.propTypes = {

}