/**
  * @implements a state login model
  *
  */
class StateLoginModel {

  constructor(params) {

    if (typeof params === 'undefined') {
      return;
    }

    this.AccessToken = params?.auth?.accessToken || null;
    this.RefreshToken = params?.auth?.refreshToken || null;

  }

}

export default StateLoginModel;