class MembershipPaymentModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.Amount = params.amount;
        this.DueDate = params.dueDate;
        this.SettledDate = params.settledDate;
        this.Settled = params.settled;
        this.Credits = params.credits;
        this.Reason = params.reason;
        this.ReferenceId = params.referenceId;
        this.Status = params.status;
        this.StatusName = params.statusName;

    }

}

export default MembershipPaymentModel;
