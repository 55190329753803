import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Edit.module.css';
import Membership from '../Shared/Membership/Membership';
import ActionsPanel from '../../Details/ActionsPanel/ActionsPanel';
import Button from '../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import AdjustCreditBalance from './AdjustCreditBalance/AdjustCreditBalance';
import LinkButton from '../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import { connect, useDispatch } from 'react-redux';
import { activateMembership, lockMembership, unlockMembership } from '../../../Foundation/Store/Actions/Membership/membershipActions';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import { toast } from 'react-toastify';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';

const Edit = ({ membership }) => {

  const [adjustCreditBalanceActive, setAdjustCreditBalanceActive] = useState(false);
  const [activatePending, setActivatePending] = useState(false);
  const [lockUnlockPending, setLockUnlockPending] = useState(false);
  const dispatch = useDispatch();

  const msg = membership?.Locked ? 'lock' : 'unlock';

  const lockUnlock = () => {

    if (lockUnlockPending) {
      return null;
    }

    setLockUnlockPending(true);

    dispatch(membership.Locked ? unlockMembership(membership.MembershipNumber) : lockMembership(membership.MembershipNumber)).then((response) => {

      setLockUnlockPending(false);

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        toast(<Notification label={`Membership ${msg}ed`} mode="success" />)
      } else {
        toast(<Notification label={`Membership ${msg} failed`} mode="error" />)
      }

    });

  }

  const activate = () => {

    if (activatePending) {
      return null;
    }

    setActivatePending(true);

    dispatch(activateMembership(membership.MembershipNumber)).then((response) => {

      setActivatePending(false);

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        toast(<Notification label="Membership activated" mode="success" />)
      } else {
        toast(<Notification label="Membership activation failed" mode="error" />)
      }

    });

  }

  return <div className={`${styles.edit} layout__sub-section`}>
    <div className={`${styles.panel} layout__side-panel`}>
      {membership && <>
        <ActionsPanel title="Membership" links={
          <>
            <LinkButton url={`payments`} label="Payments" secondary solid />
          </>
        } actions={
          <>
            <div className=''>
              <Button className='btn' onClick={() => setAdjustCreditBalanceActive(true)}>Adjust Credit Balance</Button>
              <AdjustCreditBalance active={adjustCreditBalanceActive} onClose={() => setAdjustCreditBalanceActive(false)} />
            </div>
            {!membership.Activated && <div className=''>
              <Button className='btn' loading={activatePending} primary onClick={activate}>Activate</Button>
            </div>}
            <div className=''>
              <Button className='btn' loading={lockUnlockPending} secondary={!membership.Locked} primary={membership.Locked} onClick={lockUnlock}>{membership.Locked ? "Unlock" : "Lock"}</Button>
            </div>
            <div className=''>
              <Button disabled={true} className='btn btn--danger'>Cancel</Button>
            </div>
          </>
        } />
      </>}
    </div>
    <div className={`layout__editor-panel ${styles.editor}`}>
      <Membership />
    </div>

  </div>

};

function mapStateToProps(state) {
  return {
    membership: state.membership
  }
}

export default connect(mapStateToProps)(Edit);

Edit.propTypes = {

}