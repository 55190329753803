import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import AdminAccountDetailsRequestModel from '../Models/AccountSummary/Get/AdminAccountDetailsRequestModel';
import AdminAccountDetailsResponseModel from '../Models/AccountSummary/Get/AdminAccountDetailsResponseModel';
import AdminUpdateBasicDetailsRequestModel from '../Models/AccountSummary/Update/AdminUpdateBasicDetailsRequestModel';
import AdminUpdateBasicDetailsResponseModel from '../Models/AccountSummary/Update/AdminUpdateBasicDetailsResponseModel';
import AdminAccountSummaryResponseModel from '../Models/AccountSummary/Get/AdminAccountSummaryResponseModel';

const API_ENDPOINT = process.env.REACT_APP_USER_ACCOUNTSERVICES_API_HOST;

class AdminAccountServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
  }

  /**
   * @method get
   * @type {GET}
   * @description as an admin user, gets account details by account number
   * 
   * @param {string} id resource id (user account number)
   * @returns {Task} AccountDetails
   */
  get = async (id) => this._restService.get(null, `${RESOURCE}${ACTIONS.Get}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new AdminAccountDetailsResponseModel(result));

  /**
   * @method getSummary
   * @type {GET}
   * @description as an admin user, gets account summary by account number
   * 
   * @param {string} id resource id (user account number)
   * @returns {Task} AccountSummary
   */
   getSummary = async (id) => this._restService.get(null, `${RESOURCE}${ACTIONS.GetSummary}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new AdminAccountSummaryResponseModel(result));

  /**
   * @method update
   * @type {GET}
   * @description as an admin user, update user account basic details
   * 
   * @param {object} model UpdateBasicDetailsRequestModel request model
   * @param {string} id resource id (user account number)
   * @returns {Task} 
   */
  update = async (model, id) => { 
    delete this._restService.api.headers[RESPONSE_HEADERS.ContentType]; // is form, browser decides content type from model base type (FormData)
    return this._restService.update(new AdminUpdateBasicDetailsRequestModel(model), false, `${RESOURCE}${ACTIONS.Update}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new AdminUpdateBasicDetailsResponseModel(result))};

}

export default new AdminAccountServicesApiService();