import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PaymentList.module.css';
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import Arrow from '../../../../Foundation/Helpers/Components/Feature/Arrow/Arrow';
import { Link, Outlet, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { PAYMENT_STATUS_CLASSNAMES } from '../constants';
import Pill from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';
import { ReactComponent as Warning } from '../../../../Foundation/Theming/Icons/Status/warning.svg';
import { connect } from 'react-redux';
import PaymentEntityViewModel from '../PaymentEntityViewModel';

const PAYMENT_STATUS_CODES = {
  Overdue: 4
}

const PaymentList = ({ membership, className }) => {

  const routeParams = useParams();
  const referenceId = routeParams.referenceId;
  // const [payments, setPayments] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [failed, setFailed] = useState(false);
  const [paymentsListViewData, setPaymentsListViewData] = useState(null);

  const loadMessage = failed ? 'Error retrieving payment history' : 'Success';

  const payments = membership?.PaymentsSummary?.Payments;

  useEffect(() => {

    if (payments !== null) {
      setPaymentsListViewData(payments.map(m => new PaymentEntityViewModel(m)));
    }

  }, [payments]);

  useEffect(() => {

    // todo - maybe reload the list here if it's stale in state...

    // if (typeof membershipNumber === 'undefined') {
    //   return null;
    // }

    // setIsPendingData(true);

    // _paymentServicesApiService.getByMembershipNumber(membershipNumber).then((response) => {

    //   if (response.apiStatus === HTTP_STATUS_CODES.Success) {
    //     setRedemptions(response.Redemptions);
    //   } else {
    //     toast(<Notification label="Unable to retrieve payment history" mode="error" />)
    //     setFailed(true);
    //   }

    //   setIsPendingData(false);

    // });

  }, [membership]);

  const renderPayments = (payment, i) => {

    return <li className={`${styles.payment} ${payment.Status === 4 ? styles.overdue : ''}`} key={`${payment.ReferenceId}_${i}`}>
      <Link to={`${payment.ReferenceId}`}>
        <div className={`${styles.paymentEntity} ${payment.Status === 2 ? styles.cancelled : ''}`}>
          <div className={styles.taxonomyStatus}>
            {payment.Status === 4 && <div className={styles.overdueWarning}><Warning /></div>}
            <Pill label={payment.StatusName} className={`${PAYMENT_STATUS_CLASSNAMES[payment.Status]} ${styles.statusPill}`} />
          </div>
          <div className={styles.info}>
            <div className={styles.details}>
              <div className={styles.dueDate}>{parse(payment.DueDateDisplay)}</div>
              <div className={styles.referenceId}>{payment.ReferenceId}</div>
              <div className={styles.credits}>{payment.Credits}</div>
              <div className={styles.amount}>£{payment.Amount}</div>
            </div>
            <div className={styles.reason}>{payment.Reason}</div>
          </div>
          <div className={styles.arrow}>
            <Arrow direction='right' />
          </div>
        </div>
      </Link>
    </li>

  }

  return <div className={`${styles.panel} ${className}`}>
    <div className={styles.heading}>
      <h3>Payments</h3>
    </div>
    {typeof referenceId === 'undefined' ?
      (paymentsListViewData !== null ? (paymentsListViewData?.length > 0 ?
        <ul className={styles.payments}>
          {paymentsListViewData.map((m, i) => renderPayments(m, i))}
        </ul> :
        <div className={styles.noRedemptions}>
          No Payments
        </div>) :
        <CustomLoader label='Fetching payment history' pending={isPendingData} failed={failed} message={loadMessage} className={styles.loader} />
      )
      :
      <Outlet />
    }
  </div>

};

function mapStateToProps(state) {
  return {
    membership: state.membership
  }
}

export default connect(mapStateToProps)(PaymentList);

PaymentList.propTypes = {

}