import React from 'react';
import styles from './Crossmark.module.css';

const Crossmark = ({ className }) => {
  return <div className={`sys-crossmark ${className} ${styles.crossmark} ${styles.crossmarkDraw}`}></div>;
};

export default Crossmark;

Crossmark.propTypes = {
	
}