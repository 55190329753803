/**
 * @class JsonPatchDocument
 * @description implements a JsonPatchDocument
 */
export default class JsonPatchDocument {

    /**
     * @constructor
     * 
     * @param {string} op the patch operation to be applied
     * @param {string} path the property value to manipulate
     * @param {dynamic} value the new value, can be any type
     */
    constructor(op, path, value) {
        this.op = op;
        this.path = `/${path}`;
        this.value = value;
    }
}