import React from 'react';
import PropTypes from 'prop-types';
import styles from './PartnerSelectorResultItem.module.css';
import Button from '../../../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import Pill from '../../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';

const PartnerSelectorResultItem = ({ data, index, selectedId, onSelect }) => {

  return <div className={`panel-strong ${styles.item} ${selectedId === data.PartnerId ? styles.selected : ''}`}>
    <div className={styles.inner}>
      <div className={styles.summary}>
        <div className={styles.basic}>
          <div className={styles.name}>{data.Name}</div>
          <div className={styles.location}>
            <div className={`${styles.county} label-tag`}>
              {data.CountyName}
            </div>
            <div className={`${styles.country} label-tag`}>
              {data.CountryName}
            </div>
          </div>
        </div>
        <div className={styles.partnerId}>
          <Pill label={data.PartnerId} className={styles.partnerIdPill} />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.select}>
          <Button noArrow={true} skinny onClick={() => onSelect(data)}>Select</Button>
        </div>
      </div>
    </div>
  </div>;
};

export default PartnerSelectorResultItem;

PartnerSelectorResultItem.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
  selectedId: PropTypes.string,
  onSelect: PropTypes.func.isRequired
}