import SearchApiResponseBase from '../../../../../Base/Domain/SearchApiResponseBase';
import MembershipListItemModel from '../MembershipListItemModel';

class MembershipSearchResponseModel extends SearchApiResponseBase {

  constructor(params) {

    super(params, MembershipListItemModel);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

  }

}

export default MembershipSearchResponseModel;