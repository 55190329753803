import MembershipUserModel from "../MembershipUserModel";

class MembershipListItemModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.MembershipNumber = params.membershipNumber;
        this.TypeName = params.typeName;
        this.StartDate = params.startDate;
        this.ExpiryDate = params.expiryDate;
        this.Expired = params.expired;
        this.Activated = params.activated; 
        this.Locked = params.locked;
        this.User = new MembershipUserModel(params.user);

    }

}

export default MembershipListItemModel;