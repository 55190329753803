import ApiResponseBase from '../../../../../Base/Domain/ApiResponseBase';
import AccountDetailsModel from '../AccountDetailsModel';

/**
  * @implements a refresh response model
  *
  */
class UpdateBasicDetailsResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.AccountSummary = new AccountDetailsModel(params.accountSummary);

  }

}

export default UpdateBasicDetailsResponseModel;