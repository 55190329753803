/**
 * @class LocalStorageHelper 
 * @description basic CRUD operations for browser local storage
 */
export class LocalStorageHelper {

    /**
     * 
     * @param {string} key 
     * @param {object} value 
     * 
     * @returns {void}
     */
    set = (key, value) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.error("Unable to parse data for local storage");
        }
    }

    /**
     * 
     * @param {string} key 
     * 
     * @returns {object}
     */
    get = (key) => {

        try {
            return JSON.parse(window.localStorage.getItem(key));
        } catch (e) {
            console.error("Unable to parse data from local storage");
        }

    }

    /**
     * @returns {void}
     */
    delete = (key) => {
        window.localStorage.removeItem(key);
    }

    /**
     * @returns {void}
     */
    clear = () => {
        window.localStorage.clear();
    }

}