import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import PartnerSearchRequestModel from '../Models/PartnerList/Get/PartnerSearchRequestModel';
import PartnerSearchResponseModel from '../Models/PartnerList/Get/PartnerSearchResponseModel';

const API_ENDPOINT = process.env.REACT_APP_PARTNER_ACCOUNTSERVICES_API_HOST;

class PartnerSearchServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method search
   * @type {POST}
   * @description as an admin user, search partners by parameters
   * 
   * @param {object} PartnerSearchRequestModel
   * @returns {Task} AccountSummary
   */
   search = async (params) => this._restService.post(new PartnerSearchRequestModel(params), `${RESOURCE}${ACTIONS.Search}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new PartnerSearchResponseModel(result));

   // not implemented - TU-80
  //  /**
  //  * @method searchSummary
  //  * @type {POST}
  //  * @description as an admin user, search partners summary by parameters
  //  * 
  //  * @param {object} params resource id (user account number)
  //  * @returns {Task} AccountSummary
  //  */
  //   searchSummary = async (params) => this._restService.post(new PartnerSearchRequestModel(params), `${RESOURCE}${ACTIONS.Search}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new PartnerSearchResponseModel(result));

}

export default new PartnerSearchServicesApiService();