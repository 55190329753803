import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { NullUndefinedEmpty } from '../../../../Utility/MiscHelpers';
import { FIELD_TYPES, FormFieldResolver } from './Utility/_formsConfig';
import Button from '../Feature/Common/Buttons/Button/Button';
import ExpandToggle from '../Feature/Common/MicroUi/ExpandToggle/ExpandToggle';
import { Fade } from 'react-reveal';

const DISPLAY_FIELD_ALIAS = 'Display';

// components needing manual value setting on reset (or load)
const CONTROLLED_COMPONENTS = [
  FIELD_TYPES.Calendar
]

const Form = ({ className, model, submissionResponseData = null, onWatchEvent, watchFields = [], editable = false, remoteEditToggle, remoteSubmit, remoteCancelEdit, remoteStateSetter, hideControls = false, isDisabled = false }) => {

  const [isEditMode, setIsEditMode] = useState(!editable);
  const [sectionsDisplayState, setSectionsDisplayState] = useState({});
  const { register, control, handleSubmit, watch, reset, setValue, formState: { errors, touchedFields, isSubmitted } } = useForm({ reValidateMode: 'onBlur' });
  const rooster = watch([...watchFields]);

  useEffect(() => {
    if (typeof onWatchEvent === 'function') {
      const subscription = watch((value, { name, type }) => {
        onWatchEvent({ value, type });
      });
      return () => subscription.unsubscribe();
    }
  }, [watch]);

  const submitAction = (data) => {

    if (typeof model?.onSubmit === 'function') {
      model?.onSubmit(data);
    }

    if (editable) {
      setIsEditMode(false);
    }

    return false;
  }

  const cancelEdit = () => {
    setIsEditMode(false);
    reset();
  }

  const editToggle = () => {
    setIsEditMode(!isEditMode);
  }

  const toggleSection = (id) => {

    let sectionsDisplayStateData = { ...sectionsDisplayState };

    sectionsDisplayStateData[id] = sectionsDisplayStateData[id] || {};
    sectionsDisplayStateData[id].open = !sectionsDisplayStateData[id].open;

    setSectionsDisplayState(sectionsDisplayStateData);

  }

  // #region effect methods
  useEffect(() => {

    if (submissionResponseData !== null) {
      if (editable) {
        setIsEditMode(true);
      }
    }

  }, [submissionResponseData]);

  useEffect(() => {
    reset();

    if (typeof model === 'undefined') {
      return;
    }

    const fieldCollection = Object.entries(model.sections).map((a) => a[1]?.fields)[0];

    // controlled components
    for (let field of fieldCollection) {
      if (typeof field.defaultValue !== 'undefined' && field.defaultValue !== null) {
        if (CONTROLLED_COMPONENTS.includes(field.type)) {
          setValue(field.name, field.defaultValue);
        }
      }
    }

  }, [model]);

  useEffect(() => {
    if (typeof remoteStateSetter === 'function') {
      remoteStateSetter(isEditMode);
    }
  }, [isEditMode]);

  useEffect(() => {

    if (typeof remoteEditToggle === 'object') {
      remoteEditToggle.current = editToggle;
    }

    if (typeof remoteSubmit === 'object') {
      remoteSubmit.current = handleSubmit(submitAction);
    }

    if (typeof remoteCancelEdit === 'object') {
      remoteCancelEdit.current = cancelEdit;
    }

  }, []);
  //#endregion

  if (typeof model === 'undefined' || model === null) {
    return null;
  }

  //#region render methods
  const renderSubmissionResponseData = () => {
    return <div className='system-form__server-response-data'>
      <div className='system-form__server-response-data-list'>
        {Object.keys(submissionResponseData).map((e, i) => submissionResponseData[e].map((o, idx) => <div className='system-form__server-response-data-list-item' key={`${e}_${i}_${idx}`}>{o}</div>))}
      </div>
    </div>
  }

  const render = (Component, model, i) => {

    if (typeof Component === 'undefined') {
      return null;
    }

    return <Component key={`field_${model.name}_${i}`} incrementor={i} {...model} setValue={setValue} control={control} register={register} errors={errors[model.name]} alive={(touchedFields[model.name] && isSubmitted) || isSubmitted && errors} watch={watch} />;
  }

  const renderFields = (fields) => {
    return !NullUndefinedEmpty(fields) &&
      <div className="system-form__fields">
        {fields.map((field, i) => render(FormFieldResolver[(isEditMode && field.editable !== false) ? field.type : DISPLAY_FIELD_ALIAS], field, i))}
      </div>
  }

  const renderSections = () => {

    if (NullUndefinedEmpty(model.sections)) {
      console.info("Form sections not provided");
      return null;
    }

    return Object.keys(model.sections).map((s, i) => {

      let section = model.sections[s];

      const collapsable = section.collapsable;
      const open = sectionsDisplayState[s]?.open || false;

      return <div className={`system-form__form-section ${collapsable && !open && 'system-form__collapsed'} ${NullUndefinedEmpty(section.label) ? 'system-form__form-section--no-label' : ''}`} key={`${s}_${i}`}>
        <div className={'system-form__section-header'}>
          {section.label &&
            <div className='system-form__form-section-label'>{section.label}</div>}
          {collapsable === true && <div className={'system-form__form-section__collapse-control'}>
            <ExpandToggle defaultOpen={open} inLabel="Expand" outLabel="Collapse" toggleFunction={() => toggleSection(s)} /></div>}
        </div>
        {collapsable ? <Fade duration={400} spy={open}>
          {renderFields(section.fields)}
        </Fade> : renderFields(section.fields)}
      </div>

    });

  }

  //#endregion

  return <div className={`${className} system-form`}>
    {model.title && <div className="system-form__title">{model.title}</div>}
    <form onSubmit={handleSubmit(submitAction)}>
      {submissionResponseData !== null && renderSubmissionResponseData()}
      <fieldset disabled={isDisabled}>
        {renderSections()}
        {!hideControls &&
          <div className="system-form__actions">
            {!isEditMode ? <Button type="none" skinny className='system-form__actions__edit-cancel' secondary onClick={editToggle}>Edit</Button> :
              <>
                {editable && <Button type="none" skinny className='system-form__actions__edit-cancel' tertiary onClick={() => cancelEdit()}>Cancel</Button>}
                {model.submit && <Button primary skinny>{model.submit?.name}</Button>}
              </>}
          </div>
        }
        {model.footnotes && <div className="system-form__footnotes">{model.footnotes}</div>}
      </fieldset>
    </form>
  </div>;

};

export default Form;

Form.propTypes = {
  editable: PropTypes.bool,
  remoteEditToggle: PropTypes.object,
  remoteSubmit: PropTypes.object,
  remoteCancelEdit: PropTypes.object,
  remoteStateSetter: PropTypes.func,
  hideControls: PropTypes.bool
}