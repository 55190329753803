class UserListItemModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        if(typeof params.createdAt !== 'undefined'){
            this.CreatedAt = new Date(params.createdAt)?.toDateString()?.replace(/^\S+\s/,'');
        }
        
        this.AccountNumber = params.accountNumber;
        this.FirstName = params.firstName;
        this.LastName = params.lastName;
        this.Email = params.email;
        this.Telephone = params.telephone;
        this.CountyName = params.countyName;
        this.CountryName = params.countryName;
        this.ProfileImageUri = params.profileImageUri;

        if (typeof params.roles !== 'undefined' && params.roles.length !== 0) {
            this.DisplayRole = params.roles?.reduce((a, b) => a.id < b.id ? a : b).name;
        }

    }

}

export default UserListItemModel;