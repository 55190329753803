import { combineReducers } from "redux";
import authentication from './Reducers/Authentication/authenticationReducer';
import global from './Reducers/Global/globalReducer';
import user from './Reducers/User/userReducer';
import membership from './Reducers/Membership/membershipReducer';
import requests from './Reducers/Requests/requestsReducer';
import utility from './Reducers/Utility/utilityReducer';

export default combineReducers({ 
    authentication,
    user,
    membership,
    utility,
    global,
    requests
 });