import ApiResponseBase from '../../../../../Base/Domain/ApiResponseBase';
import MembershipModel from '../../MembershipModel';

class GetMembershipsResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.Memberships = params.memberships?.map(m => new MembershipModel(m));

  }

}

export default GetMembershipsResponseModel;