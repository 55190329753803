import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Memberships.module.css';
import { Outlet, useParams } from 'react-router-dom';
import MembershipListPanel from '../../Memberships/Shared/MembershipListPanel/MembershipListPanel';
import LinkButton from '../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';

const Memberships = () => {

  const routeParams = useParams();
  const accountNumber = routeParams.id;

  return <div className={`${styles.memberships} layout__sub-section`}>
    <div className={`layout__side-panel`}>
      <MembershipListPanel accountNumber={accountNumber} />
    </div>
    <div className={`layout__editor-panel ${styles.editor}`}>
      {routeParams.membershipNumber && <div className={styles.detailLink}>
        <LinkButton url={`/memberships/${routeParams.membershipNumber}`} mode={"small"} label={"View in Membership Manager"} />
      </div>}
      <Outlet />
    </div>
  </div>

};

export default Memberships;

Memberships.propTypes = {

}
