import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Payment.module.css';
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import _paymentServicesApiService from '../../../../Application/ApiServices/Payment/PaymentServices/Services/PaymentServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../Foundation/Rest/Constants';
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { toast } from 'react-toastify';
import Notification from '../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import LinkButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import PaymentEntityViewModel from '../PaymentEntityViewModel';
import { ReactComponent as RedemptionIcon } from '../../../../Foundation/Theming/Icons/Navigation/payment.svg';
import Pill from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';
import { PAYMENT_STATUS_CLASSNAMES } from '../constants';
import ConfirmButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/ConfirmButton/ConfirmButton';
import data from '../paymentData.json';
import { useDispatch } from 'react-redux';
import { getMembershipPaymentData } from '../../../../Foundation/Store/Actions/Membership/membershipActions';
import { DATA_API_PROPERTIES_KEY } from '../constants';

const FAILED_MESSAGE = "Unable to retrieve payment details",
  FIELD_LIST = ["Amount", "DueDate", "Reason", "Credits"];

const Payment = () => {

  const [paymentDetailsApiResponse, setPaymentDetailsApiResponse] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [first, setFirst] = useState(true);
  const [failed, setFailed] = useState(false);
  const dispatch = useDispatch();
  const loadMessage = failed ? FAILED_MESSAGE : 'Success';
  const routeParams = useParams();
  const referenceId = routeParams.referenceId;
  const membershipNumber = routeParams.membershipNumber;

  const refreshPaymentStateData = () => {
    dispatch(getMembershipPaymentData(membershipNumber)).then((response) => {

    });
  }

  const setEditFormFieldDefaults = () => {

    const p = paymentDetailsApiResponse[DATA_API_PROPERTIES_KEY];

    if (p.CancelledDate !== null) {
      return;
    }

    const formData = data.editDialog.form?.sections?.Payment.fields;

    if (typeof formData !== 'undefined') {

      for (let field of FIELD_LIST) {

        let x = formData.find(x => x.name === field);
        if (typeof x !== 'undefined') {
          x.defaultValue = paymentDetailsApiResponse[DATA_API_PROPERTIES_KEY][field];
        }
      }

    }

  }

  const action = (successMessage, errorMessage, method) => {

    if (typeof referenceId === 'undefined' || referenceId === null) {
      return null;
    }

    setIsPendingData(true);

    method(referenceId).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        response[DATA_API_PROPERTIES_KEY] = new PaymentEntityViewModel(response[DATA_API_PROPERTIES_KEY]);
        setPaymentDetailsApiResponse(response);
        if (successMessage) {
          toast(<Notification label={successMessage} mode="success" />)
        }
      } else {
        if (errorMessage) {
          toast(<Notification label={errorMessage} mode="error" />)
        }
        setFailed(true);
      }

      setIsPendingData(false);

    });

  }

  // Cancel
  const onCancelReject = () => {
    toast(<Notification label={"Payment not cancelled"} mode="information" />)
  }

  const onCancelConfirm = () => action("Payment cancelled", "Unable to cancel payment", _paymentServicesApiService.cancelByReferenceId);

  // Refund
  const onRefundReject = () => {
    toast(<Notification label={"Payment not refunded"} mode="information" />)
  }

  const onRefundConfirm = () => action("Payment refunded", "Unable to refund payment", _paymentServicesApiService.refundByReferenceId);

  // Settle
  const onSettleReject = () => {
    toast(<Notification label={"Payment not marked as settled"} mode="information" />)
  }

  const onSettleConfirm = () => action("Payment marked as settled", "Unable to mark payment as settled", _paymentServicesApiService.settleByReferenceId);

  // Edit
  const onEditReject = () => {
    toast(<Notification label={"Update payment information cancelled"} mode="information" />)
  }

  const onEditConfirm = (data) => action("Payment updated", "Unable to update payment", () => _paymentServicesApiService.update({ ...data, ReferenceId: referenceId }));

  useEffect(() => {

    action(null, FAILED_MESSAGE, _paymentServicesApiService.getByReferenceId);

  }, [referenceId]);

  useEffect(() => {

    if (!first) {
      refreshPaymentStateData();
    }

    if (paymentDetailsApiResponse !== null && first) {
      setFirst(false);
    }

    if (paymentDetailsApiResponse !== null) {
      setEditFormFieldDefaults();
    }

  }, [paymentDetailsApiResponse]);


  const paymentData = paymentDetailsApiResponse !== null && paymentDetailsApiResponse[DATA_API_PROPERTIES_KEY] || null;

  return <div className={styles.paymentContainer}>
    <div className={"cf"}>
      <LinkButton url=".." label={"Back to list"} arrowDirection="left" className={styles.backLink} mode="small" />
    </div>
    <div className={`${styles.payment}`}>
      {paymentData !== null ?
        <div className={`${styles.paymentSummary}`}>
          <div className={`${styles.headline}`}>
            <div className={styles.leading}>
              <div className={styles.taxonomyIcon}>
                <RedemptionIcon />
              </div>
              <div className={styles.label}><span className={styles.referenceIdValue}>Ref: {paymentData.ReferenceId}</span>
              </div>
            </div>
            <Pill label={paymentData.StatusName} className={`${PAYMENT_STATUS_CLASSNAMES[paymentData.Status]} ${styles.statusPill}`} />
          </div>
          <div className={styles.values}>
            <div className={`${styles.amount} ${styles.field}`}>
              <div className={styles.label}>Amount</div>
              <div className={`${styles.value} ${PAYMENT_STATUS_CLASSNAMES[paymentData.Status]}`}>{paymentData.AmountDisplay}</div>
              {paymentData.Credits && <div className={`${styles.credits} ${styles.field}`}>
                <div className={styles.label}>Credits</div>
                <div className={`${styles.value} detail-label`}>{paymentData.Credits}</div>
              </div>}
            </div>
            <div className={`${styles.dates} ${styles.field}`}>
              <div className={styles.label}>Due</div>
              <div className={`${styles.dateField} ${styles.statusDates} ${styles.field}`}>
                <div className={`${styles.value} detail-label`}>{parse(paymentData.DueDateDisplay)}</div>
                <div className={styles.secondaryFields}>
                  {paymentData.SettledDate && <div className={styles.secondaryField}><div className={styles.subLabel}>Settled on</div>
                    <div className={`${styles.value} detail-label`}>{parse(paymentData.SettledDate)}</div></div>}
                  {paymentData.RefundedDate && <div className={styles.secondaryField}>
                    <div className={styles.subLabel}>Refunded on</div>
                    <div className={`${styles.value} detail-label`}>{parse(paymentData.RefundedDate)}</div></div>}
                  {paymentData.CancelledDate && <div className={styles.secondaryField}>
                    <div className={styles.subLabel}>Cancelled on</div>
                    <div className={`${styles.value} detail-label`}>{parse(paymentData.CancelledDate)}</div></div>}
                </div>
              </div>
            </div>
            <div className={`${styles.reason} ${styles.field}`}>
              <div className={styles.label}>Reason</div>
              <div className={`${styles.value}`}>{paymentData.Reason}</div>
            </div>
          </div>
          <div className={styles.actions}>
            {!paymentData.Settled && paymentData.CancelledDate === null && paymentData.RefundedDate === null &&
              <ConfirmButton noArrow danger onReject={onCancelReject} onConfirm={onCancelConfirm} dialogData={data.cancelDialog}>Cancel</ConfirmButton>}
            {paymentData.Settled && paymentData.CancelledDate === null && paymentData.RefundedDate === null &&
              <ConfirmButton noArrow danger onReject={onRefundReject} onConfirm={onRefundConfirm} dialogData={data.refundDialog}>Refund</ConfirmButton>}
            {!paymentData.CancelledDate && <ConfirmButton noArrow secondary onReject={onEditReject} onConfirm={onEditConfirm} dialogData={data.editDialog} dialogClassName={styles.editDialog}>Edit</ConfirmButton>}
            {!paymentData.Settled && paymentData.CancelledDate === null && paymentData.RefundedDate === null &&
              <ConfirmButton noArrow primary onReject={onSettleReject} onConfirm={onSettleConfirm} dialogData={data.settleDialog}>Settle</ConfirmButton>}
          </div>
        </div>
        :
        <CustomLoader label='Fetching payment details' pending={isPendingData} failed={failed} message={loadMessage} className={styles.loader} />}
    </div>
  </div>

};

export default Payment;

Payment.propTypes = {

} 
