import { UPDATE_REQUESTS_STATE } from '../../Actions/Requests/actionTypes';
import initialState from '../../initialState';

const requestsReducer = (state = initialState.requests, action) => {
    switch (action.type) {
        case UPDATE_REQUESTS_STATE:
            return { ...state, ...action.requestsState };
        default:
            return state;
    }
};

export default requestsReducer;