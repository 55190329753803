import UserModel from "./UserModel";

class MembershipModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.MembershipNumber = params.membershipNumber;
        this.AccountNumber = params.accountNumber;
        this.Credits = params.credits;
        this.CreditsRemaining = params.creditsRemaining;
        this.Expired = params.expired;
        this.Activated = params.activated; 
        this.Locked = params.locked;
        this.ExpiryDate = params.expiryDate;
        this.User = new UserModel(params.user);
    }

}

export default MembershipModel;

