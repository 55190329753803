class MembershipCreditModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.MembershipNumber = params.membershipNumber;
        this.Value = params.value;
        this.Credit = params.credit;
        this.Reason = params.reason;
        this.ActualValue = this.Credit ? params.value : -params.value;
    }

}

export default MembershipCreditModel;
