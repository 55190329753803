class UserSearchRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        if (params.SearchTerm !== '') {
            this.SearchTerm = params.SearchTerm;
        }

        if (typeof params.CountyIds !== 'undefined' && params.CountyIds.length !== 0) {
            this.CountyIds = params.CountyIds?.map((o) => o.value) || null;
        }

        if (typeof params.RoleIds !== 'undefined' && params.RoleIds !== false && params.RoleIds.length !== 0) {
            this.RoleIds = params.RoleIds?.map(r => +r) || null;
        }

    }

}

export default UserSearchRequestModel;