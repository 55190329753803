import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldBase from '../Base/FieldBase';
import { CLASS_MODIFIERS } from '../../Utility/_formsConfig';

const ToggleButton = ({ name, register, label, defaultValue, alive, options, errors, incrementor, validation = {} }) => {

    const [value, setValue] = useState(typeof defaultValue !== 'undefined' ? defaultValue : null);

    const onValueChange = (val) => {
        setValue(val.target.value);
        return val.target.value;
    }

    const renderToggleButton = (radio, i) => {

        const checked = value == radio.value;

        return <div className="toggle" key={`${radio.label}_${i}`}>
            <label>
                <div className="toggle__control-container">
                    <div className={`toggle__control ${checked ? 'checked' : ''}`} tabIndex="0" role="radio" aria-checked={checked} >
                        <input {...register(name, validation)} type="radio" onChange={onValueChange} checked={checked} value={radio.value} />
                        <span className="toggle__label">
                    {radio.label}
                </span>
                    </div>
                </div>
            </label>
        </div>
    }

    return <FieldBase {...{ errors, alive, ['classModifier']: CLASS_MODIFIERS.ToggleButton, label, incrementor }}>
        <div className="form-field--toggle-button-list__list" role="radiogroup">
            {options?.map((radio, i) => renderToggleButton(radio, i))}
        </div>
    </FieldBase>
};

export default ToggleButton;

ToggleButton.propTypes = {
    incrementor: PropTypes.number.isRequired
}