import { toDateTimeHtmlString } from '../../../../../Utility/DateHelpers';

class BookingRequestListingResultViewModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        this.ReferenceId = params.ReferenceId;
        this.PartnerId = params.PartnerId;
        this.PartnerName = params.PartnerName;
        this.MembershipNumber = params.MembershipNumber;
        this.NumberOfGuests = params.NumberOfGuests;
        this.SlotDateTime = toDateTimeHtmlString(params.SlotDateTime);
        this.BookingRequestDateTime = toDateTimeHtmlString(params.BookingRequestDateTime);
        this.User = params.User;
        this.Cancelled = params.Cancelled;
        this.Confirmed = params.Confirmed;
        
    }

}

export default BookingRequestListingResultViewModel;