import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldBase from '../Base/FieldBase';
import { CLASS_MODIFIERS } from '../../Utility/_formsConfig';
import styles from './ImageUpload.module.css';

const ImageUpload = ({ name, removePropertyName, register, setValue, variant, label, readOnly, placeholder, defaultValue, maxLength, errors, events, alive, incrementor, disabled, validation = {}, maxFiles = 1 }) => {

  const [previewFiles, setPreviewFiles] = useState(defaultValue);
  const [removeInputValue, setRemoveInputValue] = useState([]);

  const allLoaded = (loadedFiles) => {
    setPreviewFiles([...loadedFiles, ...previewFiles])
  }

  function readUrl(input) {

    const fileCount = input.files.length;
    let loadCount = 0,
      loadedFiles = [];

    if (fileCount !== 0) {
      for (let file of input.files) {

        let reader = new FileReader();

        reader.onload = function (e) {
          loadCount++;

          loadedFiles.push(e.target.result)

          if (loadCount === fileCount) {
            allLoaded(loadedFiles);
          }

        }

        reader.readAsDataURL(file); // convert to base64 string

      }

    }
  }

  const onChange = (element) => {
    readUrl(element.target);
  }

  const removeFile = (e, fileUri, idx) => {
    e.preventDefault()
    let cl = [...previewFiles];
    cl.splice(idx, 1);
    setPreviewFiles(cl)
    setRemoveInputValue([fileUri, ...removeInputValue]);
  }

  useEffect(() => {
    setValue(removePropertyName, removeInputValue);
  }, [removeInputValue]);

  return <FieldBase noLabel className={typeof previewFiles === 'undefined' ? 'form-field--empty' : ''} {...{ errors, alive, ['classModifier']: CLASS_MODIFIERS.ImageUpload, label, incrementor }}>
    <input type="hidden"
      className={`${styles.removeValueInput}`}
      {...register(removePropertyName)}
    />
    {(typeof previewFiles !== 'undefined' && previewFiles !== null && previewFiles.length !== 0) &&
      <div className="form-field--image-upload__preview">
        {previewFiles?.map((f, i) =>
          <div className={`${styles.previewFile} form-field--image-upload__preview-file`} key={`${f}_${i}`}>
            <img src={f} />
            <div className={styles.remove} onClick={(e) => removeFile(e, f, i)}>Remove</div>
          </div>)}
      </div>
    }

    <div className="form-field--image-upload__edit"><label>
      <input type="file"
        multiple
        accept="image/png, image/jpeg" // todo 
        className={`${styles.input} image-upload-field`}
        {...register(name, validation)}
        disabled={disabled}
        onChange={onChange} />

      {previewFiles?.length < maxFiles &&
        <div className="form-field--image-upload__preview-placeholder"><span className='cross'></span></div>
      }
    </label>
    </div>

  </FieldBase>
};

export default ImageUpload;

ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  removePropertyName: PropTypes.string.isRequired,
  defaultValue: PropTypes.array
}