import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import styles from './MembershipManager.module.css';
import FilterListing from '../../FilterListing/FilterListing';
import _userSearchServicesApiService from '../../../Application/ApiServices/User/Search/Services/UserSearchServicesApiService';
import _membershipSearchServicesApiService from '../../../Application/ApiServices/Membership/MembershipSearchServices/Services/MembershipSearchServicesApiService';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import filterData from './MembershipManagerListingFilterData.json';
import { getCounties } from '../../../Foundation/Store/Actions/Utility/utilityActions';
import { mapNameIdPairToSelectOptions } from '../../../Foundation/Helpers/Components/Forms/Utility/formHelpers';
import { toast } from 'react-toastify';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import MembershipListingResultViewModel from './ListingResult/MembershipListingResultViewModel';

const COUNTY_ID_KEY = 'CountyIds';

const MembershipManager = ({ counties }) => {

  const [results, setResults] = useState(null);
  const [data, setData] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [isPendingFilterData, setIsPendingFilterData] = useState(false);
  const dispatch = useDispatch();

  const mapResults = (d) => {
    return { ...d, Items: d?.Items.map(a => new MembershipListingResultViewModel(a)) };
  }

  const filterSubmit = (data) => {

    setIsPendingData(true);

    let dto = { ...data };

    _membershipSearchServicesApiService.search(dto).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {

        let mappedResults = mapResults(response.Results);
        setResults(mappedResults);
      } else {
        toast(<Notification label="Unable to complete search" mode="error" />)
      }

      setIsPendingData(false);

    });
  }

  const buildFormViewModel = () => {

    let result = { formViewModel: filterData }

    // counties
    for (let section in result.formViewModel.sections) {

      for (let field of result.formViewModel.sections[section].fields) {

        // if (field.name === COUNTY_ID_KEY) {
        //   let countyList = mapNameIdPairToSelectOptions(counties);
        //   field.options = countyList;
        //   // field.displayValue = counties.find(x => x.value === entity)?.label; // todo, from localStorage once implemented
        // }

      }
    }

    return result;
  }

  // useEffect(() => {

  //   if (counties === null) {
  //     setIsPendingFilterData(true);
  //     dispatch(getCounties()).then(() => {
  //       setIsPendingFilterData(false);
  //     })
  //   }

  //   if (counties !== null) {
  //     setData(buildFormViewModel());
  //   }

  // }, [counties]);

  useEffect(() => {

    // first load, get users
    filterSubmit(null); // todo, from pre-stored parameters or default

  }, []);

  filterData.onSubmit = filterSubmit;

  return <div className={`${styles.membershipManager}`}>
    <FilterListing type="membership" data={results} filters={filterData} isPendingData={isPendingData} isPendingFilterData={isPendingFilterData} />
  </div>

};

function mapStateToProps(state) {
  return {
    // counties: state.utility.counties
  }
}

export default connect(mapStateToProps)(MembershipManager);

MembershipManager.propTypes = {

}
