class UpdatePaymentRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return;
        }

        this.ReferenceId = params.ReferenceId;
        this.Amount = params.Amount;
        this.DueDate = params.DueDate;
        this.Reason = params.Reason;
        this.Credits = params.Credits;

    }

}

export default UpdatePaymentRequestModel;