import { GET_USER_ACCOUNT_DATA_ERROR, GET_USER_ACCOUNT_DATA_SUCCESS, UPDATE_USER_ACCOUNT_DATA_SUCCESS, UPDATE_USER_ACCOUNT_DATA_ERROR } from './actionTypes';
import { setAuthenticationTokenInService } from '../actionsHelper';
import _userAccountServicesApiService from '../../../../Application/ApiServices/User/AccountServices/Services/AccountServicesApiService';

const API_RESPONSE_CODES = {
    GetUserAccountDataSuccessful: 200,
    UpdateUserAccountDataSuccessful: 200
};

/* get user account data */
export const getUserAccountDataSuccess = (user) => ({ type: GET_USER_ACCOUNT_DATA_SUCCESS, user })
export const getUserAccountDataError = (user) => ({ type: GET_USER_ACCOUNT_DATA_ERROR, user })

export function getUserAccountData(data) {

    return (dispatch, getState) => {

        if (!setAuthenticationTokenInService(_userAccountServicesApiService, getState()?.authentication)) {
            console.error("Authentication required for API method userAccountServicesApi.get()");
        }

        return _userAccountServicesApiService.get().then(response => {
            if (response?.apiStatus === API_RESPONSE_CODES.GetUserAccountDataSuccessful) {
                let result = dispatch(getUserAccountDataSuccess(response));
                if (result?.user?.apiStatus === API_RESPONSE_CODES.GetUserAccountDataSuccessful) {
                    return result.user;
                }
            }
            return dispatch(getUserAccountDataError(response))?.user;
        });

    };
}

/* update user account data */
export const updateUserAccountDataSuccess = (user) => ({ type: UPDATE_USER_ACCOUNT_DATA_SUCCESS, user })
export const updateUserAccountDataError = (user) => ({ type: UPDATE_USER_ACCOUNT_DATA_ERROR, user })

export function updateUserAccountData(data) {

    return (dispatch, getState) => {

        if (!setAuthenticationTokenInService(_userAccountServicesApiService, getState()?.authentication)) {
            console.error("Authentication required for API method userAccountServicesApi.update()");
        }

        return _userAccountServicesApiService.update(data).then(response => {
            if (response?.apiStatus === API_RESPONSE_CODES.UpdateUserAccountDataSuccessful) {
                let result = dispatch(updateUserAccountDataSuccess(response));
                if (result?.user?.apiStatus === API_RESPONSE_CODES.UpdateUserAccountDataSuccessful) {
                    return result.user;
                }
            }
            return dispatch(updateUserAccountDataError(response))?.user;
        });

    };
}
