import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import CreateMembershipResponseModel from '../Models/Create/CreateMembershipResponseModel';
import CreateMembershipRequestModel from '../Models/Create/CreateMembershipRequestModel';
import GetMembershipResponseModel from '../Models/Get/GetMembershipResponseModel';

const API_ENDPOINT = process.env.REACT_APP_MEMBERSHIP_ACCOUNTSERVICES_API_HOST;

class MembershipAccountServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method create
   * @type {POST}
   * @description as an admin user, create a new membership
   * 
   * @param {object} CreateMembershipRequestModel
   * @returns {Task} 
   */
  create = async (model) => {
    return this._restService.post(new CreateMembershipRequestModel(model), `${RESOURCE}${ACTIONS.Create}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new CreateMembershipResponseModel(result))
  };

  /**
   * @method activate
   * @type {PUT}
   * @description as an admin user, activate a new membership
   * 
   * @param {string} membershipNumber
   * @returns {Task} 
   */
  activate = async (membershipNumber) => {
    return this._restService.update({}, false, `${RESOURCE}${ACTIONS.Activate}/${membershipNumber}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipResponseModel(result))
  };

  /**
   * @method lock
   * @type {PUT}
   * @description as an admin user, lock a membership
   * 
   * @param {string} membershipNumber
   * @returns {Task} 
   */
  lock = async (membershipNumber) => {
    return this._restService.update({}, false, `${RESOURCE}${ACTIONS.Lock}/${membershipNumber}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipResponseModel(result))
  };

  /**
   * @method unlock
   * @type {PUT}
   * @description as an admin user, unlock a membership
   * 
   * @param {string} membershipNumber
   * @returns {Task} 
   */
  unlock = async (membershipNumber) => {
    return this._restService.update({}, false, `${RESOURCE}${ACTIONS.Unlock}/${membershipNumber}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipResponseModel(result))
  };

}

export default new MembershipAccountServicesApiService();