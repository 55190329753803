import React from 'react';
import PropTypes from 'prop-types';
import styles from './Splash.module.css';
import Fade from 'react-reveal/Fade';
import { ReactComponent as MastLogo } from '../../../../Theming/Icons/Brand/mast_white.svg';

const Splash = ({ children, className = '' }) => {

  return <div className={`${styles.splash} ${className}`}>
    <div className={styles.splashInner}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <MastLogo />
        </div>
        <div className={styles.titles}>
          <div className={styles.title}>
            Tee Up
          </div>
          <div className={styles.subTitle}>
            Admin Portal
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {children}
      </div>
      <div className={styles.loading}>
        <div className={styles.loadAnim}><div></div><div></div></div>
        <div className={styles.loadingLabel}>
          Loading
        </div>
      </div>
    </div>
  </div>
};

export default Splash;

Splash.propTypes = {

}