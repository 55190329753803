import Dashboard from "../Dashboard/Dashboard";
import Users from "../Users/Users";
import Partners from "../Partners/Partners";
import Memberships from "../Memberships/Memberships";
import Redemptions from "../Redemptions/Redemptions";
import System from "../System/System";

const _ScreenResolver = {
    Dashboard: <Dashboard />,
    Users: <Users />,
    Partners: <Partners />,
    Memberships: <Memberships />,
    Redemptions: <Redemptions />,
    System: <System />,
}

export default _ScreenResolver;