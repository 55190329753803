import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './MembershipListPanel.module.css';
import Button from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import _membershipsMemberServicesApiService from '../../../../Application/ApiServices/Membership/MembershipMemberServices/Services/MembershipMemberServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../Foundation/Rest/Constants';
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import Crossmark from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Crossmark/Crossmark';
import Checkmark from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Checkmark/Checkmark';
import Arrow from '../../../../Foundation/Helpers/Components/Feature/Arrow/Arrow';
import { Link, NavLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Notification from '../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import LinkButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import { ReactComponent as MembershipsIcon } from '../../../../Foundation/Theming/Icons/Navigation/memberships.svg';

const MembershipListPanel = ({ accountNumber, className }) => {

  const routeParams = useParams();
  const membershipNumber = routeParams.membershipNumber;
  const [memberships, setMemberships] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [failed, setFailed] = useState(false);

  const loadMessage = failed ? 'Error retrieving memberships' : 'Success';

  useEffect(() => {

    if (typeof accountNumber === 'undefined') {
      return null;
    }

    setIsPendingData(true);

    _membershipsMemberServicesApiService.get(accountNumber).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        setMemberships(response.Memberships);
      } else {
        toast(<Notification label="Unable to retrieve membership details" mode="error" />)
        setFailed(true);
      }

      setIsPendingData(false);

    });

  }, [accountNumber]);

  const renderMembership = (membership, i) => {

    const active = membership.MembershipNumber === membershipNumber;

    return <li className={`${styles.membership} ${active && styles.active} `} key={`${membership.MembershipNumber}_${i}`}>
      <Link to={`${membership.MembershipNumber}`}>
        <div className={styles.membershipEntity}>
          <div className={styles.status}>
            {membership.Expired ? <Crossmark className={styles.crossmark} /> : <Checkmark />}
          </div>
          <div className={styles.membershipNumber}>
          <div className={styles.taxonomyIcon}>
            <MembershipsIcon />
          </div>
            <div className={styles.number}>
          {membership.MembershipNumber}
          </div>
          </div>
          <div className={styles.arrow}>
            {!active && <Arrow direction='right' />}
          </div>
        </div>
      </Link>
    </li>

  }

  return <div className={`${styles.panel} ${className}`}>
    <div className={styles.heading}>
      <h3>Memberships</h3>
    </div>

    {memberships !== null ? (memberships.length > 0 ?
      <ul className={styles.memberships}>
        {memberships.map((m, i) => renderMembership(m, i))}
      </ul> :
      <div className={styles.noMemberships}>
        No Memberships
      </div>) :
      <CustomLoader label='Fetching memberships' pending={isPendingData} failed={failed} message={loadMessage} className={styles.loader} />
    }
    <div className={styles.new}>
        <LinkButton url={`/memberships/create?accountNumber=${accountNumber}`} label={`New Membership`} solid secondary />
      </div>
  </div>

};

export default MembershipListPanel;

MembershipListPanel.propTypes = {
  accountNumber: PropTypes.string.isRequired
}
