import ApiResponseBase from '../../../../../Base/Domain/ApiResponseBase';
import MembershipWithDetailModel from '../../MembershipWithDetailModel';

class GetMembershipWithDetailResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.Membership = new MembershipWithDetailModel(params.membership);

  }

}

export default GetMembershipWithDetailResponseModel;