import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Overview.module.css';
import { Outlet, useParams } from 'react-router-dom';

const Overview = () => {

  const routeParams = useParams();
  const accountNumber = routeParams.id;

  return <div className={`${styles.memberships} layout__sub-section`}>
    <div className={`layout__side-panel`}>
      Aside
    </div>
    <div className={`layout__editor-panel`}>
      <Outlet />
    </div>
  </div>

};

export default Overview;

Overview.propTypes = {

}
