/**
 * @class ApiResponseBase base response model from rest API (not applicable to CRUD services)
 */

import { HTTP_STATUS_CODES } from "../../../_Domain/Api/Constants";

export default class ApiResponseBase {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return null;
        }

        // custom api response base error
        if (typeof params.apiStatus !== 'undefined') {
            if (params.apiStatus === HTTP_STATUS_CODES.Forbidden) {
                this.apiStatus = HTTP_STATUS_CODES.Forbidden;
                this.apiMessage = "You are not authorised to access this resource";
            } else {
                this.apiStatus = params.apiStatus;
                this.apiMessage = params.apiMessage;
            }
        } else if (typeof params.errors !== 'undefined') { // .net model validation error response
            this.apiStatus = params.status;
            this.apiMessage = params.title;
            this.errors = params.errors;
        } else {
            this.apiStatus = HTTP_STATUS_CODES.InternalServerError;
            this.apiMessage = "We're sorry, there has been a problem";
        }

        if (typeof params.reason !== 'undefined') { // if there is a reason provided
            this.reason = params.reason;
        }
    }

}