import PropTypes from 'prop-types';
import styles from './GenericError.module.css';

const GenericError = ({ message, title = "Error", code = 500 }) => {

  return <div className={styles.error}>
    {title &&
      <div className={styles.title}>
        {title}
      </div>}
    {code && <div className={styles.code}>
      {code}
    </div>}
    {message &&
      <div className={styles.message}>
        {message}
      </div>}
  </div>;

};

export default GenericError;

GenericError.propTypes = {
  title: PropTypes.any,
  message: PropTypes.string,
  code: PropTypes.number
}