import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../../Foundation/Helpers/Components/Feature/Common/SectionTitle/SectionTitle';
import SectionNavigator from '../Global/SectionNavigator/SectionNavigator';
import { Outlet } from 'react-router-dom';

const navigationData = {

  Memberships: {
    id: "memberships",
    uri: '',
    label: "Memberships"
  },
  Create: {
    id: "create",
    uri: 'create',
    label: "New Membership",
  },
  RedemptionCreate: {
    id: "redemptioncreate",
    uri: 'redemptions/create',
    label: "New Booking"
  },
  RedemptionRequests: {
    id: "redemptionRequests",
    uri: 'redemptions/requests',
    label: "Booking Requests"
  }
}

const Memberships = () => {
  return <div className='memberships'>
    <SectionTitle title="Membership Manager"></SectionTitle>
    <SectionNavigator data={navigationData} />
    <Outlet />
  </div>;
};

export default Memberships;

Memberships.propTypes = {

}