import MembershipRedemptionUserSummaryModel from "./MembershipRedemptionUserSummaryModel";

class MembershipRedemptionSummaryModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.MembershipNumber = params.membershipNumber;
        this.PrimaryRedemption = params.primaryRedemption;
        this.Credits = params.credits;
        this.Value = params.value;
        this.Cost = params.cost;
        this.User = new MembershipRedemptionUserSummaryModel(params.user);

    }

}

export default MembershipRedemptionSummaryModel;