import ApiResponseBase from '../../../../../Base/Domain/ApiResponseBase';
import PartnerSummaryModel from '../PartnerSummaryModel';

class GetPartnerAccountSummaryResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.AccountSummary = new PartnerSummaryModel(params.accountSummary);

  }

}

export default GetPartnerAccountSummaryResponseModel;