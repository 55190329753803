import JsonPatchHelper from '../../../../Utility/JsonPatch/JsonPatchHelper';
import { HTTP_STATUS_CODES, RESPONSE_HEADERS, CONTENT_TYPES } from '../../../_Domain/Api/Constants';
import BaseRestService from './BaseRestService';
import { isObject } from '../../../../Utility/MiscHelpers';

/**
 * @class CrudService
 * @description common api methods and lower level repository layer, interfaces with the BaseRestService
 *              to be used for CRUD methods on CRUD APIs. For APIs that evolve/expand basic CRUD operations
 *              use the RestService
 */

class CrudService {

  #_resource;
  #_resourceCollections;

  constructor(apiEndpoint, resource, resourceCollections) {
    this.#_resource = resource;
    this.#_resourceCollections = resourceCollections;
    this.api = new BaseRestService(apiEndpoint);

    this.api.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson })

  }


  /**
   * @method read
   * @description given an id, get a single entity; given an array, get a batch; given no value, get all
   * 
   * @param {Array/string} params ids (guid) multiple or single, or none for all
   * @param {string} resource override the default resource for the action (eg. for extended api endpoints)
   */
  async read(params = null, resource = null) {

    let result = null;

    if (isObject(params)) {
      result = await this.api.get(resource ?? this.#_resource, `?${new URLSearchParams(params).toString()}`); // todo, test
    } else if (typeof params === 'string') {
      result = await this.api.get(resource ?? this.#_resource, params);
    } else if (params?.length) {
      result = await this.api.get(resource ?? this.#_resourceCollections, params);
    } else {
      result = await this.api.get(resource ?? this.#_resource);
    }

    return this._resultOrError(HTTP_STATUS_CODES.Success, result);

  };

  /**
 * @method create
 * 
 * @param {object} model create model
 * @param {string} resource override the default resource for the action (eg. for extended api endpoints)
 */
  async create(model = null, resource = null) {

    if (model === null) {
      console.error("Create model required")
      return null;
    }

    let result = null;

    if (Array.isArray(model)) {
      if (model.length === 0) {
        console.error("At least one entity required")
        return null;
      }
      result = await this.api.post(resource ?? this.#_resourceCollections, model);
      return this._resultOrError(HTTP_STATUS_CODES.Created, result);
    }

    result = await this.api.post(resource ?? this.#_resource, model);

    return this._resultOrError(HTTP_STATUS_CODES.Created, result);
  }

  /**
   * @method update
   * 
   * @param {string} id entity id to update
   * @param {object} model update model
   * @param {bool} partial indicates that this is a partial update request (PATCH), default PUT
   * @param {string} resource override the default resource for the action (eg. for extended api endpoints)
   */
  async update(id = null, model = null, partial = false, resource = null) {

    if (id === null || model === null || Object.keys(model).length === 0) {
      console.error("Id and Update model required");
      return null;
    }

    let result = null;

    if (partial) {
      let patch = JsonPatchHelper.ToJsonPatchDocument(model);
      result = await this.api.patch(resource ?? this.#_resource, patch, id);
      return this._resultOrError(HTTP_STATUS_CODES.Success, result);
    }

    result = await this.api.put(resource ?? this.#_resource, model, id);

    return this._resultOrError(HTTP_STATUS_CODES.Success, result);

  }

  /**
 * @method updateBatch
 * 
 * @param {object} model update model
 * @param {bool} partial indicates that this is a partial update request (PATCH), default PUT
 * @param {string} resource override the default resource for the action (eg. for extended api endpoints)
 */
  async updateBatch(model = null, partial = false, resource = null) {

    if (model === null || Object.keys(model).length === 0) {
      console.error("Update model required");
      return null;
    }

    let result = null;

    if (partial) {
      model.map(m => m.Values = JsonPatchHelper.ToJsonPatchDocument(m.Values));
      result = await this.api.patch(resource ?? this.#_resourceCollections, model);
      return this._resultOrError(HTTP_STATUS_CODES.Success, result);
    }

    result = await this.api.put(resource ?? this.#_resourceCollections, model);

    return this._resultOrError(HTTP_STATUS_CODES.Success, result);

  }

  /**
 * @method delete
 * @description deletes an entity by id
 * 
 * @param {string} id
 * @returns {bool} true for deleted, false for failed to delete
 * @param {string} resource override the default resource for the action (eg. for extended api endpoints)
 */
  async delete(id = null, resource = null) {

    if (id === null) {
      console.error("Id required to delete");
      return null;
    }

    let result = await this.api.delete(resource ?? this.#_resource, id);

    return this._resultOrError(HTTP_STATUS_CODES.NoContent, result);
  }

  /**
   * @method _resultOrError given a response, if the status code is correct, return the result to the api
   * @private
   */
  _resultOrError = (statusCode, result) => {
    return (result?.status === statusCode) ? result?.data : { error: result?.status, data: result?.data };
  }

}

export default CrudService;