
import { store } from '../store';

/**
 * @description given a service instance and an auth object, set the authentication token in the base service and return the authenticated status based on the state
 * 
 * @param {object} service the instance of the service from which to invoke the method which calls the static property of the base service to be set
 * @param {object} state 
 */
export const setAuthenticationTokenInService = (service, auth) => {

    service.setAuthenticationToken(auth.accessToken);

    return isAuthenticated(auth);

}

/**
 * @description given an authentication object, check if the token property is valid to indicate that the user is authenticated. Given no object, check the global state value
 * 
 * @param {object} auth an authentication object  
 * @returns {boolean} user authentication token is present
 */
export const isAuthenticated = (auth = null) => {

    if (typeof auth !== 'undefined' && auth !== null) {
        return typeof auth?.accessToken !== 'undefined';
    }

    auth = store.getState().global;

    if (typeof auth === 'undefined') {
        return false;
    }

    return auth.userAuthenticated;

}
