import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Confirm.module.css';
import Button from '../../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import formData from './ConfirmData.json';
import Form from '../../../../../Foundation/Helpers/Components/Forms/Form';

const Confirm = ({ data, summary, shared, submit, className }) => {

    const steps = summary;

    let orderedIndeces = [];

    for (let itemId in steps) {
        orderedIndeces.splice(steps[itemId].order, 0, itemId);
    }

    const confirmSubmit = () => {
        if (typeof submit === 'function') {
            submit(shared);
        }
    }

    const renderSummaryItem = (item, i) => {
        return <div className={styles.summaryItem} key={`summary-item_${i}`}>
            <div className={styles.summaryItemLabel}>{item._label}</div>
            {typeof i !== 'undefined' &&
                <div className={styles.summaryItemValue}>{item.value}</div>}
        </div>;
    }

    const renderSummaryItems = (sectionSaveData) => {
        return <div className={styles.summaryItems}>
            {sectionSaveData._single ?
                renderSummaryItem(sectionSaveData._single) : Object.keys(sectionSaveData).map((a, i) => renderSummaryItem(sectionSaveData[a], i))
            }
        </div>
    }

    const renderSummarySection = (sectionData, id, i) => {

        if (typeof sectionData === 'undefined' || Object.keys(sectionData).length === 0 || id === 'confirm') {
            return null;
        }

        return <div className={styles.summarySection} key={`${id}_${i}`}>
            <div className={styles.sectionLabel}>{steps[id]?.label}</div>
            {renderSummaryItems(sectionData.saveData)}
        </div>
    }

    const renderSummary = () => {

        if (typeof shared === 'undefined') {
            return null;
        }

        return <div className={`${styles.summary} panel-strong`}>
            {orderedIndeces.map((id, i) => renderSummarySection(shared[id], id, i))}
        </div>

    }

    const setFieldValuesInState = (val, paymentPlanType = { value: 1 }) => {
        let persistData = Object.assign({}, { _single: { startDate: { _label: val, value: val }, paymentPlanType: { _label: paymentPlanType.value, value: paymentPlanType.value } } });
        data.updateState(true, persistData);
    }

    const onWatchEvent = (watchData) => {
        setFieldValuesInState(watchData.value.StartDate, watchData.value.PaymentPlanType);
    }

    useEffect(() => {
        // set default date to today's date
        let date = new Date();
        formData.sections.MembershipFields.fields[1].defaultValue = formData.sections.MembershipFields.fields[1].value = date;
        formData.sections.MembershipFields.fields[0].defaultValue = formData.sections.MembershipFields.fields[0].value = 1;
        setFieldValuesInState(date, { value: 1 });
    }, []);

    return <div className={styles.confirm}>
        <div className={styles.title}>
            <h3>Confirm new membership</h3>
        </div>
        {renderSummary()}
        <Form model={formData} watchFields={["StartDate", "PaymentPlanType"]} onWatchEvent={onWatchEvent} />
        <Button className={styles.submitButton} onClick={confirmSubmit}>Create new membership</Button>
    </div>;

};

export default Confirm;

Confirm.propTypes = {

}