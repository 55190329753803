import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UserSelector.module.css';
import filterData from './UserSelectorFilterData.json';
import Form from '../../../../../Foundation/Helpers/Components/Forms/Form';
import { toast } from 'react-toastify';
import Notification from '../../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import _userSearchServicesApiService from '../../../../../Application/ApiServices/User/Search/Services/UserSearchServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../../Foundation/Rest/Constants';
import UserSelelctorResultItem from './UserSelectorResultItem/UserSelectorResultItem';
import CustomLoader from '../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { useSearchParams } from 'react-router-dom';

const REFERAL_QUERY = "accountNumber";

const UserSelector = ({ data, shared, ui, className }) => {

    const [isPendingData, setIsPendingData] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const filterSubmit = (submitData) => {

        setIsPendingData(true);
        setSearchTerm(submitData?.SearchTerm);

        let dto = { ...submitData };

        _userSearchServicesApiService.searchSummary(dto).then((response) => {

            if (response.apiStatus === HTTP_STATUS_CODES.Success) {
                data.updateState(null, {});
                data.updateUiState(Object.assign({}, { results: response.Results }));
            } else {
                toast(<Notification label="Unable to complete search" mode="error" />)
            }

            setIsPendingData(false);

        });
    }

    const onSelectItem = (selectedData) => {
        let persistData = Object.assign(shared.user, { _single: { _label: `${selectedData.FirstName} ${selectedData.LastName} (${selectedData.AccountNumber})`, value: selectedData.AccountNumber } });

        data.updateState(true, persistData);
        data.updateUiState(Object.assign(ui.user || {}, { selectedItemId: selectedData.AccountNumber }));
    }

    const renderNoResults = () => {
        return <div className={styles.noResults}>No results for "{searchTerm}"</div>
    }

    useEffect(() => {
        if (searchParams.get(REFERAL_QUERY)) {
            filterSubmit({ SearchTerm: searchParams.get(REFERAL_QUERY) })
        }
    }, [searchParams]);

    useEffect(() => {
        let query = searchParams.get(REFERAL_QUERY);
        if (query === searchTerm && query !== null) {
            if (typeof ui.user?.results?.Count !== 'undefined' && ui.user?.results?.Count !== 0) {
                let matchResult = ui.user?.results?.Items?.find(x => x.AccountNumber === searchParams.get(REFERAL_QUERY))
                onSelectItem(matchResult);
            }
        }
    }, [ui.user]);

    filterData.onSubmit = filterSubmit; // override to add submit functionality

    return <div className={`${styles.userSelector} ${shared?.user?.valid && styles.userSelectionSectionValid}`}>
        <div className={styles.title}>
            <h3>Select a user</h3>
            <div className={styles.filter}>
                <Form model={filterData} isDisabled={isPendingData} />
            </div>
        </div>
        <div className={styles.results}>
            {isPendingData ? <CustomLoader label={"Searching users"} pending={isPendingData} className={styles.loader} /> :
                <>
                    {ui.user?.results !== null && ui.user?.results?.Count > 0 &&
                        ui.user.results.Items.map((r, i) => <UserSelelctorResultItem data={r} index={i} key={`${r.AccountNumber}_${i}`} selectedId={ui.user.selectedItemId} onSelect={onSelectItem} />)}
                    {ui.user?.results !== null && ui.user?.results?.Count === 0 &&
                        renderNoResults()}
                </>}
        </div>
    </div>;

};

export default UserSelector;

UserSelector.propTypes = {

}