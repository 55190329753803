import MembershipPaymentModel from "./MembershipPaymentModel";

class MembershipPaymentsSummaryModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.Outstanding = params.outstanding;
        this.Settled = params.settled;
        this.Total = params.total;
        this.Payments = params.outstanding;
        this.Payments = params.payments.map(a => new MembershipPaymentModel(a));

    }

}

export default MembershipPaymentsSummaryModel;
