class CountyModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.Id = params.id;
        this.Territory = params.territory;
        this.Name = params.name;
        this.Code = params.code;

    }

}

export default CountyModel;