import ApiResponseBase from "../../../../../Base/Domain/ApiResponseBase";
import MembershipCreditModel from "../MembershipCreditModel";

class AdjustCreditBalanceResponseModel extends ApiResponseBase {

    constructor(params) {

        super(params);

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.MembershipCredit = new MembershipCreditModel(params.membershipCredit);
    }

}

export default AdjustCreditBalanceResponseModel;
