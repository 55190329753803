import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import GetPartnerAccountSummaryResponseModel from '../Models/PartnerAccount/Get/GetPartnerAccountSummaryResponseModel';
import GetPartnerAccountDetailsResponseModel from '../Models/PartnerAccount/Get/GetPartnerAccountDetailsResponseModel';
import UpdatePartnerDetailsRequestModel from '../Models/PartnerAccount/Update/UpdatePartnerDetailsRequestModel';
import UpdatePartnerDetailsResponseModel from '../Models/PartnerAccount/Update/UpdatePartnerDetailsResponseModel';
import CreatePartnerDetailsRequestModel from '../Models/PartnerAccount/Create/CreatePartnerDetailsRequestModel';
import CreatePartnerDetailsResponseModel from '../Models/PartnerAccount/Create/CreatePartnerDetailsResponseModel';

const API_ENDPOINT = process.env.REACT_APP_PARTNER_ACCOUNTSERVICES_API_HOST;

class PartnerAccountServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
  }

  /**
   * @method get
   * @type {GET}
   * @description as an admin user, gets partner account summary by account number
   * 
   * @param {string} id resource id (partner account number)
   * @returns {Task} AccountSummary
   */
   getSummary = async (id) => this._restService.get(null, `${RESOURCE}${ACTIONS.GetSummary}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetPartnerAccountSummaryResponseModel(result));

  /**
   * @method get
   * @type {GET}
   * @description as an admin user, gets partner account details by account number
   * 
   * @param {string} id resource id (partner account number)
   * @returns {Task} AccountDetails
   */
  get = async (id) => this._restService.get(null, `${RESOURCE}${ACTIONS.Get}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetPartnerAccountDetailsResponseModel(result));

  /**
   * @method update
   * @type {PUT}
   * @description as an admin user, update partner account details
   * 
   * @param {object} model AdminUpdatePartnerDetailsRequestModel request model
   * @param {string} id resource id (partner account number)
   * @returns {Task} 
   */
  update = async (model, id) => {
    delete this._restService.api.headers[RESPONSE_HEADERS.ContentType]; // is form, browser decides content type from model base type (FormData)
    return this._restService.update(new UpdatePartnerDetailsRequestModel(model), false, `${RESOURCE}${ACTIONS.Update}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new UpdatePartnerDetailsResponseModel(result))
  };

  /**
   * @method create
   * @type {POST}
   * @description as an admin user, create partner account details
   * 
   * @param {object} model CreatePartnerDetailsRequestModel request model 
   * @returns {Task} 
   */
  create = async (model) => {
    delete this._restService.api.headers[RESPONSE_HEADERS.ContentType]; // is form, browser decides content type from model base type (FormData)
    return this._restService.post(new CreatePartnerDetailsRequestModel(model), `${RESOURCE}${ACTIONS.Create}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new CreatePartnerDetailsResponseModel(result))
  };

}

export default new PartnerAccountServicesApiService();