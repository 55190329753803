import React from 'react';
import styles from './ActionsPanel.module.css';

const ActionsPanel = ({ className, title, links, actions }) => {
  return <div className={`${styles.panel} ${className}`}>
    {title && <h3>{title}</h3>}
    {actions && <div className={styles.actions}>
      {actions}
    </div>}
    {links && <div className={styles.links}>
      {links}
    </div>}
  </div>
};

export default ActionsPanel;

ActionsPanel.propTypes = {

}