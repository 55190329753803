/**
 * @description submitted as FormData (contains file)
 */
class AdminUpdateBasicDetailsRequestModel extends FormData {

    constructor(params) {

        super();

        if (typeof params === 'undefined') {
            return;
        }

        this.append('FirstName', params.FirstName);
        this.append('LastName', params.LastName);
        this.append('Email', params.Email);
        this.append('CountyId', params.CountyId?.value);
        this.append('HouseNameNumber', params.HouseNameNumber);
        this.append('FirstLineAddress', params.FirstLineAddress);
        this.append('SecondLineAddress', params.SecondLineAddress);
        this.append('PostCode', params.PostCode);
        this.append('Telephone', params.Telephone);

        if(typeof params.ProfileImagesRemove !== 'undefined' && params.ProfileImagesRemove.length !== 0){
            this.append('ProfileImagesRemove', params.ProfileImagesRemove);
        }

        if(typeof params.ProfileImage !== 'undefined' && params.ProfileImage.length !== 0){
            this.append('ProfileImage', params.ProfileImage[0]);
        }

    }

}

export default AdminUpdateBasicDetailsRequestModel;