import SearchApiResponseBase from '../../../../../Base/Domain/SearchApiResponseBase';
import MembershipSummaryListItemModel from '../MembershipSummaryListItemModel';

class MembershipSearchSummaryResponseModel extends SearchApiResponseBase {

  constructor(params) {

    super(params, MembershipSummaryListItemModel);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

  }

}

export default MembershipSearchSummaryResponseModel;