/**
 * @description submitted as FormData (contains file)
 */
class UpdatePartnerDetailsRequestModel extends FormData {

    constructor(params) {

        super();

        if (typeof params === 'undefined') {
            return;
        }

        this.append('PartnerId', params.PartnerId);
        this.append('Name', params.Name);
        this.append('Summary', params.Summary);
        this.append('Information', params.Information);
        this.append('PrimaryEmail', params.PrimaryEmail);
        this.append('Telephone', params.Telephone);
        this.append('FirstLineAddress', params.FirstLineAddress);
        this.append('SecondLineAddress', params.SecondLineAddress);
        this.append('ThirdLineAddress', params.ThirdLineAddress);
        this.append('PostCode', params.PostCode);
        this.append('CountyId', params.CountyId?.value);
        this.append('Length', params.Length);
        this.append('Holes', params.Holes);
        this.append('Par', params.Par);
        this.append(`Type`, params.Type);
        this.append('HighSeasonWeekdayCredits', params.HighSeasonWeekdayCredits);
        this.append('HighSeasonWeekendCredits', params.HighSeasonWeekendCredits);
        this.append('OffSeasonWeekdayCredits', params.OffSeasonWeekdayCredits);
        this.append('OffSeasonWeekendCredits', params.OffSeasonWeekendCredits);
        this.append('HighSeasonWeekdayFee', params.HighSeasonWeekdayFee);
        this.append('HighSeasonWeekendFee', params.HighSeasonWeekendFee);
        this.append('OffSeasonWeekdayFee', params.OffSeasonWeekdayFee);
        this.append('OffSeasonWeekendFee', params.OffSeasonWeekendFee);
        this.append('Prestige', params.Prestige);
        this.append('Published', params.Published);
        this.append('RedemptionCost', params.RedemptionCost);
        this.append('MaxDaysFutureBooking', params.MaxDaysFutureBooking);
        this.append('MondaySlotTimes', params.MondaySlotTimes);
        this.append('TuesdaySlotTimes', params.TuesdaySlotTimes);
        this.append('WednesdaySlotTimes', params.WednesdaySlotTimes);
        this.append('ThursdaySlotTimes', params.ThursdaySlotTimes);
        this.append('FridaySlotTimes', params.FridaySlotTimes);
        this.append('SaturdaySlotTimes', params.SaturdaySlotTimes);
        this.append('SundaySlotTimes', params.SundaySlotTimes);

        if (params.Tees !== false) {
            params.Tees.map((tee) => {
                this.append('Tees', +tee);
            });
        }

        if (params.Attributes !== false) {
            params.Attributes?.map((attribute) => {
                this.append('Attributes', attribute);
            });
        }       

        if (params.HighSeasonMonths !== false) {
            params.HighSeasonMonths?.map((month) => {
                this.append('HighSeasonMonths', month);
            });
        }

        if (typeof params.PartnerImagesRemove !== 'undefined' && params.PartnerImagesRemove?.length !== 0) {
            params.PartnerImagesRemove?.map((partnerImageRemove) => {
                this.append('PartnerImagesRemove', partnerImageRemove);
            });
        }

        if (typeof params.PartnerImages !== 'undefined' && params.PartnerImages?.length !== 0) {
            const fileArray = Array.from(params.PartnerImages);
            fileArray?.map((partnerImage) => {
                this.append('PartnerImages', partnerImage);
            });
        }

    }

}

export default UpdatePartnerDetailsRequestModel;
