import React from 'react';
import PropTypes from 'prop-types';
import { CLASS_MODIFIERS } from '../../Utility/_formsConfig';
import FieldBase from '../Base/FieldBase';

const TextArea = ({ name, label, readOnly, placeholder, defaultValue, maxLength, register, errors, alive, incrementor, validation = {} }) => {

  const error = typeof errors !== 'undefined';

  return <FieldBase {...{ errors, alive, ['classModifier']: CLASS_MODIFIERS.TextArea, label, incrementor }}>
    <textarea
      className="textarea-field"
      readOnly={readOnly}
      placeholder={placeholder}
      {...register(name, validation)}
      defaultValue={defaultValue}
      maxLength={maxLength} />
  </FieldBase>;

};

export default TextArea;

TextArea.propTypes = {

}