class MembershipTypeModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.Id = params.id;
        this.Name = params.name;
        this.Annual = params.annual;
        this.Active = params.active;

    }

}

export default MembershipTypeModel;