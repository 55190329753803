import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import FieldBase from '../Base/FieldBase';
import { CLASS_MODIFIERS } from '../../Utility/_formsConfig';
import styles from './TimePicker.module.css';
import Select from 'react-select';

const TimePicker = ({ name, options = [], label, defaultValue, errors, alive, incrementor, control, validation = {}, isMulti }) => {

    if (options.length === 0) {
        return null;
    }

    const defaultOption = options.find(x => x.value === defaultValue);

    return <FieldBase {...{ errors, alive, ['classModifier']: CLASS_MODIFIERS.Dropdown, label, incrementor }}>
        <Controller
            name={name}
            control={control}
            rules={validation}
            defaultValue={defaultOption}
            render={({ field }) => <Select
                {...field}
                isMulti={isMulti}
                inputId={name}
                classNamePrefix="select-field"
                components={{
                    IndicatorSeparator: () => null
                }}
                options={options}
            />}
        />
    </FieldBase>

};

export default TimePicker;

TimePicker.propTypes = {
}