import MembershipListingResult from '../Memberships/MembershipManager/ListingResult/MembershipListingResult';
import PartnerListingResult from '../Partners/PartnerManager/ListingResult/PartnerListingResult';
import UserListingResult from '../Users/UserManager/ListingResult/UserListingResult';
import BookingRequestListingResult from '../Redemptions/Requests/BookingRequestManager/ListingResult/BookingRequestListingResult';

const FilterListingTypeResolver = {
    "user": UserListingResult,
    "partner": PartnerListingResult,
    "membership": MembershipListingResult,
    "bookingrequest": BookingRequestListingResult
}

export default FilterListingTypeResolver;