import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './EditDetailsPanel.module.css';
import Button from '../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';

const EditDetailsPanel = ({ className, children, isEditMode, editRef, cancelRef, submitRef, submitLabel, noEdit, isPendingEdit }) => {

  return <div className={`${styles.panel} ${className}`}>
    {children}
    {!noEdit &&
      <div className={styles.controls}>
        {!isEditMode ? <Button type="none" noArrow className='system-form__actions__edit-cancel' secondary onClick={() => editRef.current()}>Edit</Button> :
          <>
            <Button disabled={isPendingEdit} type="none" noArrow className='system-form__actions__edit-cancel' tertiary onClick={() => cancelRef.current()}>Cancel</Button>
            <Button disabled={isPendingEdit} primary onClick={() => submitRef.current()}>{submitLabel}</Button>
          </>}
      </div>}
  </div>

};

export default EditDetailsPanel;

EditDetailsPanel.propTypes = {

}
