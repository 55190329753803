import { HTTP_STATUS_CODES } from '../../../_Domain/Api/Constants';
import { isObject } from '../../../../Utility/MiscHelpers';
import BaseRestService from './BaseRestService';

/**
 * @class RestService
 * @description common rest api methods, not neccessarily associated with crud operations
 */

class RestService {

  #_resource;

  constructor(apiEndpoint, resource) {
    this.#_resource = resource;
    this.api = new BaseRestService(apiEndpoint);
  }

  /**
   * @method get
   * @description given an id, get a single entity; given an array, get a batch; given no value, get all
   * 
   * @param {Array/string} params ids (guid) multiple or single, or none for all
   * @param {string} resource override the default resource for the action (eg. for extended api endpoints)
   */
  async get(params = null, resource = null) {

    let result = null;

    if (isObject(params)) {
      result = await this.api.get(resource ?? this.#_resource, `?${new URLSearchParams(params).toString()}`); // todo, test
    } else if (typeof params === 'string') {
      result = await this.api.get(resource ?? this.#_resource, params);
    } else {
      result = await this.api.get(resource ?? this.#_resource);
    }

    return this._resultOrError(HTTP_STATUS_CODES.Success, result);

  };

  /**
 * @method post
 * 
 * @param {object} model post model
 * @param {string} resource override the default resource for the action (eg. for extended api endpoints)
 */
  async post(model = null, resource = null) {

    let result = null;

    result = await this.api.post(resource ?? this.#_resource, model);

    return this._resultOrError([HTTP_STATUS_CODES.Success, HTTP_STATUS_CODES.Created], result);
  }

  /**
   * @method update
   * 
   * @param {object} model update model
   * @param {bool} partial indicates that this is a partial update request (PATCH), default PUT
   * @param {string} resource override the default resource for the action (eg. for extended api endpoints)
   */
  async update(model = null, partial = false, resource = null) {

    if (model === null) {
      console.error("Update model required");
      return null;
    }

    let result = null;

    if (partial) {
      result = await this.api.patch(resource ?? this.#_resource, model);
      return this._resultOrError(HTTP_STATUS_CODES.Success, result);
    }

    result = await this.api.put(resource ?? this.#_resource, model);

    return this._resultOrError(HTTP_STATUS_CODES.Success, result);

  }

  /**
   * @method delete
   * @description deletes an entity
   * 
   * @param {string} id (optional)
   * @returns {bool} true for deleted, false for failed to delete
   * @param {string} resource override the default resource for the action (eg. for extended api endpoints)
   */
  async delete(id = null, resource = null) {

    let result = await this.api.delete(resource ?? this.#_resource, id);

    return this._resultOrError([HTTP_STATUS_CODES.Success, HTTP_STATUS_CODES.NoContent], result);
  }

  /**
   * @method _resultOrError given a response, if the status code is correct, return the result to the api
   * @private
   */
  _resultOrError = (statusCode, result) => {
    
    if(typeof result === 'undefined' || result === null){
      return null;
    }

    let hasResult = false;

    if (Array.isArray(statusCode)) {
      hasResult = statusCode.includes(result.status);
    } else {
      hasResult = result.status === statusCode;
    }

    return hasResult ? result.data : { error: result.status, data: result.data };

  }

}

export default RestService;