import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';
import CustomLoader from '../../MicroUi/CustomLoader/CustomLoader';

const Button = ({ primary, skinny, ghost, danger, secondary, tertiary, children, className = '', noArrow = false, type = 'submit', onClick = null, disabled, loading = false }) => {

  const onKeyPress = e => {
    const enterOrSpace =
      e.key === "Enter" ||
      e.key === " " ||
      e.key === "Spacebar" ||
      e.which === 13 ||
      e.which === 32;
    if (enterOrSpace) {
      e.preventDefault();
      onClick(e);
    }
  };

  return type === 'none' ?
    <div onKeyPress={onKeyPress} tabIndex={0} role="button" aria-pressed={false} onClick={disabled ? null : onClick} type={type} className={`sys-button ${loading && styles.loading} ${styles.button} ${className} ${disabled ? styles.disabled : ''} ${primary ? 'primary-bg' : ''} ${secondary ? 'secondary-bg' : ''} ${danger ? 'danger-btn' : ''} ${tertiary ? 'tertiary-bg' : ''} ${ghost ? 'ghost' : ''} ${noArrow ? styles.noArrow : ''} ${skinny ? styles.skinny : ''}`}>
      <span className={styles.anchorText}>{children}</span>
      {!noArrow &&
        <span className={styles.arrow}></span>
      }
      {loading && <CustomLoader pending={loading} className={styles.loader} />}
    </div> :
    <button onClick={disabled ? null : onClick} type={type} className={`sys-button ${loading && styles.loading} ${styles.button} ${className} ${disabled ? styles.disabled : ''} ${primary ? 'primary-bg' : ''} ${secondary ? 'secondary-bg' : ''} ${tertiary ? 'tertiary-bg' : ''} ${danger ? 'danger-btn' : ''} ${ghost ? 'ghost' : ''} ${noArrow ? styles.noArrow : ''} ${skinny ? styles.skinny : ''}`}>
      <span className={styles.anchorText}>{children}</span>
      {!noArrow &&
        <span className={`${styles.arrow} button-arrow`}></span>
      }
      {loading && <CustomLoader pending={loading} className={styles.loader} />}
    </button>;
};

export default Button;

Button.propTypes = {

}