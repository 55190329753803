import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './MembershipSelector.module.css';
import filterData from './MembershipSelectorFilterData.json';
import Form from '../../../../../Foundation/Helpers/Components/Forms/Form';
import { toast } from 'react-toastify';
import Notification from '../../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import _membershipSearchServicesApiService from '../../../../../Application/ApiServices/Membership/MembershipSearchServices/Services/MembershipSearchServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../../Foundation/Rest/Constants';
import MembershipSelectorResultItem from './MembershipSelectorResultItem/MembershipSelectorResultItem';
import CustomLoader from '../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import MembershipSelectorResultItemViewModel from './MembershipSelectorResultItem/MembershipSelectorResultItemViewModel';
import Pill from '../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';
import { useLocation, useSearchParams } from 'react-router-dom';

const MAX_GUESTS = 3,
    MEMBERSHIP_SELECTOR_KEY = "MembershipSelection",
    MEMBERSHIP_NUMBER_REFERAL_QUERY = "membershipNumber";

const MembershipSelector = ({ data, shared, ui, className }) => {

    const [isPendingData, setIsPendingData] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [primarySetFromDefaultData, setPrimaryFromDefaultData] = useState(false);

    const hasPrimarySelected = () => (typeof shared?.membership?.saveData?._single?.value?.primary !== 'undefined');

    const resultsToViewModel = (results) => {
        let outcome = { ...results };
        outcome.Items = outcome.Items.map(a => new MembershipSelectorResultItemViewModel(a));
        return outcome;
    }

    const filterSubmit = (submitData) => {

        setIsPendingData(true);
        setSearchTerm(submitData?.SearchTerm);

        let dto = { ...submitData };

        _membershipSearchServicesApiService.searchSummary(dto).then((response) => {

            if (response.apiStatus === HTTP_STATUS_CODES.Success) {
                let resultsAsViewModel = resultsToViewModel(response.Results);
                data.updateUiState(Object.assign(ui.membership || {}, { results: resultsAsViewModel }));
            } else {
                toast(<Notification label="Unable to complete search" mode="error" />)
            }

            setIsPendingData(false);

        });
    }

    const onSelectPrimary = (selectedData) => {

        if(typeof selectedData === 'undefined'){
            return;
        }


        let result = {
            _key: MEMBERSHIP_SELECTOR_KEY,
            value: {
                primary: { label: `${selectedData.User.FirstName} ${selectedData.User.LastName} (${selectedData.MembershipNumber})`, value: selectedData },
                guests: Array.isArray(shared.membership?.saveData?._single?.value?.guests) ? shared.membership?.saveData?._single?.value?.guests : []
            }
        }

        let persistData = Object.assign(shared.membership.saveData || {}, { _single: result });
        data.updateState(true, persistData);
        data.updateUiState(Object.assign(ui.membership || {}, persistData?._single.value));
    }

    const onSelectGuest = (selectedData) => {

        let guests = Array.isArray(shared?.membership?.saveData?._single?.value?.guests) ? shared?.membership?.saveData?._single?.value.guests : [];

        // validate, max selected guests
        if (guests.some(e => e.id === selectedData.MembershipNumber) || guests.length === MAX_GUESTS) {
            // todo, more validation
            // if primary, don't add as guest 
            return;
        }

        if (Array.isArray(selectedData)) {
            let mappedSelectedData = selectedData.map(a => ({ id: a.MembershipNumber, label: `${a.User.FirstName} ${a.User.LastName} (${a.MembershipNumber})`, value: a }));
            guests = [...guests, ...mappedSelectedData];
        } else {
            guests.push({ id: selectedData.MembershipNumber, label: `${selectedData.User.FirstName} ${selectedData.User.LastName} (${selectedData.MembershipNumber})`, value: selectedData });
        }


        let result = {
            _key: MEMBERSHIP_SELECTOR_KEY,
            value: { primary: shared?.membership?.saveData?._single?.value?.primary, guests }
        }

        let persistData = Object.assign(shared?.membership?.saveData || {}, { _single: result });
        data.updateState(hasPrimarySelected(), persistData);
        data.updateUiState(Object.assign(ui.membership || {}, persistData?._single.value));

    }

    const onPrimaryRemove = () => {

        let result = {
            _key: MEMBERSHIP_SELECTOR_KEY,
            value: {
                primary: null, // remove the primary
                guests: Array.isArray(shared.membership.saveData?._single?.value?.guests) ? shared.membership.saveData?._single?.value?.guests : []
            }
        }


        if (ui.membership.primary.value.MembershipNumber === searchParams.get(MEMBERSHIP_NUMBER_REFERAL_QUERY)) {
            searchParams.delete(MEMBERSHIP_NUMBER_REFERAL_QUERY);
            setSearchParams(searchParams);
        }

        let persistData = Object.assign(shared.membership.saveData || {}, { _single: result });
        data.updateState(false, persistData);
        data.updateUiState(Object.assign(ui.membership || {}, persistData?._single.value));
    }

    const onGuestRemove = (membershipNumber) => {

        let guests = Array.isArray(shared.membership.saveData?._single?.value?.guests) ? shared.membership.saveData?._single?.value?.guests : [],
            collectionWithSelectionRemoved = guests.filter(item => item.value.MembershipNumber !== membershipNumber);

        let result = {
            _key: MEMBERSHIP_SELECTOR_KEY,
            value: {
                primary: shared.membership.saveData?._single?.value?.primary || null,
                guests: collectionWithSelectionRemoved // remove the guest entity 
            }
        }

        let persistData = Object.assign(shared.membership.saveData || {}, { _single: result });
        data.updateState(hasPrimarySelected(), persistData);
        data.updateUiState(Object.assign(ui.membership || {}, persistData?._single.value));
    }

    const renderNoResults = () => {
        return <div className={styles.noResults}>No results for "{searchTerm}"</div>
    }

    useEffect(() => {

        // membership number referral query
        if (searchParams.get(MEMBERSHIP_NUMBER_REFERAL_QUERY)) {
            filterSubmit({ SearchTerm: searchParams.get(MEMBERSHIP_NUMBER_REFERAL_QUERY) })
        }

    }, [searchParams]);

    useEffect(() => {
        if (searchParams.get(MEMBERSHIP_NUMBER_REFERAL_QUERY) === searchTerm) {
            if (ui.membership?.results?.Count !== 0) {
                let matchResult = ui.membership?.results?.Items?.find(x => x.MembershipNumber === searchParams.get(MEMBERSHIP_NUMBER_REFERAL_QUERY))
                onSelectPrimary(matchResult);
            }
        }
    }, [ui.membership]);

    useEffect(() => {

        // preloaded data from referral
        if (!primarySetFromDefaultData && Object.keys(shared).length !== 0) {
            if (typeof data?.defaultData !== 'undefined') {
                const preloadDataPrimary = data.defaultData?.primary;
                if (typeof preloadDataPrimary !== 'undefined') {
                    onSelectPrimary(preloadDataPrimary);
                    setPrimaryFromDefaultData(true);
                }
            }
        }

    }, [data.defaultData]);

    useEffect(() => {

        if (typeof shared.membership?.saveData?._single?.value?.guests === 'undefined' || shared.membership?.saveData?._single?.value?.guests.length === 0) {
            const preloadDataGuests = data.defaultData?.guest;
            if (typeof preloadDataGuests !== 'undefined') {
                onSelectGuest(preloadDataGuests);
            }
        }

    }, [primarySetFromDefaultData]);

    filterData.onSubmit = filterSubmit; // override to add submit functionality

    const renderEntity = (entity, inc = '', label = null, onRemove) => {
        if (typeof entity === 'undefined' || entity === null || typeof entity.User === 'undefined') {
            return null;
        }

        return <div className={styles.selectionInner} key={inc}>
            <div className={styles.selectionHeader}>
                <Pill label={label} className={styles.selectionPill} />
                <div className={styles.selectionRemove} onClick={() => onRemove(entity.MembershipNumber)}>Remove</div>
            </div>
            <div className={styles.selectionMembershipNumber}>
                {entity.MembershipNumber}
            </div>
            <div className={styles.userDetails}>
                <div className={styles.name}>{entity.User.FirstName} {entity.User.LastName}</div>
            </div>
        </div>
    }

    let selectedIds = [];
    if (typeof ui.membership !== 'undefined') {
        selectedIds = typeof ui?.membership?.guests !== 'undefined' ? ui.membership.guests?.map(g => g.value.MembershipNumber) : [];
        if (typeof ui.membership.primary !== 'undefined' && ui.membership.primary !== null) {
            selectedIds.push(ui.membership.primary?.value.MembershipNumber);
        }
    }

    return <div className={`${styles.membershipSelector} ${shared?.membership?.valid && styles.membershipSelectionSectionValid}`}>
        <div className={styles.searchComponent}>
            <div className={styles.filter}>
                <Form model={filterData} isDisabled={isPendingData} />
            </div>
            <div className={styles.results}>
                {isPendingData ? <CustomLoader label={"Searching memberships"} pending={isPendingData} className={styles.loader} /> :
                    <>
                        {ui.membership?.results !== null && ui.membership?.results?.Count > 0 &&
                            ui.membership.results.Items.map((r, i) => <MembershipSelectorResultItem data={r} index={i} key={`${r.MembershipNumber}_${i}`} selectedItem={selectedIds.includes(r.MembershipNumber)} onSelectPrimary={onSelectPrimary} onSelectGuest={onSelectGuest} />)}
                        {ui.membership?.results !== null && ui.membership?.results?.Count === 0 &&
                            renderNoResults()}
                    </>}
            </div>
        </div>
        <div className={styles.selection}>
            <div className={styles.selectionTitle}>
                Selected Members
            </div>
            <div className={styles.primary}>
                {(typeof ui.membership?.primary !== 'undefined' && ui.membership.primary !== null) ?
                    renderEntity(ui.membership?.primary.value, 1, "Primary", onPrimaryRemove) :
                    <div className={styles.noSelection}>No primary</div>
                }
            </div>
            <div className={styles.guests}>
                {ui.membership?.guests?.length > 0 ?
                    ui.membership?.guests.map((g, i) => renderEntity(g.value, `${i}_gst`, "Guest", onGuestRemove)) :
                    <div className={styles.noSelection}>No guests</div>
                }
            </div>
        </div>
    </div>;

};

export default MembershipSelector;

MembershipSelector.propTypes = {

}