class PartnerListItemModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        if(typeof params.createdAt !== 'undefined'){
            this.CreatedAt = new Date(params.createdAt)?.toDateString()?.replace(/^\S+\s/,'');
        }
        
        this.PartnerId = params.partnerId;
        this.Name = params.name;
        this.PrimaryEmail = params.primaryEmail;
        this.CountyName = params.countyName;
        this.CountryName = params.countryName;
        this.LeadingImageUri = params.leadingImageUri;
        this.Type = params.type;
        this.Prestige = params.prestige;
        this.Published = params.published;

    }

}

export default PartnerListItemModel;