class PartnerSummaryModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.PartnerId = params.partnerId;
        this.Name = params.name;
        this.Summary = params.summary;
        this.PrimaryEmail = params.primaryEmail;
        this.Telephone = params.telephone;
        this.PostCode = params.postCode;
        this.TerritoryName = params.territoryName;
        this.CountyName = params.countyName;
        this.Prestige = params.prestige;
        this.Published = params.published;

        if (typeof params.createdAt !== 'undefined') {
            this.CreatedAt = new Date(params.createdAt)?.toDateString()?.replace(/^\S+\s/, '');
        }

    }

}

export default PartnerSummaryModel;