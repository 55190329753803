import React from 'react';
import PropTypes from 'prop-types';
import styles from './Pill.module.css';

const Pill = ({ label, className='' }) => {

  return <div className={`${styles.pill} ${className}`}>
    <div className={styles.label}>{label}</div>
  </div>;

};

export default Pill;

Pill.propTypes = {
  label: PropTypes.string
}