import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import GetRedemptionsResponseModel from '../Models/Get/GetRedemptionsResponseModel';
import CreateRedemptionRequestModel from '../Models/Create/CreateRedemptionRequestModel';
import CreateRedemptionResponseModel from '../Models/Create/CreateRedemptionResponseModel';
import GetRedemptionResponseModel from '../Models/Get/GetRedemptionResponseModel';
import GetMembershipRedemptionResponseModel from '../Models/Get/GetMembershipRedemptionResponseModel';
import GetMembershipRedemptionRequestModel from '../Models/Get/GetMembershipRedemptionRequestModel';

const API_ENDPOINT = process.env.REACT_APP_REDEMPTION_MEMBERSERVICES_API_HOST;

class RedemptionServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method getMembershipRedemption
   * @type {GET}
   * @description as an admin user, gets redemption by reference id
   * 
   * @param {GetMembershipRedemptionRequestModel} model 
   * @returns {Task} Redemption
   */
  getMembershipRedemption = async (model) => this._restService.get(new GetMembershipRedemptionRequestModel(model), `${RESOURCE}${ACTIONS.GetMembershipRedemption}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipRedemptionResponseModel(result));

  /**
   * @method getByReferenceId
   * @type {GET}
   * @description as an admin user, gets redemption by reference id
   * 
   * @param {string} id resource id (reference id)
   * @returns {Task} Redemption
   */
  getByReferenceId = async (id) => this._restService.get(null, `${RESOURCE}${ACTIONS.GetByReferenceId}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetRedemptionResponseModel(result));

  /**
   * @method getByMembershipNumber
   * @type {GET}
   * @description as an admin user, gets redemptions by membership number
   * 
   * @param {string} id resource id (membership number)
   * @returns {Task} [Redemption]
   */
  getByMembershipNumber = async (id) => this._restService.get(null, `${RESOURCE}${ACTIONS.GetByMembershipNumber}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetRedemptionsResponseModel(result));

  /**
   * @method create
   * @type {POST}
   * @description as an admin user, create a new redemption
   * 
   * @param {object} model CreateRedemptionRequestModel request model 
   * @returns {Task} Redemption
   */
  create = async (model) => {
    return this._restService.post(new CreateRedemptionRequestModel(model), `${RESOURCE}${ACTIONS.Create}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new CreateRedemptionResponseModel(result))
  };

}

export default new RedemptionServicesApiService();