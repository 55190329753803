import { UPDATE_AUTH_STATE, UPDATE_AUTH_STATE_COMPLETE, UPDATE_NETWORK_STATE_COMPLETE } from '../../Actions/Global/actionTypes';
import initialState from '../../initialState';

/**
 * @description globalReducer 
 */
const globalReducer = (state = initialState.global, action) => {

    switch (action.type) {

        // case UPDATE_AUTH_STATE:
        // case UPDATE_AUTH_STATE_COMPLETE:
        //     let updateState = {
        //         userAuthenticated: action.authState
        //     };
        //     return { ...state, ...updateState };
        case UPDATE_NETWORK_STATE_COMPLETE:           
            let updateNetworkState = {
                offline: action.networkState
            };
            return { ...state, ...updateNetworkState };
        default:
            return state;
    }
};

export default globalReducer;