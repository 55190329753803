import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './MembershipListingResult.module.css';
import LinkButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import Pill from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';
import parse from 'html-react-parser';
import { ReactComponent as MembershipsIcon } from '../../../../Foundation/Theming/Icons/Navigation/memberships.svg';

const MembershipListingResult = ({ model }) => {

  return <div className={`${styles.userListingResult}`}>
    <div className={styles.profileContainer}>
      <div className={styles.taxonomyIcon}>
        <MembershipsIcon />
      </div>
      <div className={styles.activeDisplay}>
        {model.Expired ? <Pill label={"Expired"} className={styles.expired} /> : (!model.Activated ? <Pill label={"Awaiting Activation"} className={styles.awaitingActivation} /> : <Pill label={"Active"} className={styles.active} />)}
        {model.Locked && <Pill label={"Locked"} className={styles.locked} />}
      </div>
    </div>
    <div className={styles.information}>
      <div className={styles.upper}>
        <div className={styles.headline}>
          <div className={styles.number}>{model.MembershipNumber}</div>
          <div className={styles.name}>
            <div>{model.User.FullName}</div>
            <div className={styles.email}>{model.User.Email}</div>
          </div>
        </div>
      </div>
      <div className={`${styles.tags}`}>
        <div className={`${styles.type}`}>
          <div className={`${styles.typeTag} label-tag`}>
            {model.TypeName}
          </div>
        </div>
        <div className={styles.tag}>
          <div className={styles.label}>
            Valid from:
          </div>
          <div className={`${styles.value} label-tag`}>
            {parse(model.StartDate)}
          </div>
        </div>
        <div className={styles.tag}>
          <div className={styles.label}>
            Expires:
          </div>
          <div className={`${styles.value} label-tag`}>
            {parse(model.ExpiryDate)}
          </div>
        </div>
      </div>
    </div>

    <div className={styles.actions}>
      <LinkButton url={model.MembershipNumber} label="Details" mode="small" arrowDirection='right'></LinkButton>
    </div>
  </div>

};

export default MembershipListingResult;

MembershipListingResult.propTypes = {
  model: PropTypes.object.isRequired
}
