import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Button from '../../Buttons/Button/Button';
import Notification from '../../Notification/Notification';
import styles from './ConfirmDialog.module.css';
import Form from '../../../../Forms/Form';
import { useRef } from 'react';

const defaultData = {
  title: "Confirm",
  rejectLabel: "Cancel",
  confirmLabel: "Confirm"
}

const ConfirmDialog = ({ data, className, onConfirm, onReject }) => {

  data = { ...defaultData, ...data };
  const remoteSubmit = useRef();
  const [inTimer, setInTimer] = useState(false);
  const hasForm = typeof data.form !== 'undefined';

  const formSubmit = (data) => {
    if (typeof onConfirm === 'function') {
      onConfirm(data);
    }
  }

  const submit = () => {

    if (!hasForm) {
      if (typeof onConfirm === 'function') {
        onConfirm();
      }
    } else {
      remoteSubmit.current();
    }

  }

  useEffect(() => {
    setTimeout(() => {
      setInTimer(true);
    }, 200);
  }, []);

  if (hasForm) {
    data.form.onSubmit = formSubmit;
  }

  return <CSSTransition in={inTimer} timeout={200} classNames="slew-up">
    <div className={`${styles.confirmDialog} panel-strong ${className}`}>
      <div className={styles.inner}>
        {data.title && <h3 className={styles.title}>{data.title}</h3>}
        {data.message && <div className={styles.message}>{data.message}</div>}
        {data.notificationText && <Notification label={data.notificationText} mode="warning" className={styles.confirmDialogNotification} />}
        {hasForm && <Form model={data.form} remoteSubmit={remoteSubmit} />}
        <div className={styles.actions}>
          <Button skinny tertiary noArrow onClick={onReject}>{data.rejectLabel}</Button>
          {typeof onConfirm === 'function' &&
            <Button skinny secondary noArrow onClick={submit}>{data.confirmLabel}</Button>}
        </div>
      </div>
    </div>
  </CSSTransition>
};

export default ConfirmDialog;

ConfirmDialog.propTypes = {

}