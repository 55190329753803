import MembershipUserModel from "../MembershipUserModel";

class MembershipSummaryListItemModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.MembershipNumber = params.membershipNumber;
        this.TypeName = params.typeName;
        this.StartDate = params.startDate;
        this.ExpiryDate = params.expiryDate;
        this.User = new MembershipUserModel(params.user);

    }

}

export default MembershipSummaryListItemModel;