import ApiResponseBase from '../../../../../Base/Domain/ApiResponseBase';
import PartnerModel from '../PartnerModel';

class PartnerListResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.PartnerList = params.partnerList?.map(a => new PartnerModel(a)) || null;

  }

}

export default PartnerListResponseModel;