import ApiResponseBase from '../../../../../Base/Domain/ApiResponseBase';
import AccountDetailsModel from '../AccountDetailsModel';

class AdminUpdateBasicDetailsResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.AccountDetails = new AccountDetailsModel(params.accountDetails);

  }

}

export default AdminUpdateBasicDetailsResponseModel;