import React from "react";
import PropTypes from 'prop-types';

const _RenderComponent = ({ component: Component }) => {
    return <Component />
}

export default _RenderComponent;

_RenderComponent.propTypes = {
    component: PropTypes.any.isRequired
}