import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Create.module.css';
import _adminPartnerAccountServicesApiService from '../../../Application/ApiServices/Partner/AdminPartnerServices/Services/PartnerAccountServicesApiService';
import _membershipServices from '../../../Application/ApiServices/Membership/MembershipAccountServices/Services/MembershipAccountServicesApiService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import StepScreen from '../../Global/StepScreen/StepScreen';
import StepScreenSection from '../../Global/StepScreen/StepScreenSection';
import TierSelector from './Components/TierSelector/TierSelector';
import Confirm from './Components/Confirm/Confirm';
import UserSelector from './Components/UserSelector/UserSelector';
import PaymentDetails from './Components/PaymentDetails/PaymentDetails';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import NewMembership from './Components/NewMembership/NewMembership';
import CustomLoader from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';

let stepsData = {
  numbered: true,
  initialActiveStepId: 'user',
  submit: (data) => {

  },
  steps: {
    user: {
      order: 0,
      label: "User",
      mandatory: true,
      next: {
        label: "Select plan tier"
      },
      onNavigateAway: (ar) => {
        // todo, is a sample method
        return true;
      }
    },
    tier: {
      order: 1,
      label: "Plan Tier",
      mandatory: true,
      next: {
        label: "Confirm"
      },
    },
    confirm:
    {
      mandatory: true,
      order: 2,
      label: "Confirm & Create"
    }
  }
}

// payment: {
//   order: 2,
//   mandatory: false,
//   label: "Payment Details",
//   next: {
//     label: "Confirm & Create"
//   },
// },

const Create = () => {

  const [newMembership, setNewMembership] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);

  // override submit
  stepsData.submit = (data) => {

    let dto = {
      typeId: data.tier.saveData?._single.value,
      accountNumber: data.user.saveData?._single.value,
      startDate: data.confirm.saveData?._single?.startDate?.value,
      paymentPlanType: data.confirm.saveData?._single?.paymentPlanType?.value
    }

    setIsPendingData(true);

    _membershipServices.create(dto).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Created) {
        setNewMembership(response);
        toast(<Notification label="Membership created" mode="success" />)
      } else {
        toast(<Notification label="Unable to create membership" mode="error" />)
      }

      setIsPendingData(false);

    });

  }

  return <div className={styles.create}>
    <h3>Create new membership</h3>
    {newMembership === null ?
      <StepScreen data={stepsData} name="Create membership plan" className={styles.creator}>
        <StepScreenSection key="user">
          <UserSelector data={stepsData.steps.user} />
        </StepScreenSection>
        <StepScreenSection key="tier">
          <TierSelector data={stepsData.steps.tier} />
        </StepScreenSection>
        {/* <StepScreenSection key="payment">
          <PaymentDetails data={stepsData.steps.payment} />
        </StepScreenSection> */}
        <StepScreenSection key="confirm">
          <Confirm data={stepsData.steps.confirm} summary={stepsData.steps} submit={stepsData.submit} />
        </StepScreenSection>
      </StepScreen>
      :
      <NewMembership data={newMembership.Membership} />}

    {isPendingData &&
      <CustomLoader label={"Creating membership"} pending={isPendingData} className={styles.loader} />}
  </div>
};

export default Create;

Create.propTypes = {

}
