import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import GetMembershipTypesResponseModel from '../Models/Get/GetMembershipTypesResponseModel';

const API_ENDPOINT = process.env.REACT_APP_MEMBERSHIP_ACCOUNTSERVICES_API_HOST;

class MembershipTypesServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
  }

  /**
   * @method get
   * @type {GET}
   * @description as an admin user, get membership types
   * 
   * @returns {Task} [MembershipType]
   */
  get = async () => this._restService.get(null, `${RESOURCE}${ACTIONS.Get}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipTypesResponseModel(result));

  // get = async (id) => this._restService.get(null, `${RESOURCE}${ACTIONS.Get}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipTypesResponseModel(result));

  // /**
  //  * @method update
  //  * @type {PUT}
  //  * @description as an admin user, update user account basic details
  //  * 
  //  * @param {object} model AdminUpdatePartnerDetailsRequestModel request model
  //  * @param {string} id resource id (user account number)
  //  * @returns {Task} 
  //  */
  // update = async (model, id) => {
  //   delete this._restService.api.headers[RESPONSE_HEADERS.ContentType]; // is form, browser decides content type from model base type (FormData)
  //   return this._restService.update(new UpdatePartnerDetailsRequestModel(model), false, `${RESOURCE}${ACTIONS.Update}/${id}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new UpdatePartnerDetailsResponseModel(result))
  // };

  // /**
  //  * @method create
  //  * @type {POST}
  //  * @description as an admin user, update user account basic details
  //  * 
  //  * @param {object} model CreateBasicDetailsRequestModel request model 
  //  * @returns {Task} 
  //  */
  // create = async (model) => {
  //   delete this._restService.api.headers[RESPONSE_HEADERS.ContentType]; // is form, browser decides content type from model base type (FormData)
  //   return this._restService.post(new CreatePartnerDetailsRequestModel(model), `${RESOURCE}${ACTIONS.Create}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new CreatePartnerDetailsResponseModel(result))
  // };

}

export default new MembershipTypesServicesApiService();