import ApiResponseBase from '../../../../Base/Domain/ApiResponseBase';
import RedemptionDetailsModel from '../RedemptionDetailsModel';

class GetRedemptionsResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.Redemptions = params.redemptions?.map(m => new RedemptionDetailsModel(m));

  }

}

export default GetRedemptionsResponseModel;