/**
  * @implements a login request model
  *
  */
class LoginRequestModel {

  constructor(params) {

    if (typeof params === 'undefined') {
      return;
    }

    this.Email = params.Email || null;
    this.Password = params.Password || null;

  }

}

export default LoginRequestModel;