import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES } from '../../../../../_Domain/Api/Constants';
import BaseApiService from '../../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../../Base/Domain/ApiResponseBase';
import CountyListRequestModel from '../Models/Get/CountyListRequestModel';
import CountyListResponseModel from '../Models/Get/CountyListResponseModel';

const API_ENDPOINT = process.env.REACT_APP_UTILITY_USERINTERFACESERVICES_API_HOST;

class CountyServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method get
   * @type {GET}
   * @description gets list of counties
   * 
   * @param {object} model CountyList request model
   * @returns {Task} CountyList
   */
  get = async (model) => this._restService.get(new CountyListRequestModel(model), `${RESOURCE}${ACTIONS.Get}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new CountyListResponseModel(result));

}

export default new CountyServicesApiService();