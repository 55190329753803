import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Membership.module.css';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import _membershipsMemberServicesApiService from '../../../../Application/ApiServices/Membership/MembershipMemberServices/Services/MembershipMemberServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../Foundation/Rest/Constants';
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { toast } from 'react-toastify';
import Notification from '../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import RedemptionList from '../../../Redemptions/Shared/RedemptionList/RedemptionList';
import { ReactComponent as MembershipIcon } from '../../../../Foundation/Theming/Icons/Navigation/memberships.svg';
import { getMembershipData, clearMembershipData } from '../../../../Foundation/Store/Actions/Membership/membershipActions';
import { connect, useDispatch } from 'react-redux';
import MembershipHeader from './MembershipHeader/MembershipHeader';
import MembershipFooter from './MembershipFooter/MembershipFooter';

const FAILED_MESSAGE = "Unable to retrieve membership details";

const Membership = ({ membership }) => {

  const [isPendingData, setIsPendingData] = useState(false);
  const [failed, setFailed] = useState(false);
  const loadMessage = failed ? FAILED_MESSAGE : 'Success';
  const dispatch = useDispatch();
  const routeParams = useParams();
  const membershipNumber = routeParams.membershipNumber;

  useEffect(() => {

    if (typeof membershipNumber === 'undefined' || membershipNumber === null) {
      return null;
    }

    dispatch(clearMembershipData());

    setIsPendingData(true);

    dispatch(getMembershipData(membershipNumber)).then((response) => {
      if (response.apiStatus !== HTTP_STATUS_CODES.Success) {
        toast(<Notification label={FAILED_MESSAGE} mode="error" />)
        setFailed(true);
      }
      setIsPendingData(false);
    });

  }, [membershipNumber]);

  const membershipData = membership;

  return <div className={`${styles.membership}`}>
    {membershipData !== null && Object.entries(membershipData).length !== 0 ?
      <div className={`${styles.membershipSummary} ${membershipData.Expired && styles.membershipExpired}`}>
        <MembershipHeader data={membership} />
        <div className={`${styles.stats}`}>
          <div className={`${styles.remainingCredits} ${styles.field}`}>
            <div className={styles.value}>{membershipData.RedemptionCount}</div>
            <div className={styles.label}>Redemptions</div>
          </div>
          <div className={styles.credits}>
            <div className={styles.subSectionTitle}>
              Credits
            </div>
            <div className={styles.subSectionBody}>
              <div className={`${styles.totalCredits} ${styles.field}`}>
                <div className={styles.value}>{membershipData.Credits}</div>
                <div className={styles.label}>Total</div>
              </div>
              <div className={`${styles.redeemedCredits} ${styles.field}`}>
                <div className={styles.value}>{membershipData.CreditsRedeemed}</div>
                <div className={styles.label}>Redeemed</div>
              </div>
              <div className={`${styles.remainingCredits} ${styles.field}`}>
                <div className={styles.value}>{membershipData.CreditsRemaining}</div>
                <div className={styles.label}>Available</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.stats}`}>
          <div className={`${styles.totalSaving} ${styles.field}`}>
            <div className={styles.value}>£{membershipData.TotalSavings}</div>
            <div className={styles.label}>Member Savings</div>
          </div>
          <div className={`${styles.totalCost} ${styles.field}`}>
            <div className={styles.value}>£{membershipData.TotalCost}</div>
            <div className={styles.label}>Tee Up Cost</div>
          </div>
        </div>
        <MembershipFooter data={membership} />
      </div>
      :
      <CustomLoader label='Fetching membership details' pending={isPendingData} failed={failed} message={loadMessage} className={styles.loader} />}
    <RedemptionList membershipNumber={membershipNumber} locked={membership?.Locked} />
  </div>

};

function mapStateToProps(state) {
  return {
    membership: state.membership
  }
}

export default connect(mapStateToProps)(Membership);

Membership.propTypes = {

}