import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import Arrow from '../../../Foundation/Helpers/Components/Feature/Arrow/Arrow';
import { ReactComponent as AlertIcon } from '../../../Foundation/Theming/Icons/Core/alert.svg';

import styles from './Header.module.css';
import Profile from './Profile/Profile';
import AppHeaderHomeLink from '../../../Foundation/Helpers/Components/Feature/Common/AppHeaderHomeLink/AppHeaderHomeLink';

const Header = ({ offline }) => {

  return <header className={`${styles.appHeader} ${offline ? 'app-offline' : ''}`}>
    <div className={styles.hgroup}>
    <AppHeaderHomeLink className={styles.homeIconLink} />
    <h1 className={styles.mainTitle}>Tee Up Admin Portal <sub className={styles.beta}>beta</sub></h1>
    </div>
    <Profile />
    <CSSTransition in={offline} unmountOnExit={true} classNames="animate-slide-in" timeout={{
      enter: 250,
      exit: 250
    }}>
      <div className="offline-mode-indicator">
        <div className="offline-mode-indicator__icon">
          <AlertIcon />
        </div>
        <div className="offline-mode-indicator__label">
          Offline
        </div>
      </div>
    </CSSTransition>
  </header>;

};

function mapStateToProps(state) {
  return {
    offline: state.global.offline
  }
}

export default connect(mapStateToProps)(Header);

Header.propTypes = {
  explorerActive: PropTypes.bool,
  toggleExplorerActive: PropTypes.func
}
