import { LOGIN_SUCCESS, LOGOUT_SUCCESS, LOGIN_STATE_COMPLETE, LOGIN_STATE_FAILED_COMPLETE, LOGIN_ERROR, LOGOUT_ERROR } from './actionTypes';
import { setAuthenticationTokenInService } from '../actionsHelper';
import _authenticationApiService from '../../../../Application/ApiServices/Identity/Authentication/Services/AuthenticationApiService';
import { LocalStorageHelper } from '../../../../Utility/LocalStorageHelper';
import { CookieStorageHelper } from '../../../../Utility/CookieStorageHelper';
import { AUTHENTICATION_STATE_KEY, BEARER_COOKIE_KEY } from '../../../Config/constants';
import { getUserAccountData } from '../User/userActions';
import { registerRequestsEvents } from '../Requests/requestsActions';

const _storageProvider = new LocalStorageHelper();
const _cookieProvider = new CookieStorageHelper();

const BEARER_COOKIE_CONFIG = {
    secure: true,
    path: "/"
};

const AUTHENTICATION_API_RESPONSE_CODES = {
    LoginSuccessful: 201,
    LogoutSuccessful: 204,
    RefreshSuccessful: 200
};

/* login */
export const loginUserSuccess = (login) => ({ type: LOGIN_SUCCESS, login })
export const loginUserError = (login) => ({ type: LOGIN_ERROR, login })

export function loginUser(data) {

    return (dispatch, getState) => {
        return _authenticationApiService.login(data).then(response => {
            if (response?.apiStatus === AUTHENTICATION_API_RESPONSE_CODES.LoginSuccessful) {
                let result = dispatch(loginUserSuccess(response));
                if (result?.login?.apiStatus === AUTHENTICATION_API_RESPONSE_CODES.LoginSuccessful) {
                    _storageProvider.set(AUTHENTICATION_STATE_KEY, getState()?.authentication);
                    _cookieProvider.write(BEARER_COOKIE_KEY, getState()?.authentication.accessToken, BEARER_COOKIE_CONFIG);
                    dispatch(getUserAccountData());
                    return result;
                }
            }
            return dispatch(loginUserError(response));
        });
    };
}

export const loginUserFromStateComplete = (login) => ({ type: LOGIN_STATE_COMPLETE, login });

export const loginUserFromStateFailedComplete = (login) => ({ type: LOGIN_STATE_FAILED_COMPLETE, login });

export function tryLoginUserFromSession() {

    return (dispatch) => {
        let stateData = _storageProvider.get(AUTHENTICATION_STATE_KEY);

        // if no state data, or has been logged out, or no refresh token present
        if (stateData === null || stateData?.isAuthenticated === false || typeof stateData?.refreshToken === 'undefined') {
            let result = new Promise((resolve, reject) => {
                resolve(dispatch(loginUserFromStateFailedComplete()));
            });

            return result;
        }

        // TODO here, this will call refresh on every page load (not client loads);
        // could try submit the existing token and the follow the on401 life if it fails? or store a last-logged in 
        // value and if over a threshold, do the refresh??!!! 
        return dispatch(refreshUserAuthentication(stateData)); // try to refresh the token
    }

}

// essentially identical to login user, as the response is the same
export function refreshUserAuthentication(data) {
    return (dispatch, getState) => {

        return _authenticationApiService.refresh(data).then(response => {

            if (response?.apiStatus === AUTHENTICATION_API_RESPONSE_CODES.RefreshSuccessful) {
                let result = dispatch(loginUserSuccess(response));
                if (result?.login?.apiStatus === AUTHENTICATION_API_RESPONSE_CODES.RefreshSuccessful) {
                    _storageProvider.set(AUTHENTICATION_STATE_KEY, getState()?.authentication); // add session token to local storage
                    _cookieProvider.write(BEARER_COOKIE_KEY, getState()?.authentication.accessToken, BEARER_COOKIE_CONFIG);
                    dispatch(getUserAccountData());
                    return result;
                }
            }
            _storageProvider.delete(AUTHENTICATION_STATE_KEY);
            _cookieProvider.erase(BEARER_COOKIE_KEY);
            return dispatch(loginUserError(response));
        });
    };
}

/* logout */
export const logoutUserSuccess = (logout) => ({ type: LOGOUT_SUCCESS, logout });
export const logoutUserError = (logout) => ({ type: LOGOUT_ERROR, logout });

export function logoutUser(data) {
    return (dispatch, getState) => {

        if (!setAuthenticationTokenInService(_authenticationApiService, getState()?.authentication)) {
            console.error("Authentication required for API method logout");
        }

        dispatch(registerRequestsEvents( { logout: true } ));

        return _authenticationApiService.logout(data).then(response => {
            _storageProvider.delete(AUTHENTICATION_STATE_KEY);
            _cookieProvider.erase(BEARER_COOKIE_KEY);
            if (response.apiStatus === AUTHENTICATION_API_RESPONSE_CODES.LogoutSuccessful) {
                dispatch(registerRequestsEvents( { logout: false } ));
                return dispatch(logoutUserSuccess(response))
            }
            dispatch(registerRequestsEvents( { logout: false } ));
            dispatch(logoutUserError(null));
            return null;
        });
    }
}
