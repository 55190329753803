class AccountSummaryRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return;
        }

    }

}

export default AccountSummaryRequestModel;