class AccountSummaryModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.AccountNumber = params.accountNumber;
        this.Email = params.email;
        this.FirstName = params.firstName;
        this.LastName = params.lastName;
        this.ProfileImageUri = [params.profileImageUri];

        if(typeof params.createdAt !== 'undefined'){
            this.CreatedAt = new Date(params.createdAt)?.toDateString()?.replace(/^\S+\s/,''); // todo, friendly date
        }

    }

}

export default AccountSummaryModel;