import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import GetMembershipsRequestModel from '../Models/Membership/Get/GetMembershipsRequestModel';
import GetMembershipsResponseModel from '../Models/Membership/Get/GetMembershipsResponseModel';
import GetMembershipResponseModel from '../Models/Membership/Get/GetMembershipResponseModel';
import GetMembershipWithDetailResponseModel from '../Models/Membership/Get/GetMembershipWithDetailResponseModel';
import AdjustCreditBalanceRequestModel from '../Models/AdjustCreditBalance/Post/AdjustCreditBalanceRequestModel';
import AdjustCreditBalanceResponseModel from '../Models/AdjustCreditBalance/Post/AdjustCreditBalanceResponseModel';
import GetMembershipPaymentDetailResponseModel from '../Models/Membership/Get/GetMembershipPaymentDetailResponseModel';

const API_ENDPOINT = process.env.REACT_APP_MEMBERSHIP_MEMBERSERVICES_API_HOST;

class MembershipMemberServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method get
   * @type {GET}
   * @description as an admin user, get memberships by account number
   * 
   * @param {object} accountNumber
   * @returns {Task} [Memberships] for account number
   */
  get = async (accountNumber) => {
    return this._restService.get(null, `${RESOURCE}${ACTIONS.GetByAccountNumber}/${accountNumber}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipsResponseModel(result));
  };

  /**
   * @method getByMembershipNumber
   * @type {GET}
   * @description as an admin user, get memberships by membership number
   * 
   * @param {object} membershipNumber
   * @returns {Task} Memberships for membership number
   */
  getByMembershipNumber = async (membershipNumber) => {
    return this._restService.get(null, `${RESOURCE}${ACTIONS.GetByMembershipNumber}/${membershipNumber}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipResponseModel(result));
  };

  /**
   * @method getWithDetailByMembershipNumber
   * @type {GET}
   * @description as an admin user, get memberships by membership number with detail
   * 
   * @param {object} membershipNumber
   * @returns {Task} Memberships for membership number
   */
  getWithDetailByMembershipNumber = async (membershipNumber) => {
    return this._restService.get(null, `${RESOURCE}${ACTIONS.GetWithDetailByMembershipNumber}/${membershipNumber}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipWithDetailResponseModel(result));
  };

  /**
 * @method getPaymentDetailByMembershipNumber
 * @type {GET}
 * @description as an admin user, get membership payment details by membership number
 * 
 * @param {object} membershipNumber
 * @returns {Task} Memberships for membership number
 */
  getPaymentDetailByMembershipNumber = async (membershipNumber) => {
    return this._restService.get(null, `${RESOURCE}${ACTIONS.GetPaymentDetailByMembershipNumber}/${membershipNumber}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetMembershipPaymentDetailResponseModel(result));
  };

  /**
 * @method adjustCreditBalance
 * @type {POST}
 * @description as an admin user, adjust credit balance for a membership
 * 
 * @param {object} membershipNumber
 * @returns {Task} Memberships for membership number
 */
  adjustCreditBalance = async (data) => {
    return this._restService.post(new AdjustCreditBalanceRequestModel(data), `${RESOURCE}${ACTIONS.AdjustCreditBalance}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new AdjustCreditBalanceResponseModel(result));
  };

}

export default new MembershipMemberServicesApiService();