const LOCALE = 'en-GB';
const dateHelper = {
    nth: (d) => {
        if (d > 3 && d < 21) {
            return 'th';
        }
        switch (d % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    },
    month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
};

export const toDateHtmlString = (dateString) => {

    if(dateString === null){
        return null;
    }

    let dateObj = new Date(dateString),
        dateValue = dateObj.getDate();

    return `${dateValue}<sup>${dateHelper.nth(dateValue)}</sup> ${dateHelper.month[dateObj.getMonth()]}, <span className='date-year'>${dateObj.getFullYear()}</span>`;
}

export const toDateTimeHtmlString = (dateTimeString) => {

    let dateObj = new Date(dateTimeString),
        dateValue = dateObj.getDate(),
        time = dateObj.toLocaleTimeString(LOCALE, {
            hour: '2-digit',
            minute: '2-digit'
        });

    return `<span className='date-time'>${time}</span> - ${dateValue}<sup>${dateHelper.nth(dateValue)}</sup> ${dateHelper.month[dateObj.getMonth()]}, <span className='date-year'>${dateObj.getFullYear()}</span>`;
}