import MembershipModel from "./MembershipModels/MembershipModel";

class RedemptionRequestDetailsModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.BookingRequestDateTime = params.bookingRequestDateTime;
        this.SlotDateTime = params.slotDateTime;
        this.PartnerId = params.partnerId;
        this.CreditsPerMember = params.creditsPerMember;
        this.ValuePerMember = params.valuePerMember;
        this.ReferenceId = params.referenceId;
        this.Confirmed = params.confirmed;
        this.Cancelled = params.cancelled;
        this.PartnerSummary = params.partnerSummary;
        this.PrimaryMembership = new MembershipModel(params.primaryMembership);
        this.GuestMemberships = params.guestMemberships.map(a => new MembershipModel(a));
    }

}

export default RedemptionRequestDetailsModel;