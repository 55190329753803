import React, { useEffect, useState, useRef } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { ReactComponent as UsersIcon } from '../../../Foundation/Theming/Icons/Navigation/users.svg';
import { ReactComponent as CoursesIcon } from '../../../Foundation/Theming/Icons/Navigation/courses.svg';
import { ReactComponent as MembershipsIcon } from '../../../Foundation/Theming/Icons/Navigation/memberships.svg';
import { ReactComponent as ConfigIcon } from '../../../Foundation/Theming/Icons/Navigation/config.svg';
import _RenderComponent from '../../../Foundation/Helpers/Components/_Utility/_RenderComponent';
import styles from './Navigator.module.css';
import AppHeaderHomeLink from '../../../Foundation/Helpers/Components/Feature/Common/AppHeaderHomeLink/AppHeaderHomeLink';

const navigationData = {
  Users: {
    id: "users",
    label: "Users",
    uri: '/users',
    icon: UsersIcon
  },
  Memberships: {
    id: "memberships",
    uri: '/memberships',
    label: "Memberships",
    icon: MembershipsIcon
  },
  Courses: {
    id: "courses",
    uri: '/courses',
    label: "Courses",
    icon: CoursesIcon
  },
  System: {
    id: "system",
    uri: '/system',
    label: "System",
    icon: ConfigIcon
  }
}

const NavigationItem = ({ item }) => {

  let resolved = useResolvedPath(item.uri),
    match = useMatch({ path: resolved.pathname, end: false });

  return <li className={`${styles.navigationItem} ${match ? styles.navigationItemActive : ''}`}>
    <Link to={item.uri} className={styles.navigationItemLink}>
      <_RenderComponent component={item.icon} />
      <div className={styles.label}>
        {item.label}
      </div>
    </Link>
  </li>

}

const Navigator = ({ }) => {

  return <aside className={`${styles.appNav}`}>
    <AppHeaderHomeLink className={styles.asideHomeLink} />
    <nav className={`${styles.appNavExplorer}`}>
      <ul>
        {Object.keys(navigationData).map((key, i) => <NavigationItem key={`${navigationData[key]?.id}_${i}_explorer`} item={navigationData[key]} />)}
      </ul>
    </nav>
  </aside>

};

export default Navigator;