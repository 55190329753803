import MembershipRedemptionSummaryModel from "./MembershipRedemptionSummaryModel";

class RedemptionDetailsModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.BookingDateTime = params.bookingDateTime;
        this.SlotDateTime = params.slotDateTime;
        this.PartnerName = params.partnerName;
        this.ReferenceId = params.referenceId;
        this.Credits = params.credits;
        this.TotalValue = params.totalValue;
        this.Cost = params.cost;

        if (params.membershipRedemptions?.length > 0) {
            this.MembershipRedemptions = params.membershipRedemptions.map(m => new MembershipRedemptionSummaryModel(m));
        }
    }

}

export default RedemptionDetailsModel;