import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Manage.module.css';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import _adminPartnerAccountServicesApiService from '../../../Application/ApiServices/Partner/AdminPartnerServices/Services/PartnerAccountServicesApiService';
import 'react-toastify/dist/ReactToastify.min.css';
import ManageNavigation from '../../Global/ManageNavigation/ManageNavigation';
import EntityDetails from './EntityDetails/EntityDetails';
import { toast } from 'react-toastify';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import CustomLoader from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';

const navigationData = {

  Summary: {
    id: "summary",
    uri: '',
    label: "Summary",
    end: false
  },
  Details: {
    id: "details",
    uri: 'details',
    label: "Details"
  }
}

const Manage = ({ }) => {

  const routeParams = useParams();
  const accountNumber = routeParams.id;
  const [partnerDataApiResponse, setPartnerDataApiResponse] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);

  useEffect(() => {
    setIsPendingData(true);
    _adminPartnerAccountServicesApiService.getSummary(accountNumber).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        setPartnerDataApiResponse(response);
      } else {
        toast(<Notification label="Error retrieving course information" mode="error" />)
      }

      setIsPendingData(false);
    });

  }, [accountNumber]);

  return <div className={styles.manage}>
    <div className={styles.sectionHeader}>
      <div className={`${styles.entityDetails} panel-strong`}>
          <EntityDetails data={partnerDataApiResponse?.AccountSummary} loading={isPendingData} />
      </div>
      <div className={`${styles.nav} panel-strong`}>
        <ManageNavigation data={navigationData} />
      </div>
    </div>
    <div className={styles.detail}>
      <Outlet />
    </div>
  </div>

};



export default Manage;

Manage.propTypes = {

}
