/**
 * @fileoverview helper methods for jsonPatchDocuments
 * @class JsonPatchHelper
 */

import JsonPatchDocument from './JsonPatchDocument';

export default class JsonPatchHelper {

    static #_operation = "replace";

    /**
     * @static @method JsonPatchHelper
     */
    static ToJsonPatchDocument(model) {

        let result = [];

        for (let member in model) {
            let patchEntity = new JsonPatchDocument(this.#_operation, member, model[member]);
            result.push(patchEntity);
        }
        return result;

    }

}