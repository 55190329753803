import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PartnerListingResult.module.css';
import LinkButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import Pill from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';
import { ReactComponent as PartnersIcon } from '../../../../Foundation/Theming/Icons/Navigation/courses.svg';

const PartnerListingResult = ({ model }) => {
 
  return <div className={`${styles.partnerListingResult} ${!model.Published ? styles.unpublished : ''} ${model.Prestige ? styles.prestige : ''}`}>
    <div className={styles.information}>
      <div className={styles.headline}>
        <div className={styles.taxonomyIcon}>
          <PartnersIcon />
        </div>
        <div className={styles.name}>
          <div className={styles.header}>
            <div className={styles.partnerName}>{model.Name}</div>
            <Pill label={model.Published ? "Published" : "Unpublished"} className={`${styles.statusPill}`} />
          </div>
          <div className={styles.partnerId}>{model.PartnerId}</div>
        </div>
        <div className={styles.status}>
          {model.Prestige && <Pill label="Prestige" className={styles.statusPill} />}
        </div>
      </div>
      <div className={styles.extraDetails}>
        <div className={styles.location}>
        <div className={`${styles.county} label-tag`}>
          {model.CountyName}
        </div>
        <div className={`${styles.country} label-tag`}>
          {model.CountryName}
        </div>
        </div>
      </div>
    </div>
    <div className={styles.actions}>
      <LinkButton url={model.PartnerId} label="Manage" mode="small" arrowDirection='right'></LinkButton>
    </div>
  </div>

};

export default PartnerListingResult;

PartnerListingResult.propTypes = {
  model: PropTypes.object.isRequired
}
