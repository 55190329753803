import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styles from './Edit.module.css';
import formData from './UserAccountDetailsFormData.json';
import Form from '../../../Foundation/Helpers/Components/Forms/Form';
import CustomLoader from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import EditDetailsPanel from '../../Details/EditDetailsPanel/EditDetailsPanel';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import { flattenDropdownOption, mapFormViewModel, mapNameIdPairToSelectOptions } from '../../../Foundation/Helpers/Components/Forms/Utility/formHelpers';
import _adminAccountServicesApiService from '../../../Application/ApiServices/User/AdminAccountServices/Services/AdminAccountServicesApiService';
import GenericError from '../../../Foundation/Helpers/Components/Feature/Common/Error/GenericError';

import 'react-toastify/dist/ReactToastify.min.css';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import { getCounties } from '../../../Foundation/Store/Actions/Utility/utilityActions';

const COUNTY_ID_KEY = 'CountyId',
  PROFILE_IMAGE_URI_KEY = 'ProfileImage',
  DATA_API_PROPERTIES_KEY = 'AccountDetails';

const Details = () => {

  const auth = useSelector((state) => state.authentication);
  const counties = useSelector((state) => state.utility.counties);
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [validationErrorData, setValidationErrorData] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [isPendingUserAccountData, setIsPendingUserAccountData] = useState(false);
  const [countiesList, setCountiesList] = useState(null);
  const [userDataApiReponse, setUserDataApiResponse] = useState(null);

  // remote form controls (outside of component) and state
  const remoteEditToggle = useRef();
  const remoteCancelEdit = useRef();
  const remoteSubmit = useRef();
  const [isEditMode, setIsEditMode] = useState(false)
  const routeParams = useParams();
  const accountNumber = routeParams.id;

  const buildFormViewModel = (apiData) => {

    let result = mapFormViewModel(formData, apiData, DATA_API_PROPERTIES_KEY)

    // counties
    for (let section in result.formViewModel.sections) {

      for (let field of result.formViewModel.sections[section].fields) {
        if (apiData[DATA_API_PROPERTIES_KEY]?.hasOwnProperty(field.name)) {
          let entity = apiData[DATA_API_PROPERTIES_KEY][field.name];
          if (field.name === COUNTY_ID_KEY) {
            let counties = mapNameIdPairToSelectOptions(countiesList);
            field.options = counties;
            field.displayValue = counties.find(x => x.value === entity)?.label;
          }
        }

        if (field.name === PROFILE_IMAGE_URI_KEY) {
          field.defaultValue = field.displayValue = apiData[DATA_API_PROPERTIES_KEY]?.ProfileImageUri !== null ? apiData[DATA_API_PROPERTIES_KEY]?.ProfileImageUri : [];
        }
      }
    }

    return result;
  }

  const submitUpdate = (submitData) => {

    if (typeof auth.accessToken !== 'undefined') {

      setIsPendingData(true);

      _adminAccountServicesApiService.update(submitData, accountNumber).then((response) => {

        // server validation error
        if (response.apiStatus === HTTP_STATUS_CODES.BadRequest) {

          let sentDataThatErrored = { ...data, ...response }
          flattenDropdownOption(sentDataThatErrored, submitData, DATA_API_PROPERTIES_KEY, COUNTY_ID_KEY);
          setValidationErrorData(response.errors);
          setData(buildFormViewModel(sentDataThatErrored));
          toast(<Notification label="There were some errors" mode="information" />)

        } else {
          // success
          setValidationErrorData(null);
          setData(buildFormViewModel(response));
          setUserDataApiResponse(response)
          toast(<Notification label="User details updated" mode="success" />)
        }

        setIsPendingData(false);
      })
    }
  }

  useEffect(() => {

    // only when both of these two have returned (async), can the form be built
    if (userDataApiReponse !== null && userDataApiReponse?.apiStatus === HTTP_STATUS_CODES.Success && countiesList !== null) {
      setData(buildFormViewModel(userDataApiReponse));
    }

    if (userDataApiReponse !== null && countiesList !== null) {
      setIsPendingData(false);
    }

  }, [userDataApiReponse, countiesList]);

  useEffect(() => {

    if (typeof auth?.accessToken !== 'undefined') {
      setIsPendingData(true);

      if (counties === null) {
        dispatch(getCounties());
      } else {
        setCountiesList(counties);
      }

      if (!isPendingUserAccountData) {
        setIsPendingUserAccountData(true);

        _adminAccountServicesApiService.get(accountNumber).then((response) => {
          setUserDataApiResponse(response);
          setIsPendingUserAccountData(false);
        });
      }

    }
  }, [counties]);

  formData.onSubmit = submitUpdate;

  return <div className={`${styles.edit} layout__sub-section`}>
    <div className={`${styles.panel} layout__side-panel`}>
      {userDataApiReponse?.apiStatus === HTTP_STATUS_CODES.Success &&
        <EditDetailsPanel isEditMode={isEditMode} editRef={remoteEditToggle} cancelRef={remoteCancelEdit} submitRef={remoteSubmit} submitLabel={formData.submit?.name} isPendingEdit={isPendingData}>
          <h3>Manage User Details</h3>
        </EditDetailsPanel>
      }
    </div>
    <div className={`layout__editor-panel ${styles.editor}`}>
      {isPendingData ? <CustomLoader label={"Loading"} pending={isPendingData} className={styles.loader} /> :
        userDataApiReponse?.apiStatus === HTTP_STATUS_CODES.Success ? <Form model={formData} editable={true} submissionResponseData={validationErrorData} remoteEditToggle={remoteEditToggle}
          remoteSubmit={remoteSubmit} remoteCancelEdit={remoteCancelEdit} remoteStateSetter={setIsEditMode} hideControls />
          : <GenericError title={"Error"} code={userDataApiReponse?.apiStatus}></GenericError>}
    </div>
  </div>

};

export default Details;

Details.propTypes = {

}
