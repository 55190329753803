class PartnerDetailsModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.PartnerId = params.partnerId;
        this.Name = params.name;
        this.Summary = params.summary;
        this.Information = params.information;
        this.PrimaryEmail = params.primaryEmail;
        this.Telephone = params.telephone;
        this.FirstLineAddress = params.firstLineAddress;
        this.SecondLineAddress = params.secondLineAddress;
        this.ThirdLineAddress = params.thirdLineAddress;
        this.PostCode = params.postCode;
        this.Tees = params.tees;
        this.Attributes = params.attributes;
        this.TerritoryName = params.territoryName;
        this.PartnerImageUris = params.partnerImageUris;
        this.CountyId = params.countyId;
        this.Length = params["length"];
        this.Holes = params.holes;
        this.Par = params.par;
        this.Type = params.type;
        this.HighSeasonWeekdayCredits = params.highSeasonWeekdayCredits;
        this.HighSeasonWeekendCredits = params.highSeasonWeekendCredits;
        this.OffSeasonWeekdayCredits = params.offSeasonWeekdayCredits;
        this.OffSeasonWeekendCredits = params.offSeasonWeekendCredits;
        this.HighSeasonWeekdayFee = params.highSeasonWeekdayFee;
        this.HighSeasonWeekendFee = params.highSeasonWeekendFee;
        this.OffSeasonWeekdayFee = params.offSeasonWeekdayFee;
        this.OffSeasonWeekendFee = params.offSeasonWeekendFee;
        this.HighSeasonMonths = params.highSeasonMonths;
        this.Prestige = params.prestige;
        this.Published = params.published;
        this.RedemptionCost = params.redemptionCost;
        this.MaxDaysFutureBooking = params.maxDaysFutureBooking;
        this.MondaySlotTimes = params.mondaySlotTimes;
        this.TuesdaySlotTimes = params.tuesdaySlotTimes;
        this.WednesdaySlotTimes = params.wednesdaySlotTimes;
        this.ThursdaySlotTimes = params.thursdaySlotTimes;
        this.FridaySlotTimes = params.fridaySlotTimes;
        this.SaturdaySlotTimes = params.saturdaySlotTimes;
        this.SundaySlotTimes = params.sundaySlotTimes;

        if (typeof params.createdAt !== 'undefined') {
            this.CreatedAt = new Date(params.createdAt)?.toDateString()?.replace(/^\S+\s/, '');
        }

    }

}

export default PartnerDetailsModel;