import ApiResponseBase from '../../../../Base/Domain/ApiResponseBase';
import PaymentDetailsModel from '../PaymentDetailsModel';

class CreatePaymentResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.Payment = new PaymentDetailsModel(params.payment);

  }

}

export default CreatePaymentResponseModel;