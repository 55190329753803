import React from 'react';
import PropTypes from 'prop-types';
import styles from './CustomLoader.module.css';
import Fade from 'react-reveal/Fade';

const CustomLoader = ({ label, pending, succeeded, failed, message, className = '' }) => {

  return <div className={`${styles.customLoader} ${className} custom-loader`}>
    <div className={`${styles.header} ${failed ? styles.customLoaderFailed : ''}`}>
      <div className={`${styles.status} custom-loader__roundel`}>
        <div className={`${styles.circleLoader} custom-loader__circle ${!pending ? styles.loadComplete : ''} ${failed ? styles.failed : ''} `}>
          {succeeded &&
            <div className={`${styles.checkmark} ${styles.checkmarkDraw}`}></div>}
            {failed && <div className={`${styles.crossmark} ${styles.crossmarkDraw}`}></div>}
        </div>
        
      </div>
    </div>
    {message &&
    <div className={`${styles.body} custom-loader__body`}>
      <Fade duration={500} spy={succeeded || failed}>
        <>
          <div className={`${styles.message} custom-loader__message`}>
            {succeeded || failed ? message : label}
          </div>
        </>
      </Fade>
    </div>}

  </div>;;

};

export default CustomLoader;

CustomLoader.propTypes = {
  label: PropTypes.string
}