import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import loginFormData from './LoginFormData.json';
import forgotPasswordFormData from './ForgotPasswordFormData.json';
import Form from '../../../Foundation/Helpers/Components/Forms/Form';
import { ReactComponent as HomeIcon } from '../../../Foundation/Theming/Icons/Brand/home.svg';
import styles from './LoginForm.module.css';

const LoginForm = ({ onLoginSubmit, onResetSubmit, className }) => {

    const [loginMode, setLoginMode] = useState(true);
    const [formData, setFormData] = useState();

    const toggleFormMode = () => {
        setLoginMode(!loginMode);
    }

    const onSubmit = (data) => {
        if (loginMode) {
            if (typeof onLoginSubmit === 'function') {
                onLoginSubmit(data);
            }
        } else {
            if (typeof onResetSubmit === 'function') {
                onResetSubmit(data);
            }
        }
    }

    useEffect(() => {
        setFormData({ ...(loginMode ? loginFormData : forgotPasswordFormData), ...{ onSubmit: onSubmit } });
    }, [loginMode]);

    return <div className={`${styles.loginForm} ${className}`}>
        <div className={styles.formHeader}>
            <div className={styles.logo}><HomeIcon /></div>
            <div className={styles.titles}>
                <h3 className={styles.loginFormTitle}>
                    {loginMode ? "Tee Up" : "Reset your password"}
                </h3>
                <div className={styles.subTitle}>
                    Administrator Login
                </div>
            </div>
        </div>
        <hr className={styles.sep} />
        <Form model={formData} />
        {/* <div className={styles.forgottenPassword}>
            <div onClick={toggleFormMode}>{loginMode ? "Forgotten password?" : "Login"}</div>
        </div> */}
    </div>;

};

export default LoginForm;

LoginForm.propTypes = {
    onLoginSubmit: PropTypes.func,
    onResetSubmit: PropTypes.func
}