import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../../Foundation/Helpers/Components/Feature/Common/SectionTitle/SectionTitle';
import SectionNavigator from '../Global/SectionNavigator/SectionNavigator';
import { Outlet } from 'react-router-dom';

const Redemptions = () => {

  return <div className='redemptions'>
    <Outlet />
  </div>;

};

export default Redemptions;

Redemptions.propTypes = {

}