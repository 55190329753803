import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TierSelector.module.css';
import Button from '../../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import _membershipTypesServicesApiService from '../../../../../Application/ApiServices/Membership/MembershipTypesServices/Services/MembershipTypesServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../../Foundation/Rest/Constants';
import { toast } from 'react-toastify';
import Notification from '../../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import CustomLoader from '../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';

const TierSelector = ({ data, shared, ui }) => {

    const [isPendingData, setIsPendingData] = useState(false);

    const onSelectItem = (selectedData) => {
        let persistData = Object.assign({}, { _single: { _label: `${selectedData.Name} (£${selectedData.Annual})`, value: selectedData.Id } });

        data.updateState(true, persistData);
        data.updateUiState(Object.assign(ui.tier || {}, { selectedItemId: selectedData.Id }));
    }

    const renderMembershipCard = (data, i) => {
        return <li key={`${data.Name}_${i}`}>
            <div className={`${styles.membershipCard} panel-strong`}>
                <div className={`${styles.membershipCardInner} ${ui.tier?.selectedItemId === data.Id ? styles.selected : ''}`}>
                    <div className={styles.details}>
                        <div className={styles.membershipName}>{data.Name}</div>
                        <div className={styles.membershipPrice}>£{data.Annual}</div>
                    </div>
                    <Button skinny onClick={() => onSelectItem(data)}>Select</Button>
                </div>
            </div>
        </li>
    }

    useEffect(() => {

        if (typeof ui.tier?.membershipTypes === 'undefined') {
            setIsPendingData(true);

            _membershipTypesServicesApiService.get().then((response) => {

                if (response.apiStatus === HTTP_STATUS_CODES.Success) {
                    data.updateUiState(Object.assign(ui.tier || {}, { membershipTypes: response.MembershipTypes }));
                } else {
                    toast(<Notification label="Unable to retrieve membership types" mode="error" />)
                }

                setIsPendingData(false);

            });
        }
    }, []);

    return <div className={styles.tierSelector}>
        <div className={styles.title}>
            <h3>Select a membership tier</h3>
        </div>

        <div className={styles.results}>
            {isPendingData ? <CustomLoader label={"Fetching available memberships"} pending={isPendingData} className={styles.loader} /> :
                typeof ui.tier?.membershipTypes !== 'undefined' && ui.tier?.membershipTypes !== null &&
                <ul className={styles.list}>
                    {ui.tier?.membershipTypes.map((a, i) => renderMembershipCard(a, i))}
                </ul>}
        </div>
    </div>;

};

export default TierSelector;

TierSelector.propTypes = {

}