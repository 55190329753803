import React from 'react';
import styles from './Checkmark.module.css';

const Checkmark = ({ className }) => {
  return <div className={`sys-checkmark ${className} ${styles.checkmark} ${styles.checkmarkDraw}`}></div>;
};

export default Checkmark;

Checkmark.propTypes = {

}