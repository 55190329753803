import ApiResponseBase from '../../../../Base/Domain/ApiResponseBase';
import RedemptionRequestDetailsModel from '../RedemptionRequestDetailsModel';

class GetRedemptionRequestResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.RedemptionRequest = new RedemptionRequestDetailsModel(params.redemptionRequest);

  }

}

export default GetRedemptionRequestResponseModel;