import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './BookingRequestListingResult.module.css';
import LinkButton from '../../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import Pill from '../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';
import parse from 'html-react-parser';
import { ReactComponent as BookingRequestIcon } from '../../../../../Foundation/Theming/Icons/Navigation/redemptionrequest.svg';
import { ReactComponent as OneGuestIcon } from '../../../../../Foundation/Theming/Icons/Core/oneguest.svg';
import { ReactComponent as TwoGuestIcon } from '../../../../../Foundation/Theming/Icons/Core/twoguest.svg';
import { ReactComponent as ThreeGuestIcon } from '../../../../../Foundation/Theming/Icons/Core/threeguest.svg';
import ProfileRoundel from '../../../../../Foundation/Helpers/Components/Feature/Common/ProfileRoundel/ProfileRoundel';

const guestIcon = {
  1: <OneGuestIcon />,
  2: <TwoGuestIcon />,
  3: <ThreeGuestIcon />,
}

const BookingRequestListingResult = ({ model }) => {

  return <div className={`${styles.bookingRequestListing}`}>
    <div className={styles.summaryContainer}>
      <div className={styles.taxonomyIcon}>
        <BookingRequestIcon />
      </div>
      <div className={styles.activeDisplay}>
        {!model.Cancelled && !model.Confirmed && <Pill label={"Pending"} className={styles.pending} />}
        {model.Cancelled && <Pill label={"Cancelled"} className={styles.cancelled} />}
        {model.Confirmed && <Pill label={"Confirmed"} className={styles.confirmed} />}
      </div>
    </div>
    <div className={styles.information}>
        <div className={styles.headline}>
          <div className={styles.profile}>
            <ProfileRoundel className={styles.roundel} imageUri={model.User.ProfileImageUri} data={[model.User.FirstName, model.User.LastName]} />
          </div>
          <div className={styles.details}>
            <div className={styles.number}>{model.MembershipNumber}</div>
            <div className={styles.name}>
              <div>{model.User.FullName}</div>
            </div>
          </div>
          <div className={styles.guestsCount}>
            + {guestIcon[model.NumberOfGuests]}
          </div>
        </div>
      <div className={`${styles.tags}`}>
        <div className={`${styles.tag} ${styles.slotDateTime}`}>
          <div className={`${styles.value} label-tag`}>
            {parse(model.SlotDateTime)}
          </div>
        </div>
        <div className={styles.tag}>
          <div className={styles.label}>
            at
          </div>
          <div className={`${styles.value} label-tag`}>
            {model.PartnerName}
          </div>
        </div>
      </div>
    </div>

    <div className={styles.right}>
      <div className={`${styles.bookingDateTime}`}>
      <div className={styles.label}>Requested:</div> {parse(model.BookingRequestDateTime)}
      </div>
      <div className={styles.actions}>
        <LinkButton url={model.ReferenceId} label="Manage" mode="small" arrowDirection='right'></LinkButton>
      </div>
    </div>
  </div>

};

export default BookingRequestListingResult;

BookingRequestListingResult.propTypes = {
  model: PropTypes.object.isRequired
}
