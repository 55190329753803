
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

// TODO the immutable state invariant must not be used in production! 

export default function configureStore(initialState) {

  // todo this fucks the loading and causes an additional call to store and overwrites and async data!

  const componseEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

  return createStore(
    rootReducer,
    initialState,
    // applyMiddleware(thunk))
    componseEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant())))
}