import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './StepScreen.module.css';
import Button from '../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import Checkmark from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Checkmark/Checkmark';
import Crossmark from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Crossmark/Crossmark';

// todo, document all settings and controls in here for this component

const StepScreen = ({ data, name, onSubmit, className, ...props }) => {

  const [activeStepId, setActiveStepId] = useState(data.initialActiveStepId);
  const [persistentStateData, setPersistentStateData] = useState({});
  const [persistentUiStateData, setPersistentUiStateData] = useState({});

  const updateIndividualPersistentDataSet = (id, newObject) => {
    setPersistentStateData({ ...persistentStateData, ...{ [id]: { ...newObject } } });
  }

  const getNextId = (thisIdx) => props.children[thisIdx + 1]?.key;

  // todo navigate, if the "onSelect" callback returns true, i.e. passes validation
  const navigate = (id) => {

    if (typeof id === 'undefined') {
      return;
    }

    let canNavigate = true;

    // mandatory attribute means the user cannot navigate away if section is not valid
    if (data.steps[activeStepId]?.mandatory) {
      if (persistentStateData[activeStepId]?.valid !== true) {
        canNavigate = false;
        let invalidAttribute = { ...persistentStateData[activeStepId], valid: false }
        updateIndividualPersistentDataSet(activeStepId, invalidAttribute);
        return;
      }
    }

    // custom event hooks on navigate away
    if (typeof data.steps[activeStepId]?.onNavigateAway === 'function') {
      canNavigate = data.steps[activeStepId]?.onNavigateAway(); // if this returns true, navigate to the selected id
    }

    // do navigate
    if (canNavigate) {
      setActiveStepId(id);
    }
  }

  // add callback hooks for cross concern data updating
  for (let stepId in data.steps) {

    /**
     * @prop {bool} valid will set the step screen state as valid
     * @prop {object} saveData { [id]: { _label: string, value: val }, [id]: { _label: string, value: val } } || { _single: { _label: string, value: val } }
     *                          if _single is the property name under saveData, only the label will be shown in the interface
     */
    data.steps[stepId].updateState = (valid, saveData) => {
      setPersistentStateData({ ...persistentStateData, ...{ [stepId]: { saveData, valid } } });
    }

    /**
     * @prop {object} uiData { }
     */
    data.steps[stepId].updateUiState = (uiData) => {
      setPersistentUiStateData({ ...persistentUiStateData, ...{ [stepId]: uiData } });
    }

    /**
     * 
     */
    data.steps[stepId].navigateNext = (fromIdx) => {
      navigate(getNextId(fromIdx));
    }
  }

  useEffect(() => {

    let persistentStateDataBase = {};

    // build persistent data storage container
    for (let stepId in data.steps) {

      persistentStateDataBase[stepId] = {};
    }

    setPersistentStateData(persistentStateDataBase);
    setPersistentUiStateData(persistentStateDataBase);

  }, []);

  const renderIndicator = (valid = null, i) => {
    return <div className={styles.indicator}>
      <div className={styles.outline}>
        {data.numbered && valid === null && <span className={styles.numberIncrement}>{i + 1}</span>}
        {valid === true && <Checkmark />}
        {valid === false && <Crossmark />}
      </div>
      <div className={styles.sep}></div>
    </div>
  }

  const renderControl = (control, key, i) => {

    return <div className={`${styles.control}
    ${key === activeStepId ? styles.controlActive : ''} 
    ${control.disabled ? styles.controlDisabled : ''}
    ${persistentStateData[key]?.valid === true ? styles.controlValid : ''}
    ${persistentStateData[key]?.valid === false ? styles.controlInvalid : ''}
    `}
      key={`${name}_control_${key}`}
      onClick={() => control.disabled ?? navigate(key)}>
      {renderIndicator(persistentStateData[key]?.valid, i)}
      <div className={styles.labelContainer}>
        <div className={styles.controlLabel}>{control.label}
          <span className={styles.controlStateFx}></span></div>
      </div>
    </div>

  }

  const renderControls = () => {
    return <div className={`${styles.controls} panel-strong`}>{Object.keys(data.steps).map((c, i) => renderControl(data.steps[c], c, i))}</div>
  }

  const renderSelections = () => {

    return <div className={styles.selections}>
      NOT IMPLEMENTED
    </div>

  }

  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, { shared: persistentStateData, ui: persistentUiStateData }); // there is a react bug here, sometimes the name of the custom props object causes a warning
  });

  const activeStep = children.filter(item => item.key === `.$${activeStepId}`);

  return <div className="step-screen">
    {typeof data.steps !== 'undefined' && props.children && typeof data.steps[activeStepId] !== 'undefined' &&
      <>
        {renderControls()}
        {/* {data.selectionDisplay === true && renderSelections()} */}
        <div className={`${styles.steps} ${className}`}>
          <div className={`${styles.step} step__section__${activeStepId} step__section
          ${persistentStateData[activeStepId]?.valid === true ? `${styles.valid} step__section--valid` : ''}
          ${persistentStateData[activeStepId]?.valid === false ? `${styles.invalid} step__section--invalid` : ''} `}>
            {data.steps[activeStepId].next && data.steps[activeStepId].next?.top &&
              <div className={`${styles.actions} step-screen__actions`}>
                <Button onClick={() => navigate(getNextId(data.steps[activeStepId].order))}>{data.steps[activeStepId].next.label}</Button>
              </div>}
            {activeStep}
            {data.steps[activeStepId].next && !data.steps[activeStepId].next?.top && <div className={`${styles.actions} step-screen__actions`}>
              <Button onClick={() => navigate(getNextId(data.steps[activeStepId].order))}>{data.steps[activeStepId].next.label}</Button>
            </div>
            }
          </div>
        </div>
      </>
    }

  </div>;

};

export default StepScreen;

StepScreen.propTypes = {
  data: PropTypes.object.isRequired,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  className: PropTypes.string
}