import React, { useEffect, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from 'prop-types';
import { CLASS_MODIFIERS } from '../../Utility/_formsConfig';
import FieldBase from '../Base/FieldBase';
import { Controller } from 'react-hook-form';
import styles from './RichText.module.css';

const RichText = ({ name, label, defaultValue, errors, alive, incrementor, control, validation = {} }) => {

  const error = typeof errors !== 'undefined';

  return <FieldBase className={styles.richText} {...{ errors, alive, ['classModifier']: CLASS_MODIFIERS.RichText, label, incrementor }}>
    <Controller
    
      name={name}
      control={control}
      rules={validation}
      defaultValue={defaultValue}
      render={({ field }) => <WYSIWYGEditor {...field} ref={null} />}
    />
  </FieldBase>;

};

export default RichText;

RichText.propTypes = {

}

const toolbarOptions = {
  options: ['inline', 'list', 'textAlign', 'link']
};

const WYSIWYGEditor = ({ onChange, value }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : "";
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value]);

  const onEditorStateChange = (editorState) => {
    setUpdated(true);
    setEditorState(editorState);

    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return <Editor
    toolbar={toolbarOptions}
    spellCheck
    editorState={editorState}
    onEditorStateChange={onEditorStateChange}
  />
};