import React from 'react';
import PropTypes from 'prop-types';
import FieldBase from '../Base/FieldBase';
import { CLASS_MODIFIERS } from '../../Utility/_formsConfig';

const TextInput = ({ name, register, variant, label, readOnly, placeholder, defaultValue, maxLength, errors, events, alive, incrementor, validation = {} }) => {

  return <FieldBase {...{errors, alive, ['classModifier']: CLASS_MODIFIERS.TextInput, label, incrementor}}>
    <input type={variant || 'text'}
      className="input-field"
      readOnly={readOnly}
      placeholder={placeholder}
      {...register(name, validation)}
      defaultValue={defaultValue}
      maxLength={maxLength} />
  </FieldBase>
};

export default TextInput;

TextInput.propTypes = {

}