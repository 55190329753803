import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import GetPaymentsResponseModel from '../Models/Get/GetPaymentsResponseModel';
import CreatePaymentRequestModel from '../Models/Create/CreatePaymentRequestModel';
import CreatePaymentResponseModel from '../Models/Create/CreatePaymentResponseModel';
import GetPaymentResponseModel from '../Models/Get/GetPaymentResponseModel';
import UpdatePaymentRequestModel from '../Models/Update/UpdatePaymentRequestModel';

const API_ENDPOINT = process.env.REACT_APP_PAYMENT_MEMBERSERVICES_API_HOST;

class PaymentServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method getByReferenceId
   * @type {GET}
   * @description as an admin user, gets payment by reference id
   * 
   * @param {string} referenceId resource id (reference id)
   * @returns {Task} Payment
   */
  getByReferenceId = async (referenceId) => this._restService.get(null, `${RESOURCE}${ACTIONS.GetByReferenceId}/${referenceId}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetPaymentResponseModel(result));

  /**
   * @method getByMembershipNumber
   * @type {GET}
   * @description as an admin user, gets payments by membership number
   * 
   * @param {string} membershipNumber resource id (membership number)
   * @returns {Task} [Payment]
   */
  getByMembershipNumber = async (membershipNumber) => this._restService.get(null, `${RESOURCE}${ACTIONS.GetByMembershipNumber}/${membershipNumber}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetPaymentsResponseModel(result));

  /**
   * @method cancelByReferenceId
   * @type {PUT}
   * @description as an admin user, cancel a payment by reference id
   * 
   * @param {string} referenceId resource id (reference id)
   * @returns {Task} Payment
   */
  cancelByReferenceId = async (referenceId) => this._restService.update({}, false, `${RESOURCE}${ACTIONS.CancelByReferenceId}/${referenceId}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetPaymentResponseModel(result));

  /**
   * @method refundByReferenceId
   * @type {PUT}
   * @description as an admin user, refund a payment by reference id
   * 
   * @param {string} referenceId resource id (reference id)
   * @returns {Task} Payment
   */
  refundByReferenceId = async (referenceId) => this._restService.update({}, false, `${RESOURCE}${ACTIONS.RefundByReferenceId}/${referenceId}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetPaymentResponseModel(result));

  /**
  * @method settleByReferenceId
  * @type {PUT}
  * @description as an admin user, settle a payment by reference id
  * 
  * @param {string} referenceId resource id (reference id)
  * @returns {Task} Payment
  */
  settleByReferenceId = async (referenceId) => this._restService.update({}, false, `${RESOURCE}${ACTIONS.SettleByReferenceId}/${referenceId}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetPaymentResponseModel(result));

  /**
  * @method update
  * @type {PUT}
  * @description as an admin user, settle a payment by reference id
  * 
  * @param {string} referenceId resource id (reference id)
  * @returns {Task} Payment
  */
  update = async (model) => this._restService.update(new UpdatePaymentRequestModel(model), false, `${RESOURCE}${ACTIONS.Update}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new GetPaymentResponseModel(result));

  /**
 * @method create
 * @type {POST}
 * @description as an admin user, create a new payment
 * 
 * @param {object} model CreatePaymentRequestModel request model 
 * @returns {Task} Payment
 */
  create = async (model) => {
    return this._restService.post(new CreatePaymentRequestModel(model), `${RESOURCE}${ACTIONS.Create}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new CreatePaymentResponseModel(result))
  };

}

export default new PaymentServicesApiService();