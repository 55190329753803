export const SlotTimesFieldKeys = {
    MONDAY: "MondaySlotTimes",
    TUESDAY: "TuesdaySlotTimes",
    WEDNESDAY: "WednesdaySlotTimes",
    THURSDAY: "ThursdaySlotTimes",
    FRIDAY: "FridaySlotTimes",
    SATURDAY: "SaturdaySlotTimes",
    SUNDAY: "SundaySlotTimes"
}


// todo, obsolete? used elsewhere?
// likely is wrong way around; eg. 0: "Green", 1 "Red" etc
export const TEES = { Green: 0, Red: 1, Blue: 2, White: 3, Yellow: 4 };
export const ATTRIBUTES = { ["Putting Green"]: 0, Buggy: 1 };
export const TYPE = { Links: 0, Heathland: 1 };