import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import styles from './Profile.module.css';
import ProfileRoundel from '../../../../Foundation/Helpers/Components/Feature/Common/ProfileRoundel/ProfileRoundel';
import Logout from '../../Logout/Logout';

const Profile = ({ user }) => {

  const [optionsActive, setOptionsActive] = useState(false);

  return <div className={styles.profile}>
    {optionsActive &&
    <div className={styles.options}>
      <Logout />
    </div>}
    <div className={styles.roundel} onClick={() => setOptionsActive(!optionsActive)}>
    <ProfileRoundel data={[user?.FirstName, user?.LastName]} imageUri={user?.ProfileImageUri} className={styles.profileRoundel} />
    </div>
  </div>

};

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {

}
