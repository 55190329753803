import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Redemption.module.css';
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import _redemptionServicesApiService from '../../../../Application/ApiServices/Redemption/RedemptionServices/Services/RedemptionServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../Foundation/Rest/Constants';
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { toast } from 'react-toastify';
import Notification from '../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import RedemptionList from '../RedemptionList/RedemptionList';
import LinkButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import RedemptionEntityViewModel from './RedemptionEntityViewModel';
import { ReactComponent as RedemptionIcon } from '../../../../Foundation/Theming/Icons/Navigation/redemption.svg';
import ProfileRoundel from '../../../../Foundation/Helpers/Components/Feature/Common/ProfileRoundel/ProfileRoundel';

const FAILED_MESSAGE = "Unable to retrieve redemption details",
  DATA_API_PROPERTIES_KEY = "Redemption";

const Redemption = () => {

  const [redemptionDetailsApiResponse, setRedemptionDetailsApiResponse] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [failed, setFailed] = useState(false);
  const loadMessage = failed ? FAILED_MESSAGE : 'Success';

  const routeParams = useParams();
  const referenceId = routeParams.referenceId;
  const membershipNumber = routeParams.membershipNumber;

  useEffect(() => {

    if (typeof referenceId === 'undefined' || referenceId === null) {
      return null;
    }

    setIsPendingData(true);

    _redemptionServicesApiService.getByReferenceId(referenceId).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        response[DATA_API_PROPERTIES_KEY] = new RedemptionEntityViewModel(response[DATA_API_PROPERTIES_KEY]);
        setRedemptionDetailsApiResponse(response);
      } else {
        toast(<Notification label={FAILED_MESSAGE} mode="error" />)
        setFailed(true);
      }

      setIsPendingData(false);

    });
  }, [referenceId]);

  const renderMember = (membershipRedemption, i) => {

    const user = membershipRedemption.User;
    const title = membershipRedemption.PrimaryRedemption ? "Primary member" : "Guest member";

    return <div title={title} key={`${user.Email}_memberredemption_${i}`} className={`${styles.userLink} ${membershipRedemption.PrimaryRedemption && styles.primaryMember}`}>
      <Link to={`/users/${user.AccountNumber}/${membershipRedemption.MembershipNumber}/${redemptionData.ReferenceId}`}>
        {user.ProfileImageUri && <ProfileRoundel imageUri={user.ProfileImageUri} className={`${styles.userRoundel}`} />}
        <div className={styles.memberDetails}>
          <div className={styles.memberUserName}>{user.FirstName} {user.LastName}</div>
          <div className={styles.membershipNumber}>{membershipRedemption.MembershipNumber}</div>
        </div>
      </Link>
      <div className={styles.redemptionIndividualCost}>{membershipRedemption.Cost}</div>
    </div>
  }

  const renderMemberListHeader = () => {

    return <div className={styles.memberListHeader}>
      <div className={styles.memberDetailsHeader}>

      </div>
      <div className={styles.redemptionIndividualCostHeader}>Cost per member</div>
    </div>
  }

  const redemptionData = redemptionDetailsApiResponse && redemptionDetailsApiResponse[DATA_API_PROPERTIES_KEY];

  return <div className={styles.redemptionContainer}>
    <div className={"cf"}>
      <LinkButton url=".." label={"Back to list"} arrowDirection="left" className={styles.backLink} mode="small" />
    </div>
    <div className={`${styles.redemption}`}>
      {redemptionDetailsApiResponse !== null ?
        <div className={`${styles.redemptionSummary}`}>
          <div className={`${styles.headline}`}>
            <div className={styles.leading}>
              <div className={styles.taxonomyIcon}>
                <RedemptionIcon />
              </div>
              <div className={styles.label}><span className={styles.referenceIdValue}>{redemptionData.ReferenceId}</span></div>
            </div>
            {redemptionData.PrimaryRedemption &&
              <div className={styles.primaryRedemption}>
                <div className={`${styles.field}`}>
                  <div className={`${styles.value} detail-label`}>Primary</div>
                </div>
              </div>}
          </div>
          <div className={styles.venue}>
            <div className={`${styles.partnerName} ${styles.field}`}>
              <div className={styles.label}>Course</div>
              <div className={`${styles.value} detail-label`}>{redemptionData.PartnerName}</div>
            </div>
            <div className={`${styles.dates} ${styles.field}`}>
              <div className={styles.label}>At</div>
              <div className={`${styles.dateField} ${styles.slotDate} ${styles.field}`}>
                <div className={`${styles.value} detail-label`}>{parse(redemptionData.SlotDateTime)}</div>
                <div className={styles.subLabel}>Booked on</div>
                <div className={`${styles.value} detail-label ${styles.secondaryValue}`}>{parse(redemptionData.BookingDateTime)}</div>
              </div>
            </div>
            <div className={`${styles.membersContainer}`}>
              <div className={`${styles.members} ${styles.field}`}>
                <div className={styles.label}>Members</div>
                <div className={styles.valueHeader}>
                  {renderMemberListHeader()}
                </div>
              </div>
              <div className={`${styles.members} ${styles.field}`}>
                <div className={styles.label}></div>
                <div className={styles.value}>
                  {redemptionData.MembershipRedemptions.map((a, i) => renderMember(a, i))}
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.stats}`}>
            <div className={`${styles.totalCredits} ${styles.field}`}>
              <div className={styles.value}>{redemptionData.Credits}</div>
              <div className={styles.label}>Credits per member</div>
            </div>
            <div className={`${styles.redemptionValue} ${styles.field}`}>
              <div className={styles.value}>{redemptionData.Cost}</div>
              <div className={styles.label}>TeeUp Cost</div>
            </div>
          </div>

        </div>
        :
        <CustomLoader label='Fetching redemption details' pending={isPendingData} failed={failed} message={loadMessage} className={styles.loader} />}
    </div>
  </div>

};

export default Redemption;

Redemption.propTypes = {

}
