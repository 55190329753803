class MembershipRedemptionUserSummaryModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.AccountNumber = params.accountNumber;
        this.FirstName = params.firstName;
        this.LastName = params.lastName;
        this.Email = params.email;
        this.ProfileImageUri = params.profileImageUri;

    }

}

export default MembershipRedemptionUserSummaryModel;