import React from 'react';
import PropTypes from 'prop-types';
import styles from './InlineLoader.module.css';

const InlineLoader = ({ className = '' }) => {

  return <div className={`${styles.inlineLoader} ${className} inline-loader`}>
    <div className={styles.dot}></div>
  </div>

};

export default InlineLoader;

InlineLoader.propTypes = {
  className: PropTypes.string
}