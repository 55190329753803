import React from 'react';
import PropTypes from 'prop-types';
import styles from './MembershipSelectorResultItem.module.css';
import Button from '../../../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import Pill from '../../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';
import parse from 'html-react-parser';

const MembershipSelectorResultItem = ({ data, index, selectedItem, onSelectPrimary, onSelectGuest }) => {

  return <div className={`panel-strong ${styles.item} ${selectedItem ? styles.selected : ''}`}>
    <div className={styles.inner}>
      <div className={styles.summary}>
        <div className={styles.membershipNumber}>
          <div className={styles.numberValue}>{data.MembershipNumber}</div>
          <div className={styles.expiry}>
            <div>
            Expires: {parse(data.ExpiryDate)}
            </div>
            <Pill label={data.TypeName} className={styles.typePill} />
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.user}>
            <div className={styles.name}>{data.User.FirstName} {data.User.LastName}</div>
          </div>
        </div>
      </div>
      <div className={styles.select}>
        <div className={styles.selectActions}>
        <Button noArrow={true} skinny onClick={() => onSelectPrimary(data)}>Primary</Button>
        <Button noArrow={true} skinny onClick={() => onSelectGuest(data)}>Guest</Button>
        </div>
      </div>
    </div>
  </div>;
};

export default MembershipSelectorResultItem;

MembershipSelectorResultItem.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
  selectedItem: PropTypes.bool,
  onSelectPrimary: PropTypes.func.isRequired,
  onSelectGuest: PropTypes.func.isRequired
}