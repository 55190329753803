import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import EntityDetails from './EntityDetails/EntityDetails';
import styles from './Manage.module.css';
import ManageNavigation from '../../Global/ManageNavigation/ManageNavigation';
import _adminAccountServicesApiService from '../../../Application/ApiServices/User/AdminAccountServices/Services/AdminAccountServicesApiService';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import { toast } from 'react-toastify';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';

const navigationData = {

  Summary: {
    id: "summary",
    uri: '',
    label: "Summary",
    end: true
  },
  Details: {
    id: "details",
    uri: 'details',
    label: "Details"
  }
}

const Manage = ({ }) => {

  const routeParams = useParams();
  const accountNumber = routeParams.id;
  const [userSummaryApiResponse, setUserSummaryApiResponse] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);

  useEffect(() => {
    setIsPendingData(true);
    _adminAccountServicesApiService.getSummary(accountNumber).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        setUserSummaryApiResponse(response);
      } else {
        toast(<Notification label="Error retrieving user information" mode="error" />)
      }

      setIsPendingData(false);
    });

  }, [accountNumber]);

  return <div className={styles.manage}>
    <div className={styles.sectionHeader}>
      <div className={`${styles.entityDetails} panel-strong`}>
        <EntityDetails data={userSummaryApiResponse?.AccountSummary} loading={isPendingData} />
      </div>
      <div className={`${styles.nav} panel-strong`}>
        <ManageNavigation data={navigationData} />
      </div>
    </div>
    <div className={styles.detail}>
      <Outlet />
    </div>
  </div>

};

export default Manage;

Manage.propTypes = {

}
