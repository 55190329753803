import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './QuickConfirmBooking.module.css';
import Notification from '../../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import Button from '../../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import Form from '../../../../../Foundation/Helpers/Components/Forms/Form';
import formData from './QuickConfirmFormData.json';
import CustomLoader from '../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';

const precisionHelper = (val) => ("0" + val).slice(-2);

const QuickConfirmBooking = ({ pending, time, confirm, reject, credits }) => {

  const remoteSubmit = useRef();

  const date = new Date(time),
    hours = date.getHours('hh'),
    minutes = date.getMinutes('mm'),
    timeString = `${precisionHelper(hours)}:${precisionHelper(minutes)}`;

  formData.sections.Default.fields[0].defaultValue = timeString;
  formData.sections.Default.fields[2].defaultValue = credits;

  const submitValidate = () => {

    if (pending) {
      return;
    }

    remoteSubmit.current();
  }

  formData.onSubmit = (data) => {

    const split = data.TimeSlot?.split(':');

    if (typeof confirm === 'function') {
      date.setHours(split[0], split[1], 0);
      confirm(date, data.Cost, data.Credits);
    }
  }
  return <div className={`${styles.confirmDialog} panel-strong`}>
      <h3 className={styles.title}>Confirm this booking</h3>
        <Notification label={`Confirming this will create the booking, notify the user and create a redemption`} mode="warning" className={'dialog-notification'} />
        <Form model={formData} remoteSubmit={remoteSubmit} hideControls={true} />
        <div className={styles.actions}>
          <Button disabled={pending} skinny tertiary noArrow onClick={reject}>Cancel</Button>
          <Button disabled={pending} skinny secondary noArrow onClick={submitValidate}>Confirm</Button>
        </div>
        {pending && <div className={styles.pending}>
      <CustomLoader label={"Please wait"} pending={pending}  />
    </div>}
      
  </div>;

};

export default QuickConfirmBooking;

QuickConfirmBooking.propTypes = {

}