class PartnerModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.Id = params.partnerId;
        this.Name = params.name;

    }

}

export default PartnerModel;