import SearchApiResponseBase from '../../../../../Base/Domain/SearchApiResponseBase';
import UserListItemModel from '../UserListItemModel';

class UserSearchResponseModel extends SearchApiResponseBase {

  constructor(params) {

    super(params, UserListItemModel);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

  }

}

export default UserSearchResponseModel;