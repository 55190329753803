import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styles from './NewMembership.module.css';
import LinkButton from '../../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';

// todo, once the membership edit screen is done, perhaps use that for this layout instead...

const NewMembership = ({ data }) => {

    return <div className={styles.newMembership}>
        <h3>New membership created</h3>
        <div className={`${styles.membershipDetails} panel-strong`}>
            <div className={styles.type}>
                <div className={styles.tier}>{data.MembershipType.Name}</div>
                <div className={styles.annual}>£{data.MembershipType.Annual}</div>
            </div>
            <div className={styles.information}>
                <div className={styles.title}>
                    <div className={styles.accountNumber}><span className={styles.label}>Account Number:</span> {data.AccountNumber}</div>
                    <div className={styles.membershipNumber}><span className={styles.label}>Membership Number:</span> {data.MembershipNumber}</div>
                </div>
                <div className={styles.dates}>
                    <div className={styles.startDate}><span className={styles.label}>Valid from:</span> {parse(data.StartDate)}</div>
                    <div className={styles.expiryDate}><span className={styles.label}>Expires on:</span> {parse(data.ExpiryDate)}</div>
                </div>
            </div>
        </div>
        <div className={styles.actions}>
            <LinkButton url={`/users/${data.AccountNumber}`} label="Go to user profile" />
        </div>
    </div>;

};

export default NewMembership;

NewMembership.propTypes = {
    data: PropTypes.object.isRequired
}