

/**
 * @function Tests a value for undefined, null or empty
 * 
 * @param {dynamic} prop value to check
 * @returns {boolean} 
 */
export function NullUndefinedEmpty(prop){
    return typeof prop === 'undefined' || prop === null || prop.length === 0;
}

export function isObject(val) {
    if (val === null) { return false; }
    return ((typeof val === 'function') || (typeof val === 'object'));
  }
