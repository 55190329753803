import React from 'react';
import PropTypes from 'prop-types';
import styles from './PartnerDetailsSummary.module.css';
import { ReactComponent as CallOutIcon } from '../../../../../Foundation/Theming/Icons/Core/callout.svg';

const PartnerDetailsSummary = ({ model, className = '' }) => {

  return <div className={`${styles.partner} ${className}`}>
    <div className={styles.heading}>
      <div className={`${styles.name}`}>{model.Name}</div>
      <div className={styles.location}>
        <div className={`${styles.county}`}>{model.CountyName}</div>
        <div className={`${styles.territory}`}>{model.Territory}</div>
      </div>
    </div>
    <div className={styles.contactDetails}>
      <div className={`${styles.telephone}`}>
        <a href={`tel:${model.Telephone}`}>
          <span className={styles.telIcon}><CallOutIcon /></span>
          <span className={styles.telText}>{model.Telephone}</span>
        </a>
      </div>
    </div>
  </div>;

};

export default PartnerDetailsSummary;

PartnerDetailsSummary.propTypes = {

}