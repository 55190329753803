import ApiResponseBase from '../../../../Base/Domain/ApiResponseBase';
import MembershipModel from '../MembershipModel';

class CreateMembershipResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.Membership = new MembershipModel(params.membership);

  }

}

export default CreateMembershipResponseModel;

