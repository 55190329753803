import React from 'react';
import PropTypes from 'prop-types';
import styles from './LinkButton.module.css';
import { Link } from 'react-router-dom';
import Arrow from '../../../Arrow/Arrow';

const modes = {
  '': '',
  'small': styles.small
}

const LinkButton = ({ url, className = '', label, solid, primary, secondary, mode = '', arrowDirection = "right" }) => {

  if(typeof url === 'undefined'){
    return null;
  }

  const modeClass = modes[mode];
  const rightMode = arrowDirection === 'right';

  return <Link to={url} className={`${styles.linkButton} ${solid && styles.solid} ${primary && styles.primary} ${secondary && styles.secondary} ${className} ${modeClass}`}>
    {arrowDirection === 'left' && <Arrow direction={arrowDirection} />}
    <div className={`${styles.label} ${!rightMode && styles.arrowleftLabel}`}>{label}</div>
    {rightMode && <Arrow direction={arrowDirection} />}
  </Link>

};

export default LinkButton;

LinkButton.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  mode: PropTypes.string, // small, 
  arrowDirection: PropTypes.string,
}