class RedemptionRequestSearchRequestModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        this.SearchTerm = params.SearchTerm;
        this.IncludeConfirmed = params.IncludeConfirmed;
        this.IncludeCancelled = params.IncludeCancelled;
        this.PartnerIds = params.PartnerIds?.map(p => p.value);

    }

}

export default RedemptionRequestSearchRequestModel;