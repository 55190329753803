import React from 'react';
import PropTypes from 'prop-types';
import './Arrow.css';

const Arrow = ({ direction }) => {

  const arrowDirection = typeof direction !== 'undefined' ? (`arrow--${direction}`) : '';

  return <div className={`arrow ${arrowDirection}`}></div>;

};

export default Arrow;

Arrow.propTypes = {
  direction: PropTypes.string
}