import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import MembershipSearchSummaryResponseModel from '../Models/MembershipList/Get/MembershipSearchSummaryResponseModel';
import MembershipSearchRequestModel from '../Models/MembershipList/Get/MembershipSearchRequestModel';
import MembershipSearchResponseModel from '../Models/MembershipList/Get/MembershipSearchResponseModel';
import MembershipSearchSummaryRequestModel from '../Models/MembershipList/Get/MembershipSearchSummaryRequestModel';

const API_ENDPOINT = process.env.REACT_APP_MEMBERSHIP_MEMBERSERVICES_API_HOST;

class MembershipSearchServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
     * @method search
     * @type {POST}
     * @description as an admin user, search memberships by parameters
     * 
     * @param {MembershipSearchRequestModel} request model
     * @returns {Task} [UserSummary]
     */
  search = async (params) => this._restService.post(new MembershipSearchRequestModel(params), `${RESOURCE}${ACTIONS.Search}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new MembershipSearchResponseModel(result));

  /**
   * @method searchSummary
   * @type {POST}
   * @description as an admin user, search memberships by parameters for membership summary list
   * 
   * @param {MembershipSearchSummaryRequestModel} request model
   * @returns {Task} [UserSummary]
   */
  searchSummary = async (params) => this._restService.post(new MembershipSearchSummaryRequestModel(params), `${RESOURCE}${ACTIONS.SearchSummary}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new MembershipSearchSummaryResponseModel(result));


}

export default new MembershipSearchServicesApiService();