import React from 'react';
import styles from './MembershipHeader.module.css';
import { ReactComponent as MembershipIcon } from '../../../../../Foundation/Theming/Icons/Navigation/memberships.svg';
import Pill from '../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';

const MembershipHeader = ({ data }) => {

  if (typeof data === 'undefined') {
    return null;
  };

  return <div className={`${styles.headline}`}>
    <div className={styles.leading}>
      <div className={styles.taxonomyIcon}>
        <MembershipIcon />
      </div>
      {data.MembershipNumber && <div className={styles.label}><span className={styles.membershipNumberValue}>{data.MembershipNumber}</span></div>}
      {data.Locked && <Pill label={"Locked"} className={styles.locked} />}
      {!data.Activated && <Pill label={"Awaiting Activation"} className={styles.awaitingActivation} />}
    </div>
    {data.MembershipType &&
      <div className={styles.membershipType}>
        <div className={`${styles.field}`}>
          <div className={styles.value}>{data.MembershipType?.Name}</div>
        </div>
        <div className={`${styles.field}`}>
          <div className={styles.value}>£{data.MembershipType?.Annual}</div>
        </div>
      </div>}
  </div>

};

export default MembershipHeader;

MembershipHeader.propTypes = {

}