import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UserListingResult.module.css';
import ProfileRoundel from '../../../../Foundation/Helpers/Components/Feature/Common/ProfileRoundel/ProfileRoundel';
import LinkButton from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/LinkButton/LinkButton';
import Pill from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';

const UserListingResult = ({ model }) => {

  return <div className={`${styles.userListingResult}`}>
    <div className={styles.profileContainer}>
      <div className={styles.profile}>
        <ProfileRoundel data={[model.FirstName, model.LastName]} imageUri={model.ProfileImageUri} />
      </div>
      <div className={styles.role}>
        {model.DisplayRole && <Pill label={model.DisplayRole} className={`role--${model.DisplayRole?.toLowerCase()}`} />}
      </div>
    </div>
    <div className={styles.information}>
      <div className={styles.headline}>
        <div className={styles.name}>
          <div>{model.FirstName} {model.LastName}</div>
          <div className={styles.accountNumber}>{model.AccountNumber}</div>
        </div>
        <div className={styles.email}>
          {model.Email}
        </div>
      </div>
      <div className={styles.extraDetails}>
        <div className={styles.meta}>
          <span className={styles.metaLabel}>Joined: </span><span className={styles.metaValue}>{model.CreatedAt}</span>
        </div>
        <div className={styles.location}>
        <div className={`${styles.county} label-tag`}>
          {model.CountyName}
        </div>
        <div className={`${styles.country} label-tag`}>
          {model.CountryName}
        </div>
        </div>
      </div>
    </div>
    <div className={styles.actions}>
      <LinkButton url={model.AccountNumber} label="Details" mode="small" arrowDirection='right'></LinkButton>
    </div>
  </div>

};

export default UserListingResult;

UserListingResult.propTypes = {
  model: PropTypes.object.isRequired
}
