import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import LoginResponseModel from '../Models/Login/LoginResponseModel';
import LoginRequestModel from '../Models/Login/LoginRequestModel';
import RefreshRequestModel from '../Models/Refresh/RefreshRequestModel';
import RefreshResponseModel from '../Models/Refresh/RefreshResponseModel';
import StateLoginModel from '../Models/Login/StateLoginModel';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';

const API_ENDPOINT = process.env.REACT_APP_IDENTITY_API_HOST;

class AuthenticationApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method login
   * @type {POST}
   * @description requests login for user credentials and returns authentication token
   * 
   * @param {object} model login request model
   * @returns {Task} login response model or unauthorised response
   */
  login = async (model) => this._crudService.create(new LoginRequestModel(model), `${RESOURCE}/${ACTIONS.Login}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new LoginResponseModel(result));

  /**
   * @method loginFromState
   * @description NO API - map state user data to simulate response model
   * 
   * @param {object} model 
   * @returns {LoginResponseModel}
   */
  loginFromState = async (model) => new StateLoginModel(model);

  /**
   * @method logoutFromState
   * @description NO API - no response
   * 
   * @param {object} model 
   * @returns {null}
   */
  logoutFromState = async () => null;

  /**
   * @method refresh
   * @type {POST}
   * 
   * @returns {RefreshResponseModel} requests an access token refresh 
   */
  refresh = async (model) => this._restService.post(new RefreshRequestModel(model), `${RESOURCE}/${ACTIONS.Refresh}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new RefreshResponseModel(result));

  /**
   * @method logout
   * @type {DELETE}
   * 
   * @returns {bool} requests logout and returns 
   */
  logout = async () => this._restService.delete(null, `${RESOURCE}/${ACTIONS.Logout}`).then(result => result === null ? new ApiResponseBase({ apiStatus: HTTP_STATUS_CODES.NoContent, apiMessage: 'Logged out' }) : new ApiResponseBase(result?.data));

}

export default new AuthenticationApiService();