import ApiResponseBase from "./ApiResponseBase";
import SearchResult from "./SearchResult";

export default class SearchApiResponseBase extends ApiResponseBase {

    /**
     * @constructor
     * @param {object} params response data
     * @param {*} ListItemType Class for list item data to be cast to
     * @returns 
     */
    constructor(params, ListItemType) {

        super(params);

        if (typeof params === 'undefined' || params === null) {
            return null;
        }

        this.Results = new SearchResult(params, ListItemType);

    }

}