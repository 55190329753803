import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AdjustCreditBalance.module.css';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import Button from '../../../../Foundation/Helpers/Components/Feature/Common/Buttons/Button/Button';
import Form from '../../../../Foundation/Helpers/Components/Forms/Form';
import formData from './AdjustCreditBalanceFormData.json';
import { useDispatch, useSelector } from 'react-redux';
import { updateMembershipState } from '../../../../Foundation/Store/Actions/Membership/membershipActions';
import { useParams } from 'react-router-dom';
import _membershipServicesApiService from '../../../../Application/ApiServices/Membership/MembershipMemberServices/Services/MembershipMemberServicesApiService';
import Notification from '../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { HTTP_STATUS_CODES } from '../../../../Foundation/Rest/Constants';

const DATA_API_PROPERTIES_KEY = "MembershipCredit";

const AdjustCreditBalance = ({ active, onClose }) => {

  const remoteSubmit = useRef();
  const dispatch = useDispatch();
  const membership = useSelector((state) => state.membership);
  const [validationErrorData, setValidationErrorData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const routeParams = useParams();
  const membershipNumber = routeParams.membershipNumber;

  const close = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  const submit = () => {
    remoteSubmit.current();
  }

  const calculateNewCreditBalance = (value) => dispatch(updateMembershipState({
      Credits: membership.Credits + value,
      CreditsRemaining: membership.CreditsRemaining + value
    }));

  const formSubmit = (submitData) => {

    if (typeof submitData === 'undefined') {
      return null;
    }

    submitData.MembershipNumber = membershipNumber;

    setIsPending(true);

    _membershipServicesApiService.adjustCreditBalance(submitData).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {
        toast(<Notification label="Credit balance updated" mode="success" />)
        calculateNewCreditBalance(response[DATA_API_PROPERTIES_KEY]?.ActualValue);
      } else {
        toast(<Notification label="Unable to update credit balance" mode="error" />)
      }

      setIsPending(false);
      close();
    });

  }

  formData.onSubmit = formSubmit;

  return <div className={styles.adjustCreditBalance}>
    <Modal isOpen={active}
      overlayClassName="sys-modal"
      className="modal-body"
      ariaHideApp={false} >
      {/* <CSSTransition in={quickConfirmDialogOpen} timeout={200} classNames="slew-up"> */}
      <div className={`${styles.dialog} panel-strong`}>
        <h3 className={styles.title}>Add or remove credits</h3>
        <div className={styles.addRemove}>
          <Form remoteSubmit={remoteSubmit} model={formData} submissionResponseData={validationErrorData} />
        </div>
        <div className={styles.actions}>
          <Button skinny tertiary noArrow onClick={close}>Cancel</Button>
          <Button skinny secondary noArrow onClick={submit}>Confirm</Button>
        </div>
        {isPending && <CustomLoader message={"Updating credit balance"} pending={isPending} className={styles.loader} />}
      </div>
      {/* </CSSTransition> */}
    </Modal>

  </div>

};

export default AdjustCreditBalance;

AdjustCreditBalance.propTypes = {

}
