import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionTitle.module.css';

const SectionTitle = ({ title }) => {

  return <div className={styles.sectionTitle}>
    <h3>{title}</h3>
  </div>;

};

export default SectionTitle;

SectionTitle.propTypes = {
  title: PropTypes.string
}