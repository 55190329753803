import ApiResponseBase from '../../../../Base/Domain/ApiResponseBase';
import MembershipTypeModel from '../MembershipTypeModel';

class GetMembershipTypesResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.MembershipTypes = params.membershipTypes?.map(m => new MembershipTypeModel(m));

  }

}

export default GetMembershipTypesResponseModel;