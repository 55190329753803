import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import styles from './BookingRequestManager.module.css';
import FilterListing from '../../../FilterListing/FilterListing';
import _userSearchServicesApiService from '../../../../Application/ApiServices/User/Search/Services/UserSearchServicesApiService';
import _redemptionRequestSearchServicesApiService from '../../../../Application/ApiServices/Redemption/RedemptionRequestSearchServices/Services/RedemptionRequestSearchServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../Foundation/Rest/Constants';
import filterData from './BookingRequestManagerListingFilterData.json';
import { getPartners } from '../../../../Foundation/Store/Actions/Utility/utilityActions';
import { mapNameIdPairToSelectOptions } from '../../../../Foundation/Helpers/Components/Forms/Utility/formHelpers';
import { toast } from 'react-toastify';
import Notification from '../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import BookingRequestListingResultViewModel from './ListingResult/BookingRequestListingResultViewModel';
import SectionTitle from '../../../../Foundation/Helpers/Components/Feature/Common/SectionTitle/SectionTitle';

const PARTNER_ID_KEY = 'PartnerIds';

const BookingRequestManager = ({ partners }) => {

  const [results, setResults] = useState(null);
  const [data, setData] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [isPendingFilterData, setIsPendingFilterData] = useState(false);
  const dispatch = useDispatch();

  const mapResults = (d) => {
    return { ...d, Items: d?.Items.map(a => new BookingRequestListingResultViewModel(a)) };
  }

  const filterSubmit = (data) => {

    setIsPendingData(true);

    let dto = { ...data };

    _redemptionRequestSearchServicesApiService.search(dto).then((response) => {

      if (response.apiStatus === HTTP_STATUS_CODES.Success) {

        let mappedResults = mapResults(response.Results);
        setResults(mappedResults);
      } else {
        toast(<Notification label="Unable to complete search" mode="error" />)
      }

      setIsPendingData(false);

    });
  }

  const buildFormViewModel = () => {

    let result = { formViewModel: filterData }

    // counties
    for (let section in result.formViewModel.sections) {

      for (let field of result.formViewModel.sections[section].fields) {

        if (field.name === PARTNER_ID_KEY) {
          let partnerList = mapNameIdPairToSelectOptions(partners);
          field.options = partnerList;
          // field.displayValue = partners.find(x => x.value === entity)?.label;
        }

      }
    }

    return result;
  }

  useEffect(() => {

    if (partners === null) {
      setIsPendingFilterData(true);
      dispatch(getPartners()).then(() => {
        setIsPendingFilterData(false);
      })
    }

    if (partners !== null) {
      setData(buildFormViewModel());
    }

  }, [partners]);

  useEffect(() => {

    filterSubmit(null); // todo, from pre-stored parameters or default

  }, []);

  filterData.onSubmit = filterSubmit;

  return <div className={`${styles.membershipManager}`}>
    <SectionTitle title="Booking requests"></SectionTitle>
    <FilterListing type="bookingrequest" data={results} filters={filterData} isPendingData={isPendingData} isPendingFilterData={isPendingFilterData} />
  </div>

};

function mapStateToProps(state) {
  return {
    partners: state.utility.partners
  }
}

export default connect(mapStateToProps)(BookingRequestManager);

BookingRequestManager.propTypes = {

}
