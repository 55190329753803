import ApiResponseBase from '../../../../Base/Domain/ApiResponseBase';

/**
  * @implements a login response model
  *
  */
class LoginResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.AccessToken = params.accessToken || null;
    this.RefreshToken = params.refreshToken || null;

  }

}

export default LoginResponseModel;