import { toDateHtmlString } from "../../../../../../Utility/DateHelpers";

class MembershipSelectorResultItemViewModel {

  constructor(params) {

    if (typeof params === 'undefined') {
      return;
    }

    this.MembershipNumber = params.MembershipNumber;
    this.TypeName = params.TypeName;
    this.StartDate = toDateHtmlString(params.StartDate);
    this.ExpiryDate = toDateHtmlString(params.ExpiryDate);
    this.User = params.User;

  }

}

export default MembershipSelectorResultItemViewModel;