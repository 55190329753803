import ApiResponseBase from '../../../../Base/Domain/ApiResponseBase';
import RedemptionDetailsModel from '../RedemptionDetailsModel';

class GetRedemptionResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.Redemption = new RedemptionDetailsModel(params.redemption);

  }

}

export default GetRedemptionResponseModel;