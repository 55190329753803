import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ConfirmButton.module.css';
import Button from '../Button/Button';
import Modal from 'react-modal';
import ConfirmDialog from '../../Dialogs/ConfirmDialog/ConfirmDialog';

const ConfirmButton = ({ dialogData, primary, skinny, ghost, danger, secondary, tertiary, children, className = '', dialogClassName = '', noArrow = false, disabled, onReject, onConfirm }) => {

  const [isOpen, setIsOpen] = useState(false);

  const trigger = () => {
    setIsOpen(true);
  }

  const confirm = (data) => {
    setIsOpen(false);
    if (typeof onConfirm === 'function') {
      onConfirm(data);
    }
  }

  const reject = () => {
    setIsOpen(false);
    if (typeof onReject === 'function') {
      onReject();
    }
  }

  return <>
    <Button onClick={trigger} primary={primary} skinny={skinny} ghost={ghost} danger={danger} secondary={secondary} tertiary={tertiary} className={className} noArrow={noArrow} type="submit" disabled={disabled}>
      {children}
    </Button><Modal
      isOpen={isOpen}
      overlayClassName="sys-modal"
      className="modal-body"
      ariaHideApp={false}
    >
      <ConfirmDialog className={dialogClassName} data={dialogData} onConfirm={confirm} onReject={reject} />
    </Modal></>
};

export default ConfirmButton;

ConfirmButton.propTypes = {
  dialogData: PropTypes.object.isRequired
}