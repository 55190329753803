import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import Head from '../Components/Global/Head/Head';
import Header from '../Components/Global/Header/Header';
import Main from '../Components/Global/Main/Main';
import './Theming/Styles/global.css';
import './Theming/Styles/forms.css';
import './Theming/Styles/Libs/normalise.css';
import Navigator from '../Components/Global/Navigator/Navigator';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Login from '../Components/Global/Login/Login';
import Splash from './Helpers/Components/Feature/Splash/Splash';

const APP_LOAD_SCREEN_DELAY_MODERATOR = 500;

const Layout = ({ className = '', authentication }) => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (authentication?.isAuthenticated === true) {
      if (location.state?.from) {
        navigate(location.state.from);
      }
    }
  }, [authentication]);

  let checkAuth = typeof authentication?.isAuthenticated !== 'undefined';

  return checkAuth ? <div className={`screen ${className}`}>
    <Head />
    <Routes>
      <Route exact index path={'/login'} element={<Fade duration={600}><Login /></Fade>}></Route>
      <Route path={'*'} element={!authentication?.isAuthenticated ?
        <Navigate to="/login" state={{ from: location }} replace /> :
        <Fade duration={600}>
          <>
            <Navigator />
            <div className='display'>
              <Header />
              <Main />
            </div>
          </>
        </Fade>
      }>
      </Route>
    </Routes>
  </div> : <Fade delay={APP_LOAD_SCREEN_DELAY_MODERATOR} duration={600}><Splash /></Fade>
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  }
}

export default connect(mapStateToProps)(Layout);

Layout.propTypes = {
  className: PropTypes.string
}