const FieldErrors = ({ errors }) => {

    if (typeof errors === 'undefined') {
        return null;
    }

    return errors.message && <div className="form-field__errors">
        <div className="form-field__error-message show">{errors.message}</div>
    </div>;

}

export default FieldErrors;