/**
 * @description merge api data with a form base model (json) for rendering with values and settings
 * 
 * @param {object} model data source as json for form structure
 * @param {object} source api data response to be merged with form base model
 * @param {string} key property key for api data
 * @returns 
 */
export const mapFormViewModel = (model, source, key) => {

    if(source === null){
        return { formViewModel: model };
    }

    let result = {
        apiStatus: source.apiStatus,
        apiMessage: source.apiMessage,
        formViewModel: model
    };

    for (let section in result.formViewModel.sections) {

        for (let field of result.formViewModel.sections[section].fields) {
            if (source[key]?.hasOwnProperty(field.name)) {
                let entity = source[key][field.name];

                field.defaultValue = entity;
                field.displayValue = entity;
            }
        }
    }

    return result;
}

/**
 * @description normalises data for rerendering where it has changed for being submitted for api
 * 
 * @param {object} source source object
 * @param {object} data object to merge
 * @param {string} propertiesKey contextual properties key from submit data
 * @param {string} key dropdown property value
 * @returns 
 */
export const flattenDropdownOption = (source, data, propertiesKey, key) => {
    source[propertiesKey] = data;
    source[propertiesKey][key] = data[key].value;
    return source;
}

/**
 * @description given a collection of Name : Id pair, map to select options
 * 
 * @param {array} countiesData 
 * @returns 
 */
export const mapNameIdPairToSelectOptions = (countiesData) => {

    if (countiesData === null || countiesData.length === 0) {
      return null;
    }

    return countiesData.map((c) => ({ value: c.Id, label: c.Name }));

  }