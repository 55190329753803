import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldBase from '../Base/FieldBase';
import { CLASS_MODIFIERS } from '../../Utility/_formsConfig';

const CheckBox = ({ name, register, label, defaultValue, alive, option, errors, incrementor, validation = {} }) => {

    const [value, setValue] = useState(defaultValue || false);

    const onValueChange = (val) => {
        setValue(val.target.checked);        
        return val;
    }

    const renderCheckBox = (checkbox) => {

        const checked = (`${value}` === "true");

        return <div className="checkbox" key={`${checkbox.label}`}>
            <label>
                <div className="checkbox__control-container">
                    <div className={`checkbox__control ${checked ? 'checked' : ''}`} tabIndex="1" role="checkbox" aria-checked={checked}>
                        <input {...register(name, validation)} type="checkbox" onChange={onValueChange} checked={checked} value={checked} />
                        <span className="checkbox__control-mask">{checked && <span className="checkmark draw"></span>}</span>
                    </div>
                </div>
                <span className="checkbox__label">
                    {checkbox.label}
                </span>
            </label>
        </div>
    }

    return <FieldBase {...{ errors, alive, ['classModifier']: CLASS_MODIFIERS.CheckBox, label, incrementor }}>
        <div role="checkbox">
            {renderCheckBox(option)}
        </div>
    </FieldBase>
};

export default CheckBox;

CheckBox.propTypes = {
    incrementor: PropTypes.number.isRequired
}