import BaseRestService from './BaseRestService';
import CrudService from './CrudService';
import RestService from './RestService';

class BaseApiService {

  _crudService;
  _restService;

  constructor(apiEndpoint, name) {
    
    this._crudService = new CrudService(apiEndpoint, name);
    this._restService = new RestService(apiEndpoint, name);
  }

  /**
   * @method setAuthenticationToken
   * @description sets the underlying bearer token for the request
   * 
   * @param {string} value bearer token value
   */
  setAuthenticationToken(value) {
    BaseRestService.authenticationToken = value;
  }

  /**
   * @method setRequestHeaders
   * @param {object} data object with request headers to set as kvp
   */
  setRequestHeaders(data) {
    this._restService.api.setRequestHeaders(data);
  }

}

export default BaseApiService;