import { UPDATE_REQUESTS_STATE } from './actionTypes';

/* requests */
const updateRequestsState = (requestsState) => ({ type: UPDATE_REQUESTS_STATE, requestsState })

export function registerRequestsEvents(data) {
    return (dispatch) => {
        return dispatch(updateRequestsState(data)); 
    }
}
