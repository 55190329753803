import FieldErrors from "../../FieldErrors/FieldErrors";
import FieldStatus from "../../Status/FieldStatus";
import Fade from 'react-reveal/Fade';

const FieldBase = ({ children, className, errors, classModifier, label, alive, incrementor, type, noLabel = false }) => {

    const error = typeof errors !== 'undefined';

    const renderContents = () => {
        return <>
            {label &&
                <div className="form-field__label">{label}</div>
            }
            <div className={`form-field__field-container ${type ? type : ''}`}>
                <div className="form-field__field">
                    {children}
                    {alive && <div className="form-field__validation-status"><FieldStatus valid={!error} incrementor={incrementor} /></div>}
                </div>
                <Fade when={error} appear><FieldErrors errors={errors} /></Fade>
            </div>
        </>
    }

    return <div className={`form-field ${className} ${classModifier ? `form-field--${classModifier}` : ''} ${error ? 'form-field--error' : ''} ${alive && !error ? 'form-field--valid' : ''}`}>
        {/* {tooltip && <Tooltip model={tooltip} />} */}
        {noLabel ? <div className="form-field__label">{renderContents()}</div> : <label>
            {renderContents()}
        </label>}

    </div >

}

export default FieldBase;