import React from 'react';
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import Pill from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';
import { ReactComponent as PartnerIcon } from '../../../../Foundation/Theming/Icons/Navigation/courses.svg';
import styles from './EntityDetails.module.css';

const EntityDetails = ({ data, loading }) => {
 
  return <div className={styles.profileSummary}>
    <div className={styles.info}>
      {!loading ? typeof data !== 'undefined' && <>
        <div className={styles.profile}>
          <div className={styles.taxonomyIcon}>
            <PartnerIcon />
          </div>
          {data.Prestige &&
            <div className={styles.prestige}>
              <Pill label='Prestige' className={styles.prestigePill} />
            </div>}
        </div>
        <div className={styles.profileSummaryDetails}>
          <div className={styles.name}>
            {data.Name}</div>
          <div className={styles.partnerId}>
            <div className={styles.partnerIdValue}>{data.PartnerId}</div>
            {data.Published ?
              <Pill label='Published' className={`${styles.pill} ${styles.publishedPill}`} /> : <Pill label='Unpublished' className={`${styles.pill} ${styles.unpublishedPill}`} />
            }</div>
          <div className={styles.tags}>
            <div className={`${styles.tag} label-tag`}>
              {data.CountyName}
            </div>
            <div className={`${styles.tag} label-tag`}>
              {data.TerritoryName}
            </div>
          </div>
          <div className={styles.accountSince}>
            Created: {data.CreatedAt}
          </div>
        </div>
      </> : <CustomLoader label={"Loading Course Summary"} pending={loading} className={styles.loader} /> }
    </div>
  </div>

};

export default EntityDetails;

EntityDetails.propTypes = {

}
