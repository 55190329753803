import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ExpandToggle.module.css';

const ExpandToggle = ({ inLabel, outLabel, toggleFunction, defaultOpen = false }) => {

  const [open, setOpen] = useState(defaultOpen);

  const toggle = () => {

    if (typeof toggleFunction !== 'function') {
      return null;
    }

    setOpen(!open);
    toggleFunction();
  }

  return <div className={styles.expandToggle} onClick={toggle}>
    <div className={styles.label}>{open ? outLabel : inLabel}</div>
    <div className={styles.icon}>
      <div className={`${styles.circlePlus} ${styles.closed} ${open ? styles.opened : ''}`}>
        <div className={styles.circle}>
          <div className={styles.horizontal}></div>
          <div className={styles.vertical}></div>
        </div>
      </div>
    </div>
  </div>
};

export default ExpandToggle;

ExpandToggle.propTypes = {
  inLabel: PropTypes.string,
  outLabel: PropTypes.string,
  toggleFunction: PropTypes.func,
  defaultOpen: PropTypes.bool
}