import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PartnerSelector.module.css';
import filterData from './PartnerSelectorFilterData.json';
import Form from '../../../../../Foundation/Helpers/Components/Forms/Form';
import { toast } from 'react-toastify';
import Notification from '../../../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import _partnerSearchServicesApiService from '../../../../../Application/ApiServices/Partner/Search/Services/PartnerSearchServicesApiService';
import { HTTP_STATUS_CODES } from '../../../../../Foundation/Rest/Constants';
import PartnerSelectorResultItem from './PartnerSelectorResultItem/PartnerSelectorResultItem';
import CustomLoader from '../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { ReactComponent as PartnerIcon } from '../../../../../Foundation/Theming/Icons/Navigation/courses.svg';
import Pill from '../../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/Pill/Pill';

const PartnerSelector = ({ data, shared, ui, className }) => {

    const [isPendingData, setIsPendingData] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);

    const filterSubmit = (submitData) => {

        setIsPendingData(true);
        setSearchTerm(submitData?.SearchTerm);

        let dto = { ...submitData };

        _partnerSearchServicesApiService.search(dto).then((response) => {

            if (response.apiStatus === HTTP_STATUS_CODES.Success) {
                data.updateState(null, {});
                data.updateUiState(Object.assign({}, { results: response.Results }));
            } else {
                toast(<Notification label="Unable to complete search" mode="error" />)
            }

            setIsPendingData(false);

        });
    }

    const onSelectItem = (selectedData) => {
        let persistData = Object.assign(shared.partner, { _single: { _label: `${selectedData.Name} (${selectedData.PartnerId})`, value: selectedData.PartnerId } });

        data.updateState(true, persistData);
        data.updateUiState(Object.assign(ui.partner || {}, { selectedItemId: selectedData.PartnerId }));
    }

    const renderNoResults = () => {
        return <div className={styles.noResults}>No results for "{searchTerm}"</div>
    }

    filterData.onSubmit = filterSubmit; // override to add submit functionality

    useEffect(() => {

        // preloaded data from referral
        if (typeof data?.defaultData !== 'undefined') {
            onSelectItem(data.defaultData);
        }

    }, []);

    return <div className={`${styles.partnerSelector} ${shared?.partner?.valid && styles.partnerSelectionSectionValid}`}>
        <div className={styles.searchComponent}>
            <div className={styles.filter}>
                <Form model={filterData} isDisabled={isPendingData} />
            </div>
            <div className={styles.results}>
                {isPendingData ? <CustomLoader label={"Searching Courses"} pending={isPendingData} className={styles.loader} /> :
                    <>
                        {ui.partner?.results !== null && ui.partner?.results?.Count > 0 &&
                            ui.partner.results.Items.map((r, i) => <PartnerSelectorResultItem data={r} index={i} key={`${r.PartnerId}_${i}`} selectedId={ui.partner.selectedItemId} onSelect={onSelectItem} />)}
                        {ui.partner?.results !== null && ui.partner?.results?.Count === 0 &&
                            renderNoResults()}
                    </>}
            </div>
        </div>
        <div className={styles.selection}>
            <div className={styles.selectionTitle}>
                Selected Course
            </div>
            <div className={styles.partner}>
                {typeof shared.partner?.saveData?._single !== 'undefined' && shared.partner?.saveData !== null ?
                    <div className={styles.selectionInner}>
                        <div className={styles.taxonomyIcon}>
                            <PartnerIcon />
                        </div>
                        <div className={styles.details}>
                            <Pill label={"Course"} className={styles.selectionPill} />
                            <div className={styles.partnerDetails}>
                                <div className={styles.name}>{shared.partner.saveData._single._label}</div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.noSelection}>No course selected</div>}
            </div>
        </div>
    </div>;

};

export default PartnerSelector;

PartnerSelector.propTypes = {

}