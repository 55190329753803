import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import ProtectedRoute from '../../../Foundation/Helpers/Components/Feature/ProtectedRoutes/ProtectedRoute';

import styles from './Main.module.css';
import _ScreenResolver from '../../Screens/_ScreenResolver';

import UserManager from '../../Users/UserManager/UserManager';
import CreateUser from '../../Users/Create/Create';
import ManageUser from '../../Users/Manage/Manage';
import PartnerManager from '../../Partners/PartnerManager/PartnerManager';
import CreatePartner from '../../Partners/Create/Create';
import ManagePartner from '../../Partners/Manage/Manage';
import PartnerDetails from '../../Partners/Edit/Edit';
import PartnerOverview from '../../Partners/Overview/Overview';
import CreateMembeship from '../../Memberships/Create/Create';
import UserDetails from '../../Users/Edit/Edit';
import Memberships from '../../Users/Memberships/Memberships';
import MembershipDetails from '../../Memberships/Edit/Edit';
import Membership from '../../Memberships/Shared/Membership/Membership';
import MembershipManager from '../../Memberships/MembershipManager/MembershipManager';
import Redemption from '../../Redemptions/Shared/Redemption/Redemption';
import CreateRedemption from '../../Redemptions/Create/Create';
import BookingRequestManager from '../../Redemptions/Requests/BookingRequestManager/BookingRequestManager';
import BookingRequest from '../../Redemptions/Requests/BookingRequest/BookingRequest';
import Payments from '../../Payments/Shared/Payments';
import Payment from '../../Payments/Shared/Payment/Payment';

const Main = () => {

  return <main id="main" className='panel'>
    <div className={`${styles.mainPanel}`}>
      <Routes>
        {/* Dashboard */}
        <Route exact index path={'/'} element={_ScreenResolver.Dashboard}></Route>

        {/* Users */}
        <Route path={'users'} element={_ScreenResolver.Users}>
          <Route index element={<UserManager />} />
          <Route path='create' element={<CreateUser />} />
          <Route path=':id' element={<ManageUser />}>
            <Route path='' element={<Memberships />}>
              <Route path=':membershipNumber' element={<Membership />}>
                <Route index path=':referenceId' element={<Redemption />} />
              </Route>
            </Route>
            <Route path='details' element={<UserDetails />} />
          </Route>
        </Route>

        {/* Memberships */}
        <Route path={'memberships'} element={_ScreenResolver.Memberships}>
          <Route index element={<MembershipManager />} />
          <Route path='create' element={<CreateMembeship />} />
          <Route path=':membershipNumber' element={<MembershipDetails />}>
            <Route index path=':referenceId' element={<Redemption />} />
          </Route>
          <Route path=':membershipNumber/payments' element={<Payments />}>
            <Route path=':referenceId' element={<Payment />}></Route>
          </Route>
          <Route path='redemptions' element={_ScreenResolver.Redemptions}>
            <Route path='create' element={<CreateRedemption />} />
            <Route path='requests' element={<BookingRequestManager />} />
            <Route index path='requests/:referenceId' element={<BookingRequest />} />
          </Route>
        </Route>

        {/* Partners */}
        <Route path={'courses'} element={_ScreenResolver.Partners}>
          <Route index element={<PartnerManager />} />
          <Route path='create' element={<CreatePartner />} />
          <Route path=':id' element={<ManagePartner />}>
            <Route path='' element={<PartnerOverview />}>

            </Route>
            <Route path='details' element={<PartnerDetails />} />
          </Route>
        </Route>

        {/* System */}
        <Route path={'system'} element={_ScreenResolver.System}>
          {/* <Route index element={<PartnerManager />} /> */}
          {/* <Route path='create' element={<CreatePartner />} />
          <Route path=':id' element={<ManagePartner />}>
            <Route path='' element={<PartnerOverview />}>

            </Route>
            <Route path='details' element={<PartnerDetails />} /> */}
          {/* </Route> */}
        </Route>
      </Routes>
    </div>
  </main>

};

function mapStateToProps(state) {
  return {
    // userAuthenticated: state.global.userAuthenticated
  }
}

export default connect(mapStateToProps)(Main);