class RedemptionDetailsModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.MembershipNumber = params.membershipNumber;
        this.PrimaryRedemption = params.primaryRedemption;
        this.BookingDateTime = params.bookingDateTime;
        this.SlotDateTime = params.slotDateTime;
        this.PartnerName = params.partnerName;
        this.ReferenceId = params.referenceId;
        this.Credits = params.credits;
        this.Value = params.value;

    }

}

export default RedemptionDetailsModel;