import React from 'react';

/**
 * @description passes custom props to first-level child elements
 * @returns 
 */
const StepScreenSection = (props) => {

  const children = React.Children.map(props.children, (child) => {
    const { children, ...customProps } = props;
    return React.cloneElement(child, { ...customProps });
  });

  return children;

};

export default StepScreenSection;