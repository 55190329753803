
export const GET_MEMBERSHIP_DATA_SUCCESS = 'GET_MEMBERSHIP_DATA_SUCCESS';
export const GET_MEMBERSHIP_DATA_ERROR = 'GET_MEMBERSHIP_DATA_ERROR';

export const UPDATE_MEMBERSHIP_STATE_SUCCESS = 'UPDATE_MEMBERSHIP_STATE_SUCCESS';
export const UPDATE_MEMBERSHIP_STATE_ERROR = 'UPDATE_MEMBERSHIP_STATE_ERROR';

export const ACTIVATE_MEMBERSHIP_SUCCESS = 'ACTIVATE_MEMBERSHIP_SUCCESS';
export const ACTIVATE_MEMBERSHIP_ERROR = 'ACTIVATE_MEMBERSHIP_ERROR';

export const LOCK_UNLOCK_MEMBERSHIP_SUCCESS = 'LOCK_UNLOCK_MEMBERSHIP_SUCCESS';
export const LOCK_UNLOCK_MEMBERSHIP_ERROR = 'LOCK_UNLOCK_MEMBERSHIP_ERROR';

export const CLEAR_MEMBERSHIP_DATA_SUCCESS = 'CLEAR_MEMBERSHIP_DATA_SUCCESS';
export const CLEAR_MEMBERSHIP_DATA_ERROR = 'CLEAR_MEMBERSHIP_DATA_ERROR';

export const UPDATE_MEMBERSHIP_PAYMENT_STATE_SUCCESS = 'UPDATE_MEMBERSHIP_PAYMENT_STATE_SUCCESS';