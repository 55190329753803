class AccountDetailsModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.AccountNumber = params.accountNumber;
        this.HouseNameNumber = params.houseNameNumber;
        this.FirstLineAddress = params.firstLineAddress;
        this.SecondLineAddress = params.secondLineAddress;
        this.PostCode = params.postCode;
        this.Telephone = params.telephone;
        this.Email = params.email;
        this.FirstName = params.firstName;
        this.LastName = params.lastName;
        this.CountyId = params.countyId;
        this.CountyName = params.countyName;
        this.ProfileImageUri = [params.profileImageUri];

    }

}

export default AccountDetailsModel;