import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Create.module.css';

const Create = () => {

  return <div className={`${styles.create}`}>
    Create User
  </div>

};

export default Create;

Create.propTypes = {
 
}
