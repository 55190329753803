

class UserModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.FirstName = params.firstName;
        this.LastName = params.lastName;
        this.Email = params.email;
        this.ProfileImageUri = params.profileImageUri;
        this.AccountNumber = params.accountNumber;

    }

}

export default UserModel;
