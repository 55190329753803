import { RESOURCE, ACTIONS } from '../Config';
import { RESPONSE_HEADERS, CONTENT_TYPES, HTTP_STATUS_CODES } from '../../../../_Domain/Api/Constants';
import BaseApiService from '../../../Base/Services/BaseApiService';
import ApiResponseBase from '../../../Base/Domain/ApiResponseBase';
import AccountSummaryRequestModel from '../Models/AccountSummary/Get/AccountSummaryRequestModel';
import AccountSummaryResponseModel from '../Models/AccountSummary/Get/AccountSummaryResponseModel';
import UpdateBasicDetailsRequestModel from '../Models/AccountSummary/Update/UpdateBasicDetailsRequestModel';
import UpdateBasicDetailsResponseModel from '../Models/AccountSummary/Update/UpdateBasicDetailsResponseModel';

const API_ENDPOINT = process.env.REACT_APP_USER_ACCOUNTSERVICES_API_HOST;

class AccountServicesApiService extends BaseApiService {

  constructor() {
    super(API_ENDPOINT, RESOURCE);
    this.setRequestHeaders({ [RESPONSE_HEADERS.ContentType]: CONTENT_TYPES.ApplicationJson });
  }

  /**
   * @method get
   * @type {GET}
   * @description gets account summary
   * 
   * @param {object} model AccountSummary request model
   * @returns {Task} AccountSummary
   */
  get = async (model) => this._restService.get(new AccountSummaryRequestModel(model), `${RESOURCE}${ACTIONS.Get}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new AccountSummaryResponseModel(result));

  /**
   * @method update
   * @type {GET}
   * @description updates user account basic details
   * 
   * @param {object} model UpdateBasicDetailsRequestModel request model
   * @returns {Task} 
   */
  update = async (model) => this._restService.update(new UpdateBasicDetailsRequestModel(model), false, `${RESOURCE}${ACTIONS.Update}`).then(result => typeof result?.error !== 'undefined' ? new ApiResponseBase(result?.data) : new UpdateBasicDetailsResponseModel(result));

}

export default new AccountServicesApiService();