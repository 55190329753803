class AdjustCreditBalanceRequestModel {

    constructor(params) {

        if (typeof params === 'undefined' || params === null) {
            return;
        }

        this.MembershipNumber = params.MembershipNumber;
        this.Value = params.Value || 0;
        this.Credit = params.Credit === '1' || false;
        this.Reason = params.Reason;
    }

}

export default AdjustCreditBalanceRequestModel;
