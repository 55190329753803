import ApiResponseBase from '../../../../../Base/Domain/ApiResponseBase';
import PartnerDetailsModel from '../PartnerDetailsModel';

class UpdatePartnerDetailsResponseModel extends ApiResponseBase {

  constructor(params) {

    super(params);

    if (typeof params === 'undefined' || params === null) {
      return;
    }

    this.AccountDetails = new PartnerDetailsModel(params.accountDetails);

  }

}

export default UpdatePartnerDetailsResponseModel;