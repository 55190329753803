import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Create.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import formData from './CreatePartnerAccountDetailsFormData.json';
import Form from '../../../Foundation/Helpers/Components/Forms/Form';
import CustomLoader from '../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import { HTTP_STATUS_CODES } from '../../../Foundation/Rest/Constants';
import { flattenDropdownOption, mapFormViewModel, mapNameIdPairToSelectOptions } from '../../../Foundation/Helpers/Components/Forms/Utility/formHelpers';
import _adminPartnerAccountServicesApiService from '../../../Application/ApiServices/Partner/AdminPartnerServices/Services/PartnerAccountServicesApiService';
import GenericError from '../../../Foundation/Helpers/Components/Feature/Common/Error/GenericError';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Notification from '../../../Foundation/Helpers/Components/Feature/Common/Notification/Notification';
import { getCounties } from '../../../Foundation/Store/Actions/Utility/utilityActions';
import CreateDetailsPanel from '../../Details/CreateDetailsPanel/CreateDetailsPanel';

import teeTimesData from '../TeeTimes.json';
import { SlotTimesFieldKeys } from '../settings';

const COUNTY_ID_KEY = 'CountyId',
  PARTNER_IMAGE_URIS_KEY = 'PartnerImages',
  DATA_API_PROPERTIES_KEY = 'AccountDetails';

const TEE_TIMES = [...teeTimesData.am, ...teeTimesData.pm];

const Create = () => {

  const auth = useSelector((state) => state.authentication);
  const counties = useSelector((state) => state.utility.counties);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [validationErrorData, setValidationErrorData] = useState(null);
  const [isPendingData, setIsPendingData] = useState(false);
  const [countiesList, setCountiesList] = useState(null);
  const [userDataApiReponse, setUserDataApiResponse] = useState(null);

  const buildFormViewModelWithData = (apiData) => {

    let result = mapFormViewModel(formData, apiData, DATA_API_PROPERTIES_KEY)

    // counties
    for (let section in result.formViewModel.sections) {

      for (let field of result.formViewModel.sections[section].fields) {
        if (apiData.AccountSummary?.hasOwnProperty(field.name)) {
          let entity = apiData[DATA_API_PROPERTIES_KEY][field.name];
          if (field.name === COUNTY_ID_KEY) {
            let counties = mapNameIdPairToSelectOptions(countiesList);
            field.options = counties;
            field.displayValue = counties.find(x => x.value === entity)?.label;
          }
        }

        if (field.name === PARTNER_IMAGE_URIS_KEY) {
          field.defaultValue = field.displayValue = apiData[DATA_API_PROPERTIES_KEY].PartnerImageUris !== null ? [apiData[DATA_API_PROPERTIES_KEY].PartnerImageUris] : [];
        }

      }
    }

    return result;
  }

  const buildFormViewModel = (apiData) => {

    let result = {
      formViewModel: formData
    };

    // counties
    for (let section in result.formViewModel.sections) {

      for (let field of result.formViewModel.sections[section].fields) {

        if (field.name === COUNTY_ID_KEY) {
          let counties = mapNameIdPairToSelectOptions(countiesList);
          field.options = counties;
        }

        if (field.name === PARTNER_IMAGE_URIS_KEY) {
          field.defaultValue = field.displayValue = [];
        }

        if (Object.values(SlotTimesFieldKeys).includes(field.name)) {
          field.options = TEE_TIMES;
        }

      }
    }

    return result;
  }

  const submitUpdate = (submitData) => {

    if (typeof auth.accessToken !== 'undefined') {

      setIsPendingData(true);

      _adminPartnerAccountServicesApiService.create(submitData).then((response) => {

        // server validation error
        if (response.apiStatus === HTTP_STATUS_CODES.BadRequest) {

          let sentDataThatErrored = { ...data, ...response }
          flattenDropdownOption(sentDataThatErrored, submitData, DATA_API_PROPERTIES_KEY, COUNTY_ID_KEY);
          setValidationErrorData(response.errors);
          setData(buildFormViewModelWithData(sentDataThatErrored));
          toast(<Notification label="There were some errors" mode="information" />)

        } else {

          let successData = response[DATA_API_PROPERTIES_KEY];
          navigate(`/courses/${successData.PartnerId}`);
          toast(<Notification label={`New Course ${successData.Name} successfully created`} mode="success" />)
        }

        setIsPendingData(false);
      })
    }
  }

  useEffect(() => {

    if (countiesList !== null) {
      setData(buildFormViewModel());
      setIsPendingData(false);
    }

  }, [countiesList]);

  useEffect(() => {

    if (typeof auth?.accessToken !== 'undefined') {
      setIsPendingData(true);

      if (counties === null) {
        dispatch(getCounties());
      } else {
        setCountiesList(counties);
      }

    }
  }, [counties]);

  formData.onSubmit = submitUpdate;

  return <div className={`${styles.create} layout__sub-section`}>
    <div className={`layout__side-panel`}>
      <CreateDetailsPanel label={"Create a new Course"} />
    </div>
    <div className={`layout__editor-panel`}>
      {isPendingData ? <CustomLoader label={"Loading"} pending={isPendingData} className={styles.loader} /> :
        <Form className={styles.form} model={data?.formViewModel} editable={false} submissionResponseData={validationErrorData} />
      }

    </div>


  </div>

};

export default Create;

Create.propTypes = {

}
