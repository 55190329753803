import React from 'react'; 
import CustomLoader from '../../../../Foundation/Helpers/Components/Feature/Common/MicroUi/CustomLoader/CustomLoader';
import ProfileRoundel from '../../../../Foundation/Helpers/Components/Feature/Common/ProfileRoundel/ProfileRoundel';
import styles from './EntityDetails.module.css';

const EntityDetails = ({ data, loading }) => {

  return <div className={styles.profileSummary}>
  <div className={styles.info}>
  {!loading ? typeof data !== 'undefined' && <>
      <div className={styles.profileAvatarContainer}>
        <ProfileRoundel imageUri={data.ProfileImageUri} data={[data.FirstName, data.LastName]} />
      </div>
      <div className={styles.profileSummaryDetails}>
        <div className={styles.usernameTitle}>
          {data.FirstName} {data.LastName}</div>
        <div className={styles.email}>
          {data.Email}</div>
        <div className={styles.accountNumber}>
          {data.AccountNumber}</div>
        <div className={styles.accountSince}>
          Joined: {data.CreatedAt}
        </div>
      </div>
      </> : <CustomLoader label={"Loading User Summary"} pending={loading} className={styles.loader} /> }
  </div>
</div>

};

export default EntityDetails;

EntityDetails.propTypes = {

}
