import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Controller } from 'react-hook-form';
import FieldBase from '../Base/FieldBase';
import { CLASS_MODIFIERS } from '../../Utility/_formsConfig';
import styles from './Calendar.module.css';

const CalendarComponent = ({ name, label, defaultValue, errors, alive, incrementor, control, validation = {} }) => {

    const [startValue, setStartValue] = useState(new Date());

    useEffect(() => {
        
        if(typeof defaultValue !== 'undefined'){
            setStartValue(new Date(defaultValue));
        }

    }, [defaultValue]);

    const d = new Date(),
        previousYear = d.setFullYear(d.getFullYear() - 1);

    return <FieldBase {...{ errors, alive, ['classModifier']: CLASS_MODIFIERS.Calendar, label, incrementor }}>
        <Controller
            name={name}
            control={control}
            rules={validation}
            value={startValue}
            render={({ field }) => {

                return <Calendar className={styles.calendar}
                    {...field}
                    minDate={new Date(previousYear)}
                    value={startValue}
                    defaultValue={startValue}
                    classNamePrefix="calendar-field"
                    onChange={(date) => { 
                        field.onChange(date);
                        setStartValue(date) } 
                    }
                />
            }}
        />

    </FieldBase>
};

export default CalendarComponent;

CalendarComponent.propTypes = {
}