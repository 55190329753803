import React from 'react';
import { ToastContainer, Bounce } from 'react-toastify';
import AuthenticationProvider from './Foundation/Helpers/Components/_Utility/AuthenticationProvider';
import Layout from './Foundation/Layout';

function App() {
  return <AuthenticationProvider>
    <Layout />
    <ToastContainer
      position="top-right"
      autoClose={2000}
      transition={Bounce}
      hideProgressBar
      newestOnTop
    />

  </AuthenticationProvider>
}

export default App;
