class RedemptionRequestSearchUserModel {

    constructor(params) {

        if (typeof params === 'undefined') {
            return null;
        }

        this.FirstName = params.firstName;
        this.LastName = params.lastName;
        this.Email = params.email;
        this.AccountNumber = params.accountNumber;
        this.ProfileImageUri = params.profileImageUri;

        this.FullName = `${this.FirstName} ${this.LastName}`;
    }

}

export default RedemptionRequestSearchUserModel;